import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_SCHEDULE_REPORTS,ADD_SCHEDULE_REPORT , UPDATE_SCHEDULE_REPORT,READ_SCHEDULE_REPORT,STATUS_SCHEDULE_REPORTS,ARCHIVE_SCHEDULE_REPORT} from "./actionTypes"

import {
  loadAgencyScheduleReports,
  noAgencyScheduleReports,
  getAgencyScheduleReportsSuccess,
  getAgencyScheduleReportsFail,
  addAgencyScheduleReportSuccess,
  addAgencyScheduleReportFail,
  updateAgencyScheduleReportSuccess,
  updateAgencyScheduleReportFail,
  readAgencyScheduleReportSuccess,
  statusAgencyScheduleReportSuccess,
  archiveAgencyScheduleReportSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getAgencyScheduleReports,postAgencyScheduleReport,updateAgencyScheduleReport,readAgencyScheduleReport,statusAgencyChangeScheduleReport,archiveAgencyScheduleReport } from "../../../helpers/Backend_helper"

function* fetchAgencyScheduleReports({ payload: scr }) {
  try {
    yield put(loadAgencyScheduleReports(true))
    var getScrList
      if(scr){
        getScrList = yield call(getAgencyScheduleReports,scr)
        console.log(getScrList)
        getScrList.responseData.response.map((item, index) => {
          item.id = index + 1
      })
      console.log(scr)
      if(getScrList.responseData.response.length === 0){
        yield put(noAgencyScheduleReports(true))
      }
        yield put(getAgencyScheduleReportsSuccess(getScrList.responseData.response))
      }
      else{
        getScrList = []
        yield put(getAgencyScheduleReportsSuccess(getScrList))
      }
  } catch (error) {
    console.log(error)
    yield put(getAgencyScheduleReportsFail(error))
  }
}

function* onReadAgencyScheduleReport({ payload: scr }) {
  try {
    const response = yield call(readAgencyScheduleReport, scr)
    yield put(readAgencyScheduleReportSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(readAgencyScheduleReportSuccess(error))
  }
}

function* onAddAgencyScheduleReport({ payload: scr }) {
  try {
    const response = yield call(postAgencyScheduleReport, scr)
    if(response.responseData.success){
      yield put(addAgencyScheduleReportSuccess(response.responseData))
    }else{
      yield put(addAgencyScheduleReportFail(response.responseData.message))
    }
  } catch (error) {
    console.log(error)
    yield put(addAgencyScheduleReportFail(error))
  }
}

function* onUpdateAgencyScheduleReport({ payload: scr }) {
  try {
    const response = yield call(updateAgencyScheduleReport, scr)
    yield put(updateAgencyScheduleReportSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(statusAgencyScheduleReportSuccess(error))
  }
}

function* onStatusAgencyScheduleReport({ payload: scr }) {
  try {
    const response = yield call(statusAgencyChangeScheduleReport, scr)
    yield put(statusAgencyScheduleReportSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(updateAgencyScheduleReportFail(error))
  }
}

function* onArchiveAgencyScheduleReport({ payload: scr }) {
  try {
    const response = yield call(archiveAgencyScheduleReport, scr)
    yield put(archiveAgencyScheduleReportSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(archiveAgencyScheduleReportSuccess(error))
  }
}

function* agencyScheduleReportsSaga() {
  yield takeEvery(GET_SCHEDULE_REPORTS, fetchAgencyScheduleReports)
  yield takeEvery(ADD_SCHEDULE_REPORT, onAddAgencyScheduleReport)
  yield takeEvery(UPDATE_SCHEDULE_REPORT, onUpdateAgencyScheduleReport)
  yield takeEvery(READ_SCHEDULE_REPORT, onReadAgencyScheduleReport)
  yield takeEvery(STATUS_SCHEDULE_REPORTS, onStatusAgencyScheduleReport)
  yield takeEvery(ARCHIVE_SCHEDULE_REPORT, onArchiveAgencyScheduleReport)
}

export default agencyScheduleReportsSaga;
