import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_HOUSE_AD_CREATIVES, ADD_HOUSE_AD_CREATIVE, UPDATE_HOUSE_AD_CREATIVE, READ_HOUSE_AD_CREATIVE, STATUS_HOUSE_AD_CREATIVE, ARCHIVE_HOUSE_AD_CREATIVE } from "./actionTypes"

import {
  loadActivitiesAdHouseAdCreatives,
  noHouseAdCreatives,
  getHouseAdCreativesSuccess,
  getHouseAdCreativesFail,
  addHouseAdCreativeSuccess,
  addHouseAdCreativeFail,
  updateHouseAdCreativeSuccess,
  updateHouseAdCreativeFail,
  readHouseAdCreativeSuccess,
  statusHouseAdCreativeSuccess,
  archiveHouseAdCreativeSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getCreatives, getCreativesByCategory,getCreativesByAdUnits, postCreatives, updateCreatives, readCreatives, statusChangeCreatives, archiveCreatives } from "../../../helpers/Backend_helper"

function* fetchHouseAdCreatives({ payload: crtv }) {
  try {
    yield put(loadActivitiesAdHouseAdCreatives(true))
    var getCrtList
    console.log(crtv)
      if (crtv) {
        getCrtList = yield call(getCreativesByAdUnits, crtv)
        console.log(getCrtList)
        getCrtList.responseData.response.map((item, index) => {
          item.id = index + 1
        })
        if(getCrtList.responseData.response.length === 0){
          yield put(noHouseAdCreatives(true))
        }
        yield put(getHouseAdCreativesSuccess(getCrtList.responseData))
      }
    
    else {
      getCrtList = []
      yield put(getHouseAdCreativesSuccess(getCrtList))
    
    }
  } catch (error) {
    yield put(getHouseAdCreativesFail(error))
  }
}

function* onReadHouseAdCreative({ payload: crtv }) {
  try {
    const response = yield call(readCreatives, crtv)
    yield put(readHouseAdCreativeSuccess(response))
  } catch (error) {
    yield put(readHouseAdCreativeSuccess(error))
  }
}

function* onAddHouseAdCreative({ payload: crtv }) {
  try {
    const response = yield call(postCreatives, crtv)
    if (response.responseData.success) {
      yield put(addHouseAdCreativeSuccess(response.responseData))
    } else {
      yield put(addHouseAdCreativeFail(response.responseData))
    }
  } catch (error) {
    yield put(addHouseAdCreativeFail(error))
  }
}

function* onUpdateHouseAdCreative({ payload: crtv }) {
  try {
    const response = yield call(updateCreatives, crtv)
    if (response.responseData.success) {
      yield put(updateHouseAdCreativeSuccess(response))
    } else {
      yield put(updateHouseAdCreativeFail(response.responseData))
    }
  } catch (error) {
    yield put(updateHouseAdCreativeFail(error))
  }
}

function* onStatusHouseAdCreative({ payload: crtv }) {
  try {
    const response = yield call(statusChangeCreatives, crtv)
    yield put(statusHouseAdCreativeSuccess(response))
  } catch (error) {
    yield put(updateHouseAdCreativeFail(error))
  }
}

function* onArchiveHouseAdCreative({ payload: crtv }) {
  try {
    const response = yield call(archiveCreatives, crtv)
    yield put(archiveHouseAdCreativeSuccess(response))
  } catch (error) {
    yield put(archiveHouseAdCreativeSuccess(error))
  }
}

function* creativesSaga() {
  yield takeEvery(GET_HOUSE_AD_CREATIVES, fetchHouseAdCreatives)
  yield takeEvery(ADD_HOUSE_AD_CREATIVE, onAddHouseAdCreative)
  yield takeEvery(UPDATE_HOUSE_AD_CREATIVE, onUpdateHouseAdCreative)
  yield takeEvery(READ_HOUSE_AD_CREATIVE, onReadHouseAdCreative)
  yield takeEvery(STATUS_HOUSE_AD_CREATIVE, onStatusHouseAdCreative)
  yield takeEvery(ARCHIVE_HOUSE_AD_CREATIVE, onArchiveHouseAdCreative)
}

export default creativesSaga;
