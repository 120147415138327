/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import MetaTags from 'react-meta-tags';
import axios from "axios";
import Select from "react-select";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Spinner,
    Label, Form, Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import moment from "moment";

import {
    getPageUrlReports as onGetPageUrlReports,
} from "store/Reports/AdminReports/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Team_History_Analytical from "pages/Reporting/YM_Team/Team_History_Analytical";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1

var fromDate = moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
var toDate = moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')

var globalFilterData = {}

const Page_Url_ReportMaster = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [reportOpen, setReportOpen] = useState(false);
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [requestTotal, setRequestTotal] = useState(0)
    const [totalImpressions, setTotalImpressions] = useState(0)
    const [totalClicks, setTotalClicks] = useState(0)
    const [totalRefreshReq, setTotalRefreshReq] = useState(0)
    const [totalRefreshImp, setTotalRefreshImp] = useState(0)
    const [totalRefreshClick, setTotalRefreshClick] = useState(0)
    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [selectAll, setSelectAll] = useState(false)
    const [selectMetrice, setSelectMetrice] = useState(false)
    const [range, setRange] = useState('daily')
    const [loader, setLoader] = useState(false)
    const [selectAllMTC, setSelectAllMTC] = useState(false)

    const [selectAllDM, setSelectAllDM] = useState({
        "partner_id": false,
        "partner_name": false,
        "publisher_id": false,
        "publisher_name": false,
        "ad_unit_group_id": false,
        "ad_unit_group_name": false,
        "ad_unit_id": false,
        "ad_unit_name": false,
        "domain": false,
        "site": false,
        "country": false,
        "city": false,
        "url": false,
        "device": false,
    });

    const [filters, setFilters] = useState({
        "partner_name": "",
        "publisher_name": "",
        "ad_unit_name": "",
        "country": "",
        "url": "",
        "device": "",
    });

    var currentTimeStamp = Date.now()

    const [selectedMTC, setSelectedMTC] = useState({
        "requests": false,
        "impressions": false,
        "refreshRequests": false,
        "refreshImpressions": false,
        "click": false,
        "refreshClick": false
    });
    const [Partner, setPartner] = useState([]);
    const [Publisher, setPublisher] = useState([]);
    const [AdUnitGroup, setAdUnitGroup] = useState([]);
    const [AdUnit, setAdUnit] = useState([]);

    const [Country, setCountry] = useState([]);
    const [Device, setDevice] = useState([
        { value: 'All', label: 'All' },
        { value: 'Desktop', label: 'Desktop' },
        { value: 'Mobile', label: 'Mobile' },
        { value: 'Tablet', label: 'Tablet' },
    ]);

    const [selectedPartner, setSelectedPartner] = useState(null);
    const [selectedPublisher, setSelectedPublisher] = useState(null);
    const [selectedAdUnitGroup, setSelectedAdUnitGroup] = useState(null);
    const [selectedAdUnit, setSelectedAdUnit] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(null);

    const handleSelectPartner = partner => {
        setSelectedPartner(partner)
        setSelectedPublisher(null)
        setSelectedAdUnitGroup(null)
        setSelectedAdUnit(null)
        if (partner) {
            setFilters(filters => ({ ...filters, partner_name: partner.label, publisher_name: '', ad_unit_group_name: '', ad_unit_name: '' }));
            changeSupplyFilter(partner, 'PB',)
        } else {
            callSupplyFiltersAll(globalFilterData)
            setFilters(filters => ({ ...filters, partner_name: '', publisher_name: '', ad_unit_group_name: '', ad_unit_name: '' }));
        }
    }

    const handleSelectPublisher = publisher => {
        setSelectedPublisher(publisher)
        setSelectedAdUnitGroup(null)
        setSelectedAdUnit(null)
        if (publisher) {
            setFilters(filters => ({ ...filters, publisher_name: publisher.label, ad_unit_group_name: '', ad_unit_name: '' }));
            changeSupplyFilter(publisher, 'AUG',)
        } else {
            if (selectedPartner) {
                changeSupplyFilter(selectedPartner, 'PB',)
            } else {
                callSupplyFiltersAll(globalFilterData)
            }
            setFilters(filters => ({ ...filters, publisher_name: '', ad_unit_group_name: '', ad_unit_name: '' }));
        }
    }

    const handleSelectAdUnitGroup = adUnitGroup => {
        setSelectedAdUnitGroup(adUnitGroup)
        setSelectedAdUnit(null)
        if (adUnitGroup) {
            setFilters(filters => ({ ...filters, ad_unit_group_name: adUnitGroup.label, ad_unit_name: '' }));
            changeSupplyFilter(adUnitGroup, 'AU',)
        } else {
            changeSupplyFilter(selectedPublisher, 'AUG',)
            setFilters(filters => ({ ...filters, ad_unit_group_name: '', ad_unit_name: '' }));
        }
    }

    const handleSelectAdUnit = adUnitName => {
        setSelectedAdUnit(adUnitName)
        if (adUnitName) {
            setFilters(filters => ({ ...filters, ad_unit_name: adUnitName.label }));
        } else {
            setFilters(filters => ({ ...filters, ad_unit_name: '' }));
        }
    }

    const handleSelectCountry = country => {
        setSelectedCountry(country)
        if (country) {
            setFilters(filters => ({ ...filters, country: country.value }));
        } else {
            setFilters(filters => ({ ...filters, country: '' }));
        }
    }

    const handleSelectDevice = device => {
        setSelectedDevice(device)
        if (device) {
            setFilters(filters => ({ ...filters, device: device.value }));
        } else {
            setFilters(filters => ({ ...filters, device: '' }));
        }
    }

    const changeMTCSelect = (e) => {
        setSelectAllMTC(e.target.checked)
        setSelectedMTC({
            "requests": e.target.checked,
            "impressions": e.target.checked,
            "refreshRequests": e.target.checked,
            "refreshImpressions": e.target.checked,
            "click": e.target.checked,
            "refreshClick": e.target.checked
        })
    }

    const changeSelectionMTC = (e) => {
        const { name, value } = e.target;
        setSelectedMTC(selectedMTC => ({ ...selectedMTC, [name]: e.target.checked }));
        let newMTC = { ...selectedMTC, [name]: e.target.checked }
        let MtcArr = Object.values(newMTC)
        if (!MtcArr.includes(false)) {
            setSelectAllMTC(true)
        } else {
            setSelectAllMTC(false)
        }
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
        }
    }

    useEffect(() => {
        body.url = "http://127.0.0.1:2002/v1.0/regions/list"
        axios.post("/v1.0/connectReportAPI", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.responseData) {
                    setCountry([...res.data.responseData.Africa, ...res.data.responseData.Americas, ...res.data.responseData.Asia, ...res.data.responseData.Europe, ...res.data.responseData.GDPR, ...res.data.responseData.Oceania])
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }, []);

    useEffect(() => {
        globalFilterData = {}
        let bodyFilter = {
            "url": 'http://127.0.0.1:2002/v1.0/cache',
            "requestBody": {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                "EntityName": "Advertiser",
                "EntityValue": ""
            }
        }
        axios.post("/v1.0/connectReportAPI", bodyFilter,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.responseData) {
                    callSupplyFiltersAll(res.data.responseData)
                    globalFilterData = res.data.responseData
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
        fromDate = moment().subtract(6, 'days')
        toDate = moment()
        console.log("start: ", fromDate);
        console.log("end: ", toDate);
    }, []);

    const callSupplyFiltersAll = (loadData) => {
        console.log(loadData.supply)
        let PBList = []
        let AUGList = []
        let AUList = []

        if (loadData.supply.length > 0) {
            loadData.supply.map(y => {
                y.value = y.PartnerID; y.label = y.PartnerName;
                y.Publishers.length > 0 && y.Publishers.map(a => {
                    a.value = a.PublisherID; a.label = a.PublisherName;
                    PBList.push(a)
                    a.AdUnitGroups.length > 0 && a.AdUnitGroups.map(b => {
                        b.value = b.AdUnitGroupID; b.label = b.AdUnitGroupName;
                        AUGList.push(b)
                        b.AdUnits.length > 0 && b.AdUnits.map(c => {
                            c.value = c.AdUnitID; c.label = c.AdUnitName;
                            AUList.push(c)
                        })
                    })
                })
            })
        }
        console.log(loadData.supply)
        setPartner(loadData.supply)
        setPublisher(PBList)
        setAdUnitGroup(AUGList)
        setAdUnit(AUList)
    }

    const changeSupplyFilter = (loadData, entity) => {
        if (entity === 'PB') {
            let PBList = []
            let AUGList = []
            let AUList = []
            loadData.Publishers.length > 0 && loadData.Publishers.map(a => {
                a.value = a.PublisherID; a.label = a.PublisherName;
                PBList.push(a)
                a.AdUnitGroups.length > 0 && a.AdUnitGroups.map(b => {
                    b.value = b.AdUnitGroupID; b.label = b.AdUnitGroupName;
                    AUGList.push(b)
                    b.AdUnits.length > 0 && b.AdUnits.map(c => {
                        c.value = c.AdUnitID; c.label = c.AdUnitName;
                        AUList.push(c)
                    })
                })
            })
            setPublisher(PBList)
            setAdUnitGroup(AUGList)
            setAdUnit(AUList)
        }
        if (entity === 'AUG') {
            let AUGList = []
            let AUList = []
            loadData.AdUnitGroups.length > 0 && loadData.AdUnitGroups.map(b => {
                b.value = b.AdUnitGroupID; b.label = b.AdUnitGroupName;
                AUGList.push(b)
                b.AdUnits.length > 0 && b.AdUnits.map(c => {
                    c.value = c.AdUnitID; c.label = c.AdUnitName;
                    AUList.push(c)
                })
            })
            setAdUnitGroup(AUGList)
            setAdUnit(AUList)
        }
        if (entity === 'AU') {
            let AUList = []
            loadData.AdUnits.length > 0 && loadData.AdUnits.map(c => {
                c.value = c.AdUnitID; c.label = c.AdUnitName;
                AUList.push(c)
            })
            setAdUnit(AUList)
        }
    }

    const changeDMSelect = (e) => {
        setSelectAll(e.target.checked)
        setSelectAllDM({
            "partner_id": e.target.checked,
            "partner_name": e.target.checked,
            "publisher_id": e.target.checked,
            "publisher_name": e.target.checked,
            "ad_unit_group_id": e.target.checked,
            "ad_unit_group_name": e.target.checked,
            "ad_unit_id": e.target.checked,
            "ad_unit_name": e.target.checked,
            "domain": e.target.checked,
            "site": e.target.checked,
            "country": e.target.checked,
            "city": e.target.checked,
            "url": e.target.checked,
            "device": e.target.checked,
        })
    }

    const changeSelection = (e) => {
        setSelectAll(false)
        const { name, value } = e.target;
        setSelectAllDM(selectAllDM => ({ ...selectAllDM, [name]: e.target.checked }));
        let newDim = { ...selectAllDM, [name]: e.target.checked }
        let DimArr = Object.values(newDim)
        if (!DimArr.includes(false)) {
            setSelectAll(true)
        } else {
            setSelectAll(false)
        }
    }

    const changeRange = (e) => {
        setRange(e.target.value)
    }

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.adminReports.errorMsg,
        successMsg: state.adminReports.successMsg,
    }));

    console.log(errorMsg, successMsg)

    // useEffect(() => {
    //     if (errorMsg) {
    //         const timer = setTimeout(() => {
    //             setReportOpen(false)
    //             dispatch(onGetPageUrlReports(null));
    //         }, 5000);
    //         return () => clearTimeout(timer);
    //     }
    // }, [errorMsg]);

    const { pageUrlReports } = useSelector(state => ({
        pageUrlReports: state.adminReports.pageUrlRpt,
    }));

    const { loading } = useSelector(state => ({
        loading: state.adminReports.loading,
    }));


    console.log(pageUrlReports)

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const pageUrlReportsColumns = [
        {
            text: "No",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
            footer: ''
        },
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "requests", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(onGetPageUrlReports(null));
        let DimArr = Object.values(selectAllDM)
        let MtcArr = Object.values(selectedMTC)
        if (!MtcArr.includes(true)) {
            toastr.warning('Please select atleast one Metrices')
        }
        else {
            setLoader(true)

            let query = {
                "dimensions": selectAllDM,
                "startDate": moment(fromDate).format("YYYY-MM-DD"),
                "endDate": moment(toDate).format("YYYY-MM-DD"),
                "filters": filters,
                "metrices": selectedMTC,
                "range": range,
                "type": 'proxy'
            }
            console.log(query)
            dispatch(onGetPageUrlReports({ ...query }));
            setReportOpen(true)
        }
    }

    if (pageUrlReports.length > 0) {
        let keys = [];
        for (var k in pageUrlReports[0]) keys.push(k);
        if (keys.includes("hour")) {
            pageUrlReports.map((item, index) => {
                item.hour = parseInt(item.hour)
            })
        }
        console.log(keys)
        if (keys.includes("date")) {
            pageUrlReportsColumns.push({
                dataField: "date",
                text: "Date",
                sort: true,
                footer: 'Total Requests'
            })
        }
        if (keys.includes("month")) {
            pageUrlReportsColumns.push({
                dataField: "month",
                text: "Month",
                sort: true,
                footer: 'Total Requests'
            })
        }
        if (keys.includes("hour")) {
            pageUrlReportsColumns.push({
                dataField: "hour",
                text: "Hour",
                sort: true,
                footer: ''
            })
        }
        if (keys.includes("partner_id")) {
            pageUrlReportsColumns.push({
                dataField: "partner_id",
                text: "Partner ID",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("partner_name")) {
            pageUrlReportsColumns.push({
                dataField: "partner_name",
                text: "Partner",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("publisher_id")) {
            pageUrlReportsColumns.push({
                dataField: "publisher_id",
                text: "Publisher ID",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("publisher_name")) {
            pageUrlReportsColumns.push({
                dataField: "publisher_name",
                text: "Publisher",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("ad_unit_group_id")) {
            pageUrlReportsColumns.push({
                dataField: "ad_unit_group_id",
                text: "Ad Unit Group ID",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("ad_unit_group_name")) {
            pageUrlReportsColumns.push({
                dataField: "ad_unit_group_name",
                text: "Ad Unit Group",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("ad_unit_id")) {
            pageUrlReportsColumns.push({
                dataField: "ad_unit_id",
                text: "Ad Unit ID",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("ad_unit_name")) {
            pageUrlReportsColumns.push({
                dataField: "ad_unit_name",
                text: "Ad Unit",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("domain")) {
            pageUrlReportsColumns.push({
                dataField: "domain",
                text: "Domain",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("site")) {
            pageUrlReportsColumns.push({
                dataField: "site",
                text: "Site",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("country")) {
            pageUrlReportsColumns.push({
                dataField: "country",
                text: "Country",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("city")) {
            pageUrlReportsColumns.push({
                dataField: "city",
                text: "City",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("url")) {
            pageUrlReportsColumns.push({
                dataField: "url",
                text: "URL",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("device")) {
            pageUrlReportsColumns.push({
                dataField: "device",
                text: "Device",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("requests")) {
            pageUrlReportsColumns.push({
                dataField: "requests",
                text: "Requests",
                sort: true,
                footer: `${requestTotal}`
            })
        }
        if (keys.includes("impressions")) {
            pageUrlReportsColumns.push({
                dataField: "impressions",
                text: "Impressions",
                sort: true,
                footer: `${totalImpressions}`
            })
        }
        if (keys.includes("click")) {
            pageUrlReportsColumns.push({
                dataField: "click",
                text: "click",
                sort: true,
                footer: `${totalClicks}`
            })
        }

        if (keys.includes("refreshRequests")) {
            pageUrlReportsColumns.push({
                dataField: "refreshRequests",
                text: "Refresh Requests",
                sort: true,
                footer: `${totalRefreshReq}`
            })
        }
        if (keys.includes("refreshImpressions")) {
            pageUrlReportsColumns.push({
                dataField: "refreshImpressions",
                text: "Refresh Impressions",
                sort: true,
                footer: `${totalRefreshImp}`
            })
        }

        if (keys.includes("refreshClick")) {
            pageUrlReportsColumns.push({
                dataField: "refreshClick",
                text: "Refresh Click",
                sort: true,
                footer: `${totalRefreshClick}`
            })
        }

        // loader = false
        console.log(pageUrlReportsColumns)
    } else {
        // loader = false
    }

    useEffect(() => {
        console.log(pageUrlReports)
        if (pageUrlReports !== null && pageUrlReports.length > 0) {
            setLoader(false)
            let totalRequest = 0
            let totalImpressionsCount = 0
            let totalClicks = 0
            let totalRefreshRequests = 0
            let totalRefreshImpressions = 0
            let totalRefreshClicks = 0
            pageUrlReports.map((item, index) => {
                if (item.requests) {
                    item.requests = Number(item.requests)
                    totalRequest = totalRequest + parseInt(item.requests)
                }
                if (item.impressions) {
                    item.impressions = Number(item.impressions)
                    totalImpressionsCount = totalImpressionsCount + parseInt(item.impressions)
                }
                if (item.click) {
                    item.click = Number(item.click)
                    totalClicks += parseInt(item.click)
                }
                if (item.refreshRequests) {
                    item.refreshRequests = Number(item.refreshRequests)
                    totalRefreshRequests += parseInt(item.refreshRequests)
                }
                if (item.refreshImpressions) {
                    item.refreshImpressions = Number(item.refreshImpressions)
                    totalRefreshImpressions += parseInt(item.refreshImpressions)
                }
                if (item.refreshClick) {
                    item.refreshClick = Number(item.refreshClick)
                    totalRefreshClicks += parseInt(item.refreshClick)
                }

            })
            setRequestTotal(totalRequest)
            setTotalImpressions(totalImpressionsCount)
            setTotalClicks(totalClicks)
            setTotalRefreshReq(totalRefreshRequests)
            setTotalRefreshImp(totalRefreshImpressions)
            setTotalRefreshClick(totalRefreshClicks)

        } else {
            setLoader(false)
        }

    }, [pageUrlReports]);

    var CSVProp
    const CSVClick = () => {
        CSVProp.onExport();
    }

    const MyExportCSV = (csvprops) => {
        CSVProp = csvprops
        return (<></>);
    };

    const copyTable = () => {
        console.log(CSVProp, JSON.stringify(CSVProp))
        navigator.clipboard.writeText(CSVProp)
        console.log('Element Copied! Paste it in a file')

    }

    const [state, setState] = useState({
        start: moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'),
        end: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
    });

    const { start, end } = state;

    const handleEvent = (startDate, endDate, label) => {
        console.log(startDate, endDate)
        setState({ start: startDate, end: endDate, });
        fromDate = startDate._d.toISOString();
        toDate = endDate._d.toISOString();
    };

    const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

    const [isRight, setIsRight] = useState(false);

    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };

    const toggleDrawer = (close, scheduleReport, action) => (event) => {
        console.log(event)
        setIsRight(close);
    };

    return (
        <React.Fragment>
            {/* <Sidebar />
          <Header />
          <div className="main-content"> */}
            <div className="page-content">
                <MetaTags>
                    <title>Page URL Report | Bidsxchange</title>
                </MetaTags>
                {!reportOpen &&
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <div className=" d-sm-flex  justify-content-between">
                            <SupplyBreadCrumb title="Reporting" breadcrumbItem="Page URL Report Query " />
                            <div className=" text-sm-end">
                                <Button
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                    onClick={toggleRightCanvas}
                                >
                                    History Report
                                </Button>
                            </div>
                        </div>
                        <Row>
                            <div className="col-12">
                                <Card>
                                    <CardBody>
                                        <Form onSubmit={handleSubmit}>
                                            <Row style={{ padding: '1% 3%', }}>
                                                <Col xs={4} >
                                                    <div className="mb-3">
                                                        <h5>SELECT DATE</h5> <br />
                                                        <DateRangePicker
                                                            initialSettings={{
                                                                opens: 'left',
                                                                startDate: start.toDate(),
                                                                endDate: end.toDate(),
                                                                minDate: moment().subtract(estCurrentDate > 1 ? 31 : 30, 'days'), maxDate: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
                                                                ranges: {
                                                                    // Today: [ moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
                                                                    // moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),],
                                                                    Yesterday: [
                                                                        moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                        moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                    ],
                                                                    'Last 7 Days': [moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                    'Last 30 Days': [moment().subtract(estCurrentDate > 1 ? 31 : 30, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                                    // 'Last Month': [
                                                                    // moment().subtract(1, 'month').startOf('month'),
                                                                    // moment().subtract(1, 'month').endOf('month'),
                                                                    // ],
                                                                },
                                                            }}
                                                            onCallback={handleEvent}
                                                        >
                                                            <button className='datePicker' type="button">
                                                                <i className="bx bx-calendar font-size-18 " id="edittooltip" style={{ margin: '3% 3% 2%' }} ></i>{labelDate}
                                                            </button>
                                                        </DateRangePicker>
                                                    </div>
                                                    <br />
                                                    <h5>FILTERS</h5>

                                                    <div className="mb-3">
                                                        <Label className="form-label">Supply Partner </Label>
                                                        <Select
                                                            value={selectedPartner}
                                                            onChange={s => {
                                                                handleSelectPartner(s)
                                                            }}
                                                            options={Partner}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Publisher</Label>
                                                        <Select
                                                            value={selectedPublisher}
                                                            onChange={s => {
                                                                handleSelectPublisher(s)
                                                            }}
                                                            options={Publisher}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                    {/* <div className="mb-3">
                                                            <Label className="form-label">Ad Unit Group</Label>
                                                            <Select
                                                                value={selectedAdUnitGroup}
                                                                onChange={s => {
                                                                    handleSelectAdUnitGroup(s)
                                                                }}
                                                                options={AdUnitGroup}
                                                                isClearable={true}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div> */}
                                                    <div className="mb-3">
                                                        <Label className="form-label">Ad Unit</Label>
                                                        <Select
                                                            value={selectedAdUnit}
                                                            onChange={s => {
                                                                handleSelectAdUnit(s)
                                                            }}
                                                            options={AdUnit}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Country </Label>
                                                        <Select
                                                            value={selectedCountry}
                                                            onChange={s => {
                                                                handleSelectCountry(s)
                                                            }}
                                                            options={Country}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Device</Label>
                                                        <Select
                                                            value={selectedDevice}
                                                            onChange={s => {
                                                                handleSelectDevice(s)
                                                            }}
                                                            options={Device}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={4} style={{ padding: '0% 3%', }}>

                                                    <div className="mb-3">
                                                        <h5> SELECT RANGE</h5><br />
                                                        <Input
                                                            name="range"
                                                            type="select"
                                                            className="form-select"
                                                            onChange={changeRange}
                                                            value={range}
                                                        >
                                                            <option value=''>Select</option>
                                                            <option value='alltime'>All Time</option>
                                                            <option value='monthly'>Monthly</option>
                                                            <option value='daily'>Daily</option>
                                                            <option value='hourly'>Hourly</option>
                                                        </Input>
                                                    </div>
                                                    <br />
                                                    <h5 className="mb-3 ">DIMENSIONS</h5>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAll}
                                                            onChange={changeDMSelect}
                                                            id="selectall"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="selectall"
                                                        >
                                                            Select All
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.partner_id}
                                                            name="partner_id"
                                                            onChange={changeSelection}
                                                            id="partner_id"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="partner_id"
                                                        >
                                                            Partner ID
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.partner_name}
                                                            name="partner_name"
                                                            onChange={changeSelection}
                                                            id="partner_name"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="partner_name"
                                                        >
                                                            Supply Partner
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.publisher_id}
                                                            name="publisher_id"
                                                            onChange={changeSelection}
                                                            id="publisher_id"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="publisher_id"
                                                        >
                                                            Publisher ID
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.publisher_name}
                                                            name="publisher_name"
                                                            onChange={changeSelection}
                                                            id="publisher_name"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="publisher_name"
                                                        >
                                                            Publisher
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.ad_unit_group_id}
                                                            name="ad_unit_group_id"
                                                            onChange={changeSelection}
                                                            id="ad_unit_group_id"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="ad_unit_group_id"
                                                        >
                                                            Ad Unit Group ID
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.ad_unit_group_name}
                                                            name="ad_unit_group_name"
                                                            onChange={changeSelection}
                                                            id="ad_unit_group_name"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="ad_unit_group_name"
                                                        >
                                                            Ad Unit Group
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.ad_unit_id}
                                                            name="ad_unit_id"
                                                            onChange={changeSelection}
                                                            id="ad_unit_id"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="ad_unit_id"
                                                        >
                                                            Ad Unit ID
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.ad_unit_name}
                                                            name="ad_unit_name"
                                                            onChange={changeSelection}
                                                            id="ad_unit_name"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="ad_unit_name"
                                                        >
                                                            Ad Unit
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.domain}
                                                            name="domain"
                                                            onChange={changeSelection}
                                                            id="domain"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="domain"
                                                        >
                                                            Domain
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.site}
                                                            name="site"
                                                            onChange={changeSelection}
                                                            id="site"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="site"
                                                        >
                                                            Site
                                                        </label>
                                                    </div>


                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.url}
                                                            name="url"
                                                            onChange={changeSelection}
                                                            id="url"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="url"
                                                        >
                                                            URL
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.country}
                                                            name="country"
                                                            onChange={changeSelection}
                                                            id="country"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="country"
                                                        >
                                                            Country
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.city}
                                                            name="city"
                                                            onChange={changeSelection}
                                                            id="city"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="city"
                                                        >
                                                            City
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.device}
                                                            name="device"
                                                            onChange={changeSelection}
                                                            id="device"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="device"
                                                        >
                                                            Device
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col xs={4} style={{ padding: '0 3%', }}>
                                                    <div className="mb-3">
                                                        <h5> SELECT Time Zone</h5><br />
                                                        <Input
                                                            name="timeZone"
                                                            type="select"
                                                            className="form-select"
                                                            defaultValue={'EST'}
                                                        >
                                                            <option value='' disabled>Select</option>
                                                            <option value='EST'>EST</option>
                                                        </Input>
                                                    </div>
                                                    <br />
                                                    <h5 className="mb-3">METRICES</h5>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllMTC}
                                                            onChange={changeMTCSelect}
                                                            id="selectallmetrices"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="selectallmetrices"
                                                        >
                                                            Select All
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.impressions}
                                                            name="impressions"
                                                            onChange={changeSelectionMTC}
                                                            id="impressions"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="impressions"
                                                        >
                                                            Impressions
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.requests}
                                                            name="requests"
                                                            onChange={changeSelectionMTC}
                                                            id="requests"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="requests"
                                                        >
                                                            Requests
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.refreshRequests}
                                                            name="refreshRequests"
                                                            onChange={changeSelectionMTC}
                                                            id="refreshRequests"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="refreshRequests"
                                                        >
                                                            Refresh Requests
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.refreshImpressions}
                                                            name="refreshImpressions"
                                                            onChange={changeSelectionMTC}
                                                            id="refreshImpressions"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="refreshImpressions"
                                                        >
                                                            Refresh Impressions
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.click}
                                                            name="click"
                                                            onChange={changeSelectionMTC}
                                                            id="click"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="click"
                                                        >
                                                            Clicks
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.refreshClick}
                                                            name="refreshClick"
                                                            onChange={changeSelectionMTC}
                                                            id="refreshClick"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="refreshClick"
                                                        >
                                                            Refresh Click
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className=" text-sm-end">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary save-user"
                                                        >
                                                            Run Report
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                }
                {reportOpen &&
                    <Container fluid={true}>
                        <div className=" d-sm-flex  justify-content-between">
                            <SupplyBreadCrumb title="Reporting" breadcrumbItem="Page URL Report " />
                            <div className=" text-sm-end">
                                <div className="btn-group">
                                    <Dropdown
                                        isOpen={btnprimary1}
                                        toggle={() => setBtnprimary1(!btnprimary1)}
                                    >
                                        <DropdownToggle tag="button" className="btn btn-primary">
                                            Export
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {/* <DropdownItem onClick={copyTable}>Copy</DropdownItem> */}
                                            <DropdownItem onClick={CSVClick}>CSV</DropdownItem>
                                            {/* <DropdownItem onClick={exportPDF}>PDF</DropdownItem> */}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                &nbsp;&nbsp;&nbsp;
                                <Button
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                    onClick={(e) => setReportOpen(false)}
                                >
                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                    Modify Report
                                </Button>
                            </div>
                        </div>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody aria-hidden="true">
                                        {loading &&
                                            <>
                                                <Spinner className="ms-2 loader" color="primary" />
                                                <br />
                                                <h5 className="text-center mt-5" style={{ padding: '15%', fontSize: '25px' }}></h5>
                                                <br />
                                            </>
                                        }
                                        {pageUrlReports.length === 0 && !loading &&
                                            <h5 className="text-center" style={{ padding: '15%', fontSize: '25px' }}>{errorMsg ? errorMsg : "No Data"}</h5>
                                        }
                                        {pageUrlReports.length > 0 && !loading &&
                                            <ToolkitProvider
                                                keyField={keyField}
                                                data={pageUrlReports}
                                                columns={pageUrlReportsColumns}
                                                // bootstrap4
                                                search
                                                exportCSV={{
                                                    fileName: 'PageURL-Report-' + currentTimeStamp + '.csv',
                                                }}
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col sm="8">
                                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col sm="1">
                                                                <MyExportCSV {...toolkitProps.csvProps} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12" className="table-responsive">
                                                                <div >
                                                                    <BootstrapTable
                                                                        keyField={keyField}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        pagination={paginationFactory(pageOptions)}
                                                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }

            </div>
            {/* </div> */}
            <Offcanvas isOpen={isRight} direction='end'  >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {'Page URL Report History'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Team_History_Analytical closeCanvas={toggleDrawer(false, '', '')} selectedTeam="MA_PU" />
                </OffcanvasBody>
            </Offcanvas>
        </React.Fragment>
    )
}

Page_Url_ReportMaster.propTypes = {}

export default Page_Url_ReportMaster