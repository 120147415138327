import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import axios from "axios";

import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Modal,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Tooltip
} from "reactstrap"

import classnames from "classnames";

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone, PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';
import excelFile from 'assets/files/AdsTxtFile.xlsx'
import PDFFile from 'assets/files/AdsTxtEntries.pdf'

import LoadingTable from "pages/LoaderPages/LoadingTable";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Ads_Txt_EntrtiesUpdate from "./Ads_Txt_EntrtiesUpdate";
import AdminSidebar from "../Admin_Sidebar/AdminSidebar";

const Ads_Txt_Entries = () => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [adsTxtEntries, setAdsTxtEntries] = useState([])
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [uploadModal, setUploadModal] = useState(false);
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [isRight, setIsRight] = useState(false);

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const [switchLoader, setSwitchLoader] = useState(true)
    const [loading, setLoading] = useState(false)

    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setIsRight(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const copyCrawled = (data) => (e) => {
        const cb = navigator.clipboard;
        const paragraph = document.getElementById(data);
        cb.writeText(paragraph.innerText)
    }

    const onToggleTooltip = (e) => {
        setIsTooltipOpen(!isTooltipOpen)
    }

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name
        }
    }

    useEffect(() => {
        setLoading(true)
        body.url = "http://127.0.0.1:2002/v1.0/ads.txtEntries/read"
        axios.post("/v1.0/connectOP", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                setLoading(false)
                if (res.data.responseData.response) {
                    res.data.responseData.response.map((item, index) => {
                        item.id = index + 1
                    })
                    setAdsTxtEntries([...res.data.responseData.response])
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log("e", err)
            });

    }, []);

    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        setFromDate(event.startDate._d.toISOString());
        setToDate(event.endDate._d.toISOString());
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const adsTxtEntriesListColumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "sSPEntry",
            text: "Ads.txt Entries",
            sort: true,
        },

        {
            text: "Accounts",
            dataField: "sSPEntryAccount",
            sort: true,
        },

        {
            dataField: "sSPEntryInventoryType",
            text: "Inventory Type",
            sort: true,
        }
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} entries
      </span>
    );
  
  
    const pageOptions = {
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const [newEntryList, setNewEntryList] = useState([{ sSPEntryAccount: '', sSPEntry: '', sSPEntryInventoryType: '' }])

    const addNewEntry = () => {
        let entryList = [...newEntryList]
        entryList.push({ sSPEntryAccount: '', sSPEntry: '', sSPEntryInventoryType: '' })
        setNewEntryList(entryList)
    }

    const handleEntryChange = (index) => (e) => {
        const { name, value } = e.target
        let entryList = [...newEntryList]
        let entryData = { ...entryList[index], [name]: value }
        entryList[index] = entryData
        setNewEntryList(entryList)
    }

    const removeEntry = (index) => (e) => {
        let entryList = [...newEntryList]
        entryList.splice(index, 1)
        setNewEntryList(entryList)
    }

    const saveEntries = (e) => {
        console.log(newEntryList)
        let bodyEntries = {
            url: "http://127.0.0.1:2002/v1.0/ads.txtEntries/create",
            "requestBody": {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                sspEntries: newEntryList,
                name: userInfo.name
            }
        }
        console.log(bodyEntries)
        axios.post("/v1.0/connectOP", bodyEntries,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.responseData.response) {
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };

    const toggleDrawer = (close, advertiser) => (event) => {
        console.log(event)
        if (event === 'Update') {
            toastr.success('ADS.txt Updated Successfully!...')
        }
        setIsRight(close);
    };

    useEffect(() => {
        console.log(loading)
        if (loading) {
            setSwitchLoader(true)
        }
    }, [loading]);
    return (
        <React.Fragment >
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            {/* <AdminSidebar />
      <div className="main-content access-management"> */}
            <div className="page-content">
                <MetaTags>
                    <title>ADS.txt| Bidsxchange</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Tools" breadcrumbItem="ADS.txt Entries" />
                    {switchLoader &&
                        <LoadingTable loading={loading} targetLoading={handleSwitch} />
                    }
                    {!switchLoader &&
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                                    <ToolkitProvider
                                                        keyField={keyField}
                                                        data={adsTxtEntries}
                                                        columns={adsTxtEntriesListColumns}
                                                        // bootstrap4
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row className="mb-2">
                                                                    <Col sm="3">
                                                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                                                            <div className="position-relative">
                                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                                <i className="bx bx-search-alt search-icon" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm="9">
                                                                        <div className="text-sm-end">
                                                                            <Button
                                                                                color="primary"
                                                                                className="font-16 btn-block btn btn-primary"
                                                                                onClick={toggleRightCanvas}
                                                                            >
                                                                                Edit
                                                                            </Button>&nbsp;&nbsp;&nbsp;
                                                                            {/* <div className="btn-group">
                                                                                <Dropdown
                                                                                    isOpen={btnprimary1}
                                                                                    toggle={() => setBtnprimary1(!btnprimary1)}
                                                                                >
                                                                                    <DropdownToggle tag="button" className="btn btn-primary">
                                                                                        Export
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem >Copy</DropdownItem>
                                                                                        <DropdownItem ><a href={excelFile} download="Ads_Txt_Entries.xlsx">Excel</a></DropdownItem>
                                                                                        <DropdownItem ><a href={PDFFile} download="Ads_Txt_Entries.pdf">PDF</a></DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </Dropdown>
                                                                            </div> &nbsp;&nbsp;&nbsp; */}
                                                                            <Button
                                                                                color="primary"
                                                                                className="font-16 btn-block btn btn-primary"
                                                                                id="controlledTooltip"
                                                                                onClick={copyCrawled('adsTxtEntries')}
                                                                                onMouseLeave={() => {
                                                                                    setIsTooltipOpen(false)
                                                                                }}
                                                                            >
                                                                                Copy Ads.txt Entries
                                                                            </Button>
                                                                            <Tooltip
                                                                                isOpen={isTooltipOpen}
                                                                                placement="top"
                                                                                target="controlledTooltip"
                                                                                toggle={onToggleTooltip}
                                                                                trigger="click"
                                                                            >
                                                                                Copied
                                                                            </Tooltip>&nbsp;&nbsp;&nbsp;
                                                                            <Button
                                                                                color="primary"
                                                                                className="font-16 btn-block btn btn-primary"
                                                                                onClick={() => {
                                                                                    setUploadModal(true);
                                                                                }}
                                                                            >
                                                                                Add/Revised Entries
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="12" className="table-responsive">
                                                                        <div >
                                                                            <BootstrapTable
                                                                                keyField={keyField}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}

                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                pagination={paginationFactory(pageOptions)}
                                                                                noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                            // ref={node}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>

            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            <Modal
                size="lg"
                isOpen={uploadModal}
                className="adsTxt"
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Add/Revised Entries
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setUploadModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Card>
                        <CardBody>

                            {/* <Nav tabs className="nav-tabs-custom nav-justified" style={{ width: '20pc' }}>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleCustom("1");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="fas fa-home"></i>
                                        </span>
                                        <span className="d-none d-sm-block">Manual Entry</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleCustom("2");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="far fa-user"></i>
                                        </span>
                                        <span className="d-none d-sm-block"> Upload Entries</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent
                                activeTab={customActiveTab}
                                className="p-3 text-muted"
                                style={{overflow:'auto',maxHeight:'65vh'}}
                            >
                                <TabPane tabId="1">
                           
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="12">
                                            <Input className="form-control" type="file" id="formFile" />
                                            <div className="col-12  ">
                                                <div className="row justify-content-center mt-2 ">
                                                    <Button color="primary" className="font-16 btn-block btn btn-primary w-25">Upload</Button>
                                                </div>
                                            </div>
                                            <hr className="hr" />
                                            <span className=" mt-3 mb-1 row justify-content-center">
                                                Upload Ads.txt Entries in Excel format (.xlsx file)
                                            </span>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent> */}

                            <Row>
                                <Col sm="12">
                                    <div className="row mt-2">
                                        <div className="col-4">
                                            <h5 id="headingOne mt-3">
                                                <u> Entry</u>
                                            </h5>
                                        </div>
                                        <div className="col-4">
                                            <h5 id="headingOne mt-3">
                                                <u> Account</u>
                                            </h5>
                                        </div>
                                        <div className="col-3">
                                            <h5 id="headingOne mt-3">
                                                <u> Inventory Type</u>
                                            </h5>
                                        </div>
                                        <div className="col-1">
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        {
                                            newEntryList.map((subItem, i) => {
                                                return (
                                                    <div className="vast-settings" key={i}>
                                                        <div className="form-group">
                                                            <div className="row mt-2">
                                                                <div className="col-4">
                                                                    <Input
                                                                        name="sSPEntry"
                                                                        type="text"
                                                                        placeholder='Enter Entry'
                                                                        value={subItem.sSPEntry}
                                                                        onChange={handleEntryChange(i)}
                                                                    >
                                                                    </Input>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Input
                                                                        name="sSPEntryAccount"
                                                                        type="text"
                                                                        placeholder='Enter Account'
                                                                        value={subItem.sSPEntryAccount}
                                                                        onChange={handleEntryChange(i)}
                                                                    >
                                                                    </Input>
                                                                </div>
                                                                <div className="col-3">
                                                                    <Input
                                                                        name="sSPEntryInventoryType"
                                                                        placeholder='Enter Inventory Type'
                                                                        value={subItem.sSPEntryInventoryType}
                                                                        onChange={handleEntryChange(i)}
                                                                        type="select" className="form-select"
                                                                    >
                                                                        <option value='' disabled>  Select...</option>
                                                                        <option value='display'>  Display</option>
                                                                        <option value='video'> Video</option>
                                                                        <option value='both'> Both</option>

                                                                    </Input>
                                                                </div>
                                                                <div className="col-1">
                                                                    <div className='row form-group '>
                                                                        <Button type="button" className='btn btn-danger ml-auto ' style={{ marginRight: '1pc', marginTop: '0pc', background: '#dc3545', float: 'right', width: '3pc', }} disabled={newEntryList.length === 1} onClick={removeEntry(i)}
                                                                        >X</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='row'>
                                        <Button
                                            color="primary"
                                            className="font-16 btn-block btn btn-primary"
                                            style={{ margin: '2% 1% 0%', width: 'auto' }}
                                            onClick={addNewEntry}
                                        >
                                            <i className="mdi mdi-plus-circle-outline me-1" />
                                            Add New Entry
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            setUploadModal(false);
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                    <Button
                        color="primary"
                        className="font-16 btn-block btn btn-primary"
                        onClick={saveEntries}
                    >
                        Save
                    </Button>

                </div>
            </Modal>

            <div className="m-0 p-0" id="adsTxtEntries" style={{ height: '0px', width: '0px', overflow: 'auto', }}>
                {
                    adsTxtEntries.length > 0 && adsTxtEntries.map((subItem, i) => {
                        return (
                            <div className="crawledData row mt-2" key={i}>
                                <span className="col-11">{i + 1}. &nbsp;&nbsp; {subItem.sSPEntry}</span>
                            </div>
                        )
                    })
                }
            </div>

            <Offcanvas isOpen={isRight} direction='end'  >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {'Edit Ads.Txt Entries'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Ads_Txt_EntrtiesUpdate closeCanvas={toggleDrawer(false, '')} />
                </OffcanvasBody>
            </Offcanvas>
        </React.Fragment >
    )
}

export default Ads_Txt_Entries