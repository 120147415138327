/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import {
  Row, Col, Label, Form, Input
} from "reactstrap";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import Select, { components, ValueContainerProps } from "react-select";
import GoogleMapReact from 'google-map-react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import _ from "lodash";

const weathers = [
  {
    label: "Clear",
    value: "Clear"
  },
  {
    label: "Sunny",
    value: "Sunny"
  },
  {
    label: "Mostly Clear",
    value: "MostlyClear"
  },
  {
    label: "Mostly Sunny",
    value: "MostlySunny"
  },
  {
    label: "Partly Cloudy",
    value: "PartlyCloudy"
  },
  {
    label: "Partly Sunny",
    value: "PartlySunny"
  },
  {
    label: "Mostly Cloudy",
    value: "MostlyCloudy"
  },
  {
    label: "Cloudy",
    value: "Cloudy"
  },
]

var latlang = {
  lat: 12.953473209752545,
  lng: 77.58613154745797
}

var cityRetrieved = []
var countriesAll = ["DZ", "AO", "BJ", "BW", "BF", "BI", "CM", "CV", "CF", "TD", "KM", "CG", "CD", "CI", "DJ", "EG", "GQ", "ER", "ET", "GA", "GM", "GH", "GN", "GW", "KE", "LS", "LR", "LY", "MG", "MW", "ML", "MR", "MU", "YT", "MA", "MZ", "NA", "NE", "NG", "RW", "RE", "SH", "SN", "SC", "SL", "SO", "ZA", "SD", "SZ", "ST", "TZ", "TG", "TN", "UG", "EH", "ZM", "ZW", "AI", "AG", "AR", "AW", "BS", "BB", "BZ", "BM", "BO", "BR", "VG", "CA", "KY", "CL", "CO", "CR", "CU", "DM", "DO", "EC", "SV", "FK", "GF", "GL", "GD", "GP", "GT", "GY", "HT", "HN", "JM", "MQ", "MX", "MS", "AN", "NI", "PA", "PY", "PE", "PR", "BL", "KN", "LC", "MF", "PM", "VC", "SR", "TT", "TC", "VI", "US", "UY", "VE", "AF", "AM", "AZ", "BH", "BD", "BT", "BN", "KH", "CN", "GE", "HK", "IN", "ID", "IR", "IQ", "IL", "JP", "JO", "KZ", "KW", "KG", "LA", "LB", "MO", "MY", "MV", "MN", "MM", "NP", "NT", "KP", "OM", "PK", "PS", "YD", "PH", "QA", "SA", "SG", "KR", "LK", "SY", "TW", "TJ", "TH", "TL", "TR", "™", "AE", "UZ", "VN", "YE", "AL", "AD", "BY", "BA", "DD", "FO", "GI", "GG", "IS", "IM", "JE", "LI", "MK", "FX", "MD", "MC", "ME", "NO", "RU", "SM", "RS", "CS", "SJ", "CH", "UA", "SU", "VA", "AX", "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "GB", "AS", "AQ", "AU", "BV", "IO", "CX", "CC", "CK", "FJ", "PF", "TF", "GU", "HM", "KI", "MH", "FM", "NR", "NC", "NZ", "NU", "NF", "MP", "PW", "PG", "PN", "WS", "SB", "GS", "TK", "TO", "TV", "UM", "VU", "WF"]


const Geo_Targeting = (props) => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const [lineItem, setLineItem] = useState({
    countriesIn: [],
    countriesEx: [],
    citiesIn: [],
    citiesEx: [],
    zipCodesIn: [],
    zipCodesEx: [],
    latLongIn: [],
    latLongEx: [],
    weathersIn: [],
    weathersEx: [],
  })

  const [targetStates, setTargetStates] = useState({
    countries: false,
    cities: false,
    zipCodes: false,
    latLong: false,
    weathers: false,
  })


  const [value, setValue] = useState([]);
  const [regionData, setRegionData] = useState([])
  const [cityData, setCityData] = useState([])
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedWheather, setSelectedWheather] = useState([]);
  const [zipCode, setZipCode] = useState([])
  const [zipCodeValues, setZipCodeValues] = useState('')

  const [countriesState, setCountriesState] = useState(true);
  const [citiesState, setCitiesState] = useState(true);
  const [whetherState, setWhetherState] = useState(true);
  const [zipCodesState, setZipCodesState] = useState(true);
  const [countryData, setCountryData] = useState([]);
  const [optionData, setOptionData] = useState({
    options: []
  });
  const [mapState, setMapState] = useState(true);
  const [mapStatus, setMapStatus] = useState(false);
  const [selectAllCity, setSelectAllCity] = useState(false);
  const [selectAllCountry, setSelectAllCountry] = useState(false);

  const [treeSelect, setTreeSelect] = useState({
    checked: [],
    expanded: [],
    keyword: ''
  })

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const myRef = useRef();
  const cityRef = useRef();

  const handleClickOutside = e => {
    if (!myRef.current.contains(e.target)) {
      setIsOptionsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const [countriesData, setCountriesData] = useState([])
  const [treeSelectCity, setTreeSelectCity] = useState({
    checked: [],
    expanded: [],
    keyword: ''
  })
  const [isOptionsOpenCity, setIsOptionsOpenCity] = useState(false);

  const handleClickCityOutside = e => {
    if (!cityRef.current.contains(e.target)) {
      setIsOptionsOpenCity(false);
    }
  };

  const toggleOptionsCity = () => {
    if (treeSelect.checked && treeSelect.checked.length > 0) {
      setIsOptionsOpenCity(!isOptionsOpenCity);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickCityOutside);
    return () => document.removeEventListener("mousedown", handleClickCityOutside);
  });

  const [geoLocation, setGeoLocation] = useState({
    center: {
      lat: 12.95347,
      lng: 77.58613
    },
    zoom: 16,
  })

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,
    }
  }



  const changeLatLang = (e) => {
    const { name, value } = e.target;
    if (name === 'lat') {
      setGeoLocation(geoLocation => ({ ...geoLocation, center: { ...geoLocation.center, lat: value } }))
    } else {
      setGeoLocation(geoLocation => ({ ...geoLocation, center: { ...geoLocation.center, lng: value } }))
    }
    let latlangList = ['', '']
    if (name === 'lat') {
      latlangList[0] = value
    } else {
      latlangList[1] = value
    }
    mapState ? setLineItem(lineItem => ({ ...lineItem, latLongIn: latlangList })) : setLineItem(lineItem => ({ ...lineItem, latLongEx: latlangList }))
    mapState ? props.selectedItems({ latLongIn: latlangList }) : props.selectedItems({ latLongEx: latlangList })
  }

  const displayMap = () => {
    setMapStatus(!mapStatus)
  }

  useEffect(() => {
    if (props.tabId !== 3) {
      setIsOptionsOpen(false);
    }
  }, [props.tabId])

  useEffect(() => {
    // $('#country_targeting').multiselect();

    body.url = "http://127.0.0.1:2002/v1.0/regions/list"
    axios.post("/v1.0/connectReportAPI", body,
      {
        headers: headers
      })
      .then((res) => {
        if (res.data.responseData) {
          let trees = [
            createTree("Africa", res.data.responseData.Africa),
            createTree("America", res.data.responseData.Americas),
            createTree("Asia", res.data.responseData.Asia),
            createTree("Europe", res.data.responseData.Europe),
            createTree("GDPR", res.data.responseData.GDPR),
            createTree("Oceania", res.data.responseData.Oceania),
          ];
          setCountryData(trees)
          setCountriesData([...res.data.responseData.Africa, ...res.data.responseData.Americas, ...res.data.responseData.Asia, ...res.data.responseData.Europe, ...res.data.responseData.GDPR, ...res.data.responseData.Oceania])
          if (props.lineItemData !== '') {
            props.targetedSelectedItems({ cities: props.lineItemData.citiesIn.length > 0 || props.lineItemData.citiesEx.length > 0 ? true : false})
              setTargetStates(targetStates => ({
                ...targetStates,
                cities: props.lineItemData.citiesIn.length > 0 || props.lineItemData.citiesEx.length > 0 ? true : false,}))
                cityRetrieved = props.lineItemData.citiesIn.length > 0 ? props.lineItemData.citiesIn : props.lineItemData.citiesEx.length > 0 ? props.lineItemData.citiesEx : [] 
            setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: props.lineItemData.citiesIn.length > 0 ? props.lineItemData.citiesIn : props.lineItemData.citiesEx.length > 0 ? props.lineItemData.citiesEx : [] }));
            if (props.lineItemData.countriesIn.length > 0 || props.lineItemData.countriesEx.length > 0) {
              getDefaultSelectedCities(props.lineItemData.countriesIn.length > 0 ? props.lineItemData.countriesIn : props.lineItemData.countriesEx.length > 0 ? props.lineItemData.countriesEx : [], [...res.data.responseData.Africa, ...res.data.responseData.Americas, ...res.data.responseData.Asia, ...res.data.responseData.Europe, ...res.data.responseData.GDPR, ...res.data.responseData.Oceania])
            }
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });

  }, []);

  const getCities = (countries, countryData) => {
    setCityData([])
    let countryList = []
    var array3 = countryData.filter(function (obj) {
      return countries.indexOf(obj.value) !== -1
    });
    array3.map((item, index) => {
      countryList.push(item.label)
    })
    const bodyCity = {
      "url": "http://127.0.0.1:2002/v1.0/cities/list/countrylist",
      "requestBody": {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
        "countrylist": countryList
      }
    }
    axios.post("/v1.0/connectReportAPI", bodyCity,
      {
        headers: headers
      })
      .then((res) => {
        if (res.data.responseData) {
          let trees = []
          let selectedCities = []
          if (res.data.responseData.response.length > 0) {
            res.data.responseData.response.map((item, i) => {
              trees.push(createCityTree(item.label, item.value))
              selectedCities = [...selectedCities, ...item.value]
              cityRetrieved = selectedCities
            })
          }
          setSelectAllCity(false)
          setCityData(trees)
          countriesState ? props.selectedItems({ countriesIn: countries }) : props.selectedItems({ countriesEx: countries })
          if (treeSelectCity.checked.length > 0 && cityRetrieved.length > 0) {
            let arr1 = [treeSelectCity.checked, cityRetrieved]
            let newArr = arr1.reduce((x, y) => x.filter((z) => y.includes(z)));
            console.log(newArr)
            setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: newArr }));
            citiesState ? setLineItem(lineItem => ({ ...lineItem, citiesIn: newArr })) : setLineItem(lineItem => ({ ...lineItem, citiesEx: newArr }));
            citiesState ? props.selectedItems({ citiesIn: newArr }) : props.selectedItems({ citiesEx: newArr })
          }

          // setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: selectedCities }));
          // countriesState ? props.selectedItems({ countriesIn: countries }) : props.selectedItems({ countriesEx: countries })
          // citiesState ? setLineItem(lineItem => ({ ...lineItem, citiesIn: selectedCities })) : setLineItem(lineItem => ({ ...lineItem, citiesEx: selectedCities }));
          // citiesState ? props.selectedItems({ citiesIn: selectedCities }) : props.selectedItems({ citiesEx: selectedCities })
          // setTargetStates(targetStates => ({
          //   ...targetStates,
          //   cities: true,
          // }));
          // props.targetedSelectedItems({ cities: true, })
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const toggleSelectAllCountries = (e) => {
    setSelectAllCountry(e.target.checked)
    if (e.target.checked) {
      setTreeSelect(treeSelect => ({ ...treeSelect, checked: countriesAll }));
      countriesState ? setLineItem(lineItem => ({ ...lineItem, countriesIn: cityRetrieved })) : setLineItem(lineItem => ({ ...lineItem, countriesEx: countriesAll }));
      getCities(countriesAll, countriesData)
      setTargetStates(targetStates => ({
        ...targetStates,
        countries: true,
      }));
      props.targetedSelectedItems({ countries: true, })
    } else {
      cityRetrieved = []
      setTreeSelect(treeSelect => ({ ...treeSelect, checked: [] }));
      countriesState ? setLineItem(lineItem => ({ ...lineItem, countriesIn: [] })) : setLineItem(lineItem => ({ ...lineItem, countriesEx: [] }));
      countriesState ? props.selectedItems({ countriesIn: [] }) : props.selectedItems({ countriesEx: [] })
    }
  }

  const toggleSelectAllCities = (e) => {
    setSelectAllCity(e.target.checked)
    if (e.target.checked) {
      setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: cityRetrieved }));
      citiesState ? setLineItem(lineItem => ({ ...lineItem, citiesIn: cityRetrieved })) : setLineItem(lineItem => ({ ...lineItem, citiesEx: cityRetrieved }));
      citiesState ? props.selectedItems({ citiesIn: cityRetrieved }) : props.selectedItems({ citiesEx: cityRetrieved })
      setTargetStates(targetStates => ({
        ...targetStates,
        cities: true,
      }));
      props.targetedSelectedItems({ cities: true, })
      console.log(cityRetrieved)
    } else {
      setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: [] }));
      citiesState ? setLineItem(lineItem => ({ ...lineItem, citiesIn: [] })) : setLineItem(lineItem => ({ ...lineItem, citiesEx: [] }));
      citiesState ? props.selectedItems({ citiesIn: [] }) : props.selectedItems({ citiesEx: [] })
    }
  }

  const getDefaultSelectedCities = (countries, countryData) => {
    setCityData([])
    let countryList = []
    var array3 = countryData.filter(function (obj) {
      return countries.indexOf(obj.value) !== -1
    });
    array3.map((item, index) => {
      countryList.push(item.label)
    })
    const bodyCity = {
      "url": "http://127.0.0.1:2002/v1.0/cities/list/countrylist",
      "requestBody": {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
        "countrylist": countryList
      }
    }
    axios.post("/v1.0/connectReportAPI", bodyCity,
      {
        headers: headers
      })
      .then((res) => {
        if (res.data.responseData) {
          let trees = []
          let selectedCities = []
          if (res.data.responseData.response.length > 0) {
            res.data.responseData.response.map((item, i) => {
              trees.push(createCityTree(item.label, item.value))
              selectedCities = [...selectedCities, ...item.value]
              cityRetrieved = selectedCities
            })
          }
          setCityData(trees)
          setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: props.lineItemData.citiesIn.length > 0 ? props.lineItemData.citiesIn : props.lineItemData.citiesEx.length > 0 ? props.lineItemData.citiesEx : [] }));
          setSelectedCity(props.lineItemData.citiesIn.length > 0 ? objectModals(props.lineItemData.citiesIn) : props.lineItemData.citiesEx.length > 0 ? objectModals(props.lineItemData.citiesEx) : [])
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const createTree = (groupName, options) => {
    return {
      label: groupName,
      value: groupName,
      children: options,
    };
  };

  const createCityTree = (groupName, options) => {

    return {
      label: groupName,
      value: groupName,
      children: objectModalCity(options)
    };
  };

  const objectModalCity = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }


  useEffect(() => {
    if (props.lineItemData !== '') {
      setLineItem(props.lineItemData)
      setSelectedRegion(props.lineItemData.countriesIn.length > 0 ? objectModals(props.lineItemData.countriesIn) : props.lineItemData.countriesEx.length > 0 ? objectModals(props.lineItemData.countriesEx) : [])
      setTreeSelect(treeSelect => ({ ...treeSelect, checked: props.lineItemData.countriesIn.length > 0 ? props.lineItemData.countriesIn : props.lineItemData.countriesEx.length > 0 ? props.lineItemData.countriesEx : [] }));
      setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: props.lineItemData.citiesIn.length > 0 ? props.lineItemData.citiesIn : props.lineItemData.citiesEx.length > 0 ? props.lineItemData.citiesEx : [] }));
      setSelectedCity(props.lineItemData.citiesIn.length > 0 ? objectModals(props.lineItemData.citiesIn) : props.lineItemData.citiesEx.length > 0 ? objectModals(props.lineItemData.citiesEx) : [])
      setSelectedWheather(props.lineItemData.weathersIn.length > 0 ? objectModals(props.lineItemData.weathersIn) : props.lineItemData.weathersEx.length > 0 ? objectModals(props.lineItemData.weathersEx) : [])
      setZipCode(props.lineItemData.zipCodesIn.length > 0 ? props.lineItemData.zipCodesIn[0] : props.lineItemData.zipCodesEx.length > 0 ? props.lineItemData.zipCodesEx[0] : [])
      setZipCodeValues(props.lineItemData.zipCodesIn.length > 0 ? props.lineItemData.zipCodesIn : props.lineItemData.zipCodesEx.length > 0 ? props.lineItemData.zipCodesEx : [])
      setCountriesState(props.lineItemData.countriesIn.length > 0 ? true : props.lineItemData.countriesEx.length > 0 ? false : true)
      setCitiesState(props.lineItemData.citiesIn.length > 0 ? true : props.lineItemData.citiesEx.length > 0 ? false : true)
      setWhetherState(props.lineItemData.weathersIn.length > 0 ? true : props.lineItemData.weathersEx.length > 0 ? false : true)
      setZipCodesState(props.lineItemData.zipCodesIn.length > 0 ? true : props.lineItemData.zipCodesEx.length > 0 ? false : true)
      latlang = {
        lat: props.lineItemData.latLongIn.length > 0 ? props.lineItemData.latLongIn[0] : props.lineItemData.latLongEx.length > 0 ? props.lineItemData.latLongEx[0] : 0,
        lng: props.lineItemData.latLongIn.length > 0 ? props.lineItemData.latLongIn[1] : props.lineItemData.latLongEx.length > 0 ? props.lineItemData.latLongEx[1] : 0
      }
      setMapState(props.lineItemData.latLongIn.length > 0 ? true : props.lineItemData.latLongEx.length > 0 ? false : true)

      setTargetStates(targetStates => ({
        ...targetStates,
        countries: props.lineItemData.countriesIn.length > 0 || props.lineItemData.countriesEx.length > 0 ? true : false,
        cities: props.lineItemData.citiesIn.length > 0 || props.lineItemData.citiesEx.length > 0 ? true : false,
        zipCodes: props.lineItemData.zipCodesIn.length > 0 || props.lineItemData.zipCodesEx.length > 0 ? true : false,
        latLong: props.lineItemData.latLongIn.length > 0 || props.lineItemData.latLongEx.length > 0 ? true : false,
        weathers: props.lineItemData.weathersIn.length > 0 || props.lineItemData.weathersEx.length > 0 ? true : false,
      }));

      props.targetedSelectedItems({
        countries: props.lineItemData.countriesIn.length > 0 || props.lineItemData.countriesEx.length > 0 ? true : false,
        cities: props.lineItemData.citiesIn.length > 0 || props.lineItemData.citiesEx.length > 0 ? true : false,
        zipCodes: props.lineItemData.zipCodesIn.length > 0 || props.lineItemData.zipCodesEx.length > 0 ? true : false,
        latLong: props.lineItemData.latLongIn.length > 0 || props.lineItemData.latLongEx.length > 0 ? true : false,
        weathers: props.lineItemData.weathersIn.length > 0 || props.lineItemData.weathersEx.length > 0 ? true : false,
      })
    }
  }, []);

  useEffect(() => {
    if (treeSelect.checked.length === 0) {
      setSelectAllCity(false)
      setCityData([])
      setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: [] }));
      citiesState ? setLineItem(lineItem => ({ ...lineItem, citiesIn: [] })) : setLineItem(lineItem => ({ ...lineItem, citiesEx: [] }));
      citiesState ? props.selectedItems({ citiesIn: [] }) : props.selectedItems({ citiesEx: [] })
      cityRetrieved = []
      setTargetStates(targetStates => ({
        ...targetStates,
        cities: false, 
      }));
      props.targetedSelectedItems({ cities: false,})
    } else {
      setTargetStates(targetStates => ({
        ...targetStates, countries: true
      }));
      props.targetedSelectedItems({  countries: true })
    }
  }, [treeSelect.checked.length])

  const objectModals = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  const getLatLang = (e) => {
    var latlangList = []
    latlangList.push(e.lat)
    latlangList.push(e.lng)
    // setGeoLocation(geoLocation => ({ ...geoLocation,center:{...geoLocation.center,lat: e.lat,lng:e.lng}}));
    latlang = e
    mapState ? setLineItem(lineItem => ({ ...lineItem, latLongIn: latlangList })) : setLineItem(lineItem => ({ ...lineItem, latLongEx: latlangList }))
    mapState ? props.selectedItems({ latLongIn: latlangList }) : props.selectedItems({ latLongEx: latlangList })
  }

  const handleSelectRegion = country => (value) => {
    setValue(value.concat(options.filter((grpOpt) => !value.includes(grpOpt))));
    setValue(country)
    setSelectedRegion(country);
    var countryList = []
    country.map((item, index) => {
      countryList.push(item.value)
    })
    countriesState ? setLineItem(lineItem => ({ ...lineItem, countriesIn: countryList })) : setLineItem(lineItem => ({ ...lineItem, countriesEx: countryList }))
    countriesState ? props.selectedItems({ countriesIn: countryList }) : props.selectedItems({ countriesEx: countryList })
  }

  const handleSelectCity = city => {
    setSelectedCity(city);
    var citiesLists = []
    city.map((item, index) => {
      citiesLists.push(item.value)
    })
    citiesState ? setLineItem(lineItem => ({ ...lineItem, citiesIn: citiesLists })) : setLineItem(lineItem => ({ ...lineItem, citiesEx: citiesLists }))
    citiesState ? props.selectedItems({ citiesIn: citiesLists }) : props.selectedItems({ citiesEx: citiesLists })
  }

  const handleSelectWheather = wheather => {
    setSelectedWheather(wheather);
    var wheatherLists = []
    wheather.map((item, index) => {
      wheatherLists.push(item.value)
    })
    whetherState ? setLineItem(lineItem => ({ ...lineItem, weathersIn: wheatherLists })) : setLineItem(lineItem => ({ ...lineItem, weathersEx: wheatherLists }))
    whetherState ? props.selectedItems({ weathersIn: wheatherLists }) : props.selectedItems({ weathersEx: wheatherLists })
  }

  const handleZipCode = (e) => {
    const { name, value } = e.target;
    setZipCode(e.target.value)
    setZipCodeValues(e.target.value)
    let zipcodes = value.split(/[\s,]+/);
    zipCodesState ? setLineItem(lineItem => ({ ...lineItem, zipCodesIn: zipcodes })) : setLineItem(lineItem => ({ ...lineItem, zipCodesEx: zipcodes }))
    zipCodesState ? props.selectedItems({ zipCodesIn: zipcodes }) : props.selectedItems({ zipCodesEx: zipcodes })
  }

  const countryStateChange = (e) => {
    setCountriesState(e.target.checked)
    var countriess = []
    if (lineItem.countriesIn.length > 0) {
      countriess = lineItem.countriesIn
    } else {
      countriess = lineItem.countriesEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, countriesIn: countriess, countriesEx: [] }));
      props.selectedItems({ countriesIn: countriess, countriesEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, countriesEx: countriess, countriesIn: [] }));
      props.selectedItems({ countriesEx: countriess, countriesIn: [] })
    }
  }

  const cityStateChange = (e) => {
    setCitiesState(e.target.checked)
    var citiess = []
    if (lineItem.citiesIn.length > 0) {
      citiess = lineItem.citiesIn
    } else {
      citiess = lineItem.citiesEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, citiesIn: citiess, citiesEx: [] }));
      props.selectedItems({ citiesIn: citiess, citiesEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, citiesEx: citiess, citiesIn: [] }));
      props.selectedItems({ citiesEx: citiess, citiesIn: [] })
    }
  }

  const wheatherStateChange = (e) => {
    setWhetherState(e.target.checked)
    var wheatherss = []
    if (lineItem.weathersIn.length > 0) {
      wheatherss = lineItem.weathersIn
    } else {
      wheatherss = lineItem.weathersEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, weathersIn: wheatherss, weathersEx: [] }));
      props.selectedItems({ weathersIn: wheatherss, weathersEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, weathersEx: wheatherss, weathersIn: [] }));
      props.selectedItems({ weathersEx: wheatherss, weathersIn: [] })
    }
  }

  const latLangChange = (e) => {
    setMapState(e.target.checked)
    var latlangs = []
    if (lineItem.latLongIn.length > 0) {
      latlangs = lineItem.latLongIn
    } else {
      latlangs = lineItem.latLongEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, latLongIn: latlangs, latLongEx: [] }));
      props.selectedItems({ latLongIn: latlangs, latLongEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, latLongEx: latlangs, latLongIn: [] }));
      props.selectedItems({ latLongEx: latlangs, latLongIn: [] })
    }
  }

  const zipCodeStateChange = (e) => {
    setZipCodesState(e.target.checked)
    var zipCodes = []
    if (lineItem.zipCodesIn.length > 0) {
      zipCodes = lineItem.zipCodesIn
    } else {
      zipCodes = lineItem.zipCodesEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, zipCodesIn: zipCodes, zipCodesEx: [] }));
      props.selectedItems({ zipCodesIn: zipCodes, zipCodesEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, zipCodesEx: zipCodes, zipCodesIn: [] }));
      props.selectedItems({ zipCodesEx: zipCodes, zipCodesIn: [] })
    }
  }

  const groupSelect = (options) => (e) => {
    if (e.target.checked) {
      var countryList = []
      options.map((item, index) => {
        countryList.push(item.value)
      })
      countriesState ? setLineItem(lineItem => ({ ...lineItem, countriesIn: countryList })) : setLineItem(lineItem => ({ ...lineItem, countriesEx: countryList }))
      countriesState ? props.selectedItems({ countriesIn: countryList }) : props.selectedItems({ countriesEx: countryList })
      let arr = value
      let arr2 = []
      options.map((item, index) => {
        arr.push(item)
      })
      const ids = arr.map(o => o.value)
      const filtered = arr.filter(({ value }, index) => !ids.includes(value, index + 1))
      setValue(filtered);
    } else {
      let myArray = value.filter(ar => !options.find(rm => (rm.label === ar.label && ar.value === rm.value)))
      setValue(myArray)
    }
  }

  const createGroup = (groupName, options) => {
    return {
      label: (() => {
        return (
          <div style={{ cursor: 'pointer', color: 'black', fontSize: '14px', fontWeight: '600' }}
          // onClick={() => {

          //   var countryList = []
          //   options.map((item, index) => {
          //     countryList.push(item.value)
          //   })
          //   countriesState ? setLineItem(lineItem => ({ ...lineItem, countriesIn: countryList })) : setLineItem(lineItem => ({ ...lineItem, countriesEx: countryList }))
          //   countriesState ? props.selectedItems({ countriesIn: countryList }) : props.selectedItems({ countriesEx: countryList })
          //   return setValue(...value,options);
          // }
          // }
          >
            <input style={{ margin: '5px 10px' }} type="checkbox" onChange={groupSelect(options)} />
            <label>{groupName}</label>
          </div>
        );
      })(),
      options: options,
    };
  };


  const Option = props => {
    const changeChild = props => (e) => {
    }
    // console.log(props)
    return (
      <div>
        <components.Option {...props} style={{ padding: '8px 10px 0px' }}>
          <input style={{ margin: '5px 10px' }} type="checkbox" onChange={changeChild(props)} checked={props.isSelected} />
          <label>{props.label}</label>
        </components.Option>
      </div>
    )
  }






  const OptionGroup = props => (
    <div>
      <components.Option {...props} style={{ padding: '8px 10px 0px' }}>
        <input style={{ margin: '5px 10px' }} type="checkbox" checked={props.isSelected} onChange={() => {
          console.log(props)
          null
        }} />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );


  const retrieveCities = (countryCodes, node) => {
    let cityBody = {
      url: "http://127.0.0.1:2002/v1.0/cities/list/countrylist",
      "requestBody": {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        countrylist: countryCodes,
        name: userInfo.name,
      }
    }
    axios.post("/v1.0/connectOP", cityBody,
      {
        headers: headers
      })
      .then((res) => {
        if (res.data.responseData) {

          // var cityList = []
          // var citiesList = []
          // cityList.map((item, index) => {
          //   let obj = {}
          //   obj.value = item
          //   obj.label = item
          //   citiesList.push(obj)
          // })
          // setCityData(citiesList)
          // console.log(citiesList)
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const onSearchInputChange = (event, data, searchedNodes) => {
    console.log(event, data, searchedNodes)
    setTreeSelect(treeSelect => {
      if (treeSelect.keyword.trim() && !data.value.trim()) {
        return {
          ...treeSelect,
          expanded: [],
          keyword: data.value
        };
      }
      return {
        ...treeSelect,
        expanded: getAllValuesFromNodes(searchedNodes, true),
        keyword: data.value
      };
    });
  };

  const onSearchInputCityChange = (event, data, searchedNodes) => {
    console.log(event, data, searchedNodes)
    setTreeSelectCity(treeSelectCity => {
      if (treeSelectCity.keyword.trim() && !data.value.trim()) {
        return {
          ...treeSelectCity,
          expanded: [],
          keyword: data.value
        };
      }
      return {
        ...treeSelectCity,
        expanded: getAllValuesFromNodes(searchedNodes, true),
        keyword: data.value
      };
    });
  };

  const getAllValuesFromNodes = (nodes, firstLevel) => {
    if (firstLevel) {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, false));
        }
      }
      return values;
    } else {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, false));
        }
      }
      return values;
    }
  };

  const keywordFilter = (nodes, keyword) => {
    let newNodes = [];
    for (let n of nodes) {
      if (n.children) {
        const nextNodes = keywordFilter(n.children, keyword);
        if (nextNodes.length > 0) {
          n.children = nextNodes;
        } else if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.children = nextNodes.length > 0 ? nextNodes : [];
        }
        if (
          nextNodes.length > 0 ||
          n.label.toLowerCase().includes(keyword.toLowerCase())
        ) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      } else {
        if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      }
    }
    if (newNodes.length === 1) {
      let regions = countryData
      regions.map((item, index) => {
        if (item.value.toLowerCase() === newNodes[0].value.toLowerCase()) {
          newNodes[0].children = item.children
        }
      })
    }
    return newNodes;
  };

  const keywordFilterCity = (nodes, keyword) => {
    let newNodes = [];
    for (let n of nodes) {
      if (n.children) {
        const nextNodes = keywordFilterCity(n.children, keyword);
        if (nextNodes.length > 0) {
          n.children = nextNodes;
        } else if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.children = nextNodes.length > 0 ? nextNodes : [];
        }
        if (
          nextNodes.length > 0 ||
          n.label.toLowerCase().includes(keyword.toLowerCase())
        ) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      } else {
        if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      }
    }
    if (newNodes.length === 1) {
      let countries = cityData
      countries.map((item, index) => {
        if (item.value.toLowerCase() === newNodes[0].value.toLowerCase()) {
          newNodes[0].children = item.children
        }
      })
    }
    return newNodes;
  };

  const getHighlightText = (text, keyword) => {
    console.log(text, keyword)
    const startIndex = text.indexOf(keyword);
    return startIndex !== -1 ? (
      <span>
        {text.substring(0, startIndex)}
        <span style={{ color: "red" }}>
          {text.substring(startIndex, startIndex + keyword.length)}
        </span>
        {text.substring(startIndex + keyword.length)}
      </span>
    ) : (
      <span>{text}</span>
    );
  };


  const checkValue = (row, checked) => {
    if (row.isParent) {
      if (row.checked) {
        let finalChecked = [...treeSelect.checked, ...checked]
        finalChecked = finalChecked.filter((item, index) => finalChecked.indexOf(item) === index)
        setTreeSelect(treeSelect => ({ ...treeSelect, checked: finalChecked }));
        countriesState ? setLineItem(lineItem => ({ ...lineItem, countriesIn: finalChecked })) : setLineItem(lineItem => ({ ...lineItem, countriesEx: finalChecked }));
        // countriesState ? props.selectedItems({ countriesIn: finalChecked }) : props.selectedItems({ countriesEx: finalChecked })
        if (finalChecked.length > 0) {
          getCities(finalChecked, countriesData)
        }
      } else {
        let finalChecked = [...treeSelect.checked]
        let filteredChildren = row.children.map(a => a.value);
        let result = finalChecked.filter(val => !filteredChildren.includes(val));
        setTreeSelect(treeSelect => ({ ...treeSelect, checked: result }));
        countriesState ? setLineItem(lineItem => ({ ...lineItem, countriesIn: result })) : setLineItem(lineItem => ({ ...lineItem, countriesEx: result }));
        // countriesState ? props.selectedItems({ countriesIn: result }) : props.selectedItems({ countriesEx: result })
        if (result.length > 0) {
          getCities(result, countriesData)
        }
      }
    } else {
      if (row.checked) {
        let finalChecked = [...treeSelect.checked, ...checked]
        finalChecked = finalChecked.filter((item, index) => finalChecked.indexOf(item) === index)
        setTreeSelect(treeSelect => ({ ...treeSelect, checked: finalChecked }));
        countriesState ? setLineItem(lineItem => ({ ...lineItem, countriesIn: finalChecked })) : setLineItem(lineItem => ({ ...lineItem, countriesEx: finalChecked }));
        // countriesState ? props.selectedItems({ countriesIn: finalChecked }) : props.selectedItems({ countriesEx: finalChecked })
        if (finalChecked.length > 0) {
          getCities(finalChecked, countriesData)
        }
      } else {
        let finalChecked = [...treeSelect.checked]
        let index = finalChecked.indexOf(row.value)
        finalChecked.splice(index, 1)
        setTreeSelect(treeSelect => ({ ...treeSelect, checked: finalChecked }));
        countriesState ? setLineItem(lineItem => ({ ...lineItem, countriesIn: finalChecked })) : setLineItem(lineItem => ({ ...lineItem, countriesEx: finalChecked }));
        // countriesState ? props.selectedItems({ countriesIn: finalChecked }) : props.selectedItems({ countriesEx: finalChecked })
        if (finalChecked.length > 0) {
          getCities(finalChecked, countriesData)
        }
      }
    }
  }

  const checkCityValue = (row, checked) => {
    if (row.isParent) {
      if (row.checked) {
        let finalChecked = [...treeSelectCity.checked, ...checked]
        finalChecked = finalChecked.filter((item, index) => finalChecked.indexOf(item) === index)
        setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: finalChecked }));
        citiesState ? setLineItem(lineItem => ({ ...lineItem, citiesIn: finalChecked })) : setLineItem(lineItem => ({ ...lineItem, citiesEx: finalChecked }));
        citiesState ? props.selectedItems({ citiesIn: finalChecked }) : props.selectedItems({ citiesEx: finalChecked })
      } else {
        let finalChecked = [...treeSelectCity.checked]
        let filteredChildren = row.children.map(a => a.value);
        let result = finalChecked.filter(val => !filteredChildren.includes(val));
        setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: result }));
        citiesState ? setLineItem(lineItem => ({ ...lineItem, citiesIn: result })) : setLineItem(lineItem => ({ ...lineItem, citiesEx: result }));
        citiesState ? props.selectedItems({ citiesIn: result }) : props.selectedItems({ citiesEx: result })
      }
    } else {
      if (row.checked) {
        let finalChecked = [...treeSelectCity.checked, ...checked]
        finalChecked = finalChecked.filter((item, index) => finalChecked.indexOf(item) === index)
        setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: finalChecked }));
        citiesState ? setLineItem(lineItem => ({ ...lineItem, citiesIn: finalChecked })) : setLineItem(lineItem => ({ ...lineItem, citiesEx: finalChecked }));
        citiesState ? props.selectedItems({ citiesIn: finalChecked }) : props.selectedItems({ citiesEx: finalChecked })
      } else {
        let finalChecked = [...treeSelectCity.checked]
        let index = finalChecked.indexOf(row.value)
        finalChecked.splice(index, 1)
        setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: finalChecked }));
        citiesState ? setLineItem(lineItem => ({ ...lineItem, citiesIn: finalChecked })) : setLineItem(lineItem => ({ ...lineItem, citiesEx: finalChecked }));
        citiesState ? props.selectedItems({ citiesIn: finalChecked }) : props.selectedItems({ citiesEx: finalChecked })
      }
    }
  }

  const handleCheck = e => {
    const { name, value } = e.target;
    setTargetStates(targetStates => ({ ...targetStates, [name]: e.target.checked }));
    props.targetedSelectedItems({ [name]: e.target.checked })
    if (name === 'countries') {
      setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: [] }));
      setLineItem(lineItem => ({ ...lineItem, citiesEx: [], citiesIn: [] }));
      props.selectedItems({ citiesEx: [], citiesIn: [] })
      cityRetrieved = []
      setTreeSelect(treeSelect => ({ ...treeSelect, checked: [] }));
      setLineItem(lineItem => ({ ...lineItem, countriesEx: [], countriesIn: [] }));
      props.selectedItems({ countriesEx: [], countriesIn: [] })
    } if (name === 'cities') {
      setSelectAllCity(false)
      setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: [] }));
      setLineItem(lineItem => ({ ...lineItem, citiesEx: [], citiesIn: [] }));
      props.selectedItems({ citiesEx: [], citiesIn: [] })
    }
   if (name === 'weathers') {
      setSelectedWheather([])
      setLineItem(lineItem => ({ ...lineItem, weathersEx: [], weathersIn: [] }));
      props.selectedItems({ weathersEx: [], weathersIn: [] })
    }
    if (name === 'zipCodes') {
      setZipCodeValues('')
      setLineItem(lineItem => ({ ...lineItem, zipCodesIn: [], zipCodesEx: [] }));
      props.selectedItems({ zipCodesIn: [], zipCodesEx: [] })
    }
     if (name === 'latLong') {
      latlang = {
        lat: 12.953473209752545,
        lng: 77.58613154745797
      }
      setLineItem(lineItem => ({ ...lineItem, latLongEx: [], latLongIn: [] }));
      props.selectedItems({ latLongEx: [], latLongIn: [] })
    }
  }



  return (

    <Form>
      <Row>
        <Col lg="8">
          <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="countries" id='countries' checked={targetStates.countries} onChange={handleCheck}  />
              <Label className='m-3 mt-0 ' htmlFor='countries'>Country Targeting</Label>
            </div>
            {countryData.length > 0 && targetStates.countries &&
              <div className="form-group d-flex mt-1">
                <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="countriesSelectAll" id='countriesSelectAll' checked={(selectAllCountry && treeSelect.checked.length === 252)} onChange={toggleSelectAllCountries} disabled={props.selectedArchived} />
                <Label className='m-3 mt-0 ' htmlFor='countriesSelectAll'>Select All </Label>
              </div>
            }
            <div className='row checkbox-tree rmsc'>
              <div className='col-9' ref={myRef}>

                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isOptionsOpen}
                  className={'selectionButton ' + (isOptionsOpen ? "expanded" : "")}
                  onClick={toggleOptions}
                  disabled={props.selectedArchived || !targetStates.countries}
                >
                  {treeSelect.checked && treeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{treeSelect.checked.length + " Countries are Selected"}</span> : "Please Select Countries"}
                </button>
                <Input
                  style={{ display: isOptionsOpen ? 'block' : 'none' }}
                  placeholder="Search Countries..."
                  className='mt-1'
                  value={treeSelect.keyword}
                  onChange={(event, data) => {
                    onSearchInputChange(event, event.target, treeSelect.keyword.trim()
                      ? keywordFilter(_.cloneDeep(countryData), treeSelect.keyword.trim())
                      : countryData);
                  }}
                />
                <ul
                  className={`options ${isOptionsOpen ? "show" : ""}`}
                  role="listbox"
                  tabIndex={-1}
                >
                  <CheckboxTree
                    nodes={treeSelect.keyword.trim()
                      ? keywordFilter(_.cloneDeep(countryData), treeSelect.keyword.trim())
                      : countryData}
                    checked={treeSelect.checked}
                    expanded={treeSelect.expanded}
                    onCheck={(checked, row) => {
                      checkValue(row, checked)
                    }}
                    onExpand={expanded => setTreeSelect(treeSelect => ({ ...treeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-3" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd11"
                  checked={countriesState}
                  onChange={countryStateChange}
                  disabled={props.selectedArchived || !targetStates.countries}
                />
                <label htmlFor='customSwitchsizemd11' className="form-check-label">
                  {countriesState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>


          </div>
          <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="cities" id='cities' checked={targetStates.cities} onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='cities'>City Targeting</Label>
            </div>
            {cityData.length > 0 && targetStates.cities &&
              <div className="form-group d-flex mt-1">
                <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="citiesSelectAll" id='citiesSelectAll' checked={(selectAllCity && props.lineItemData !== '' ? (cityRetrieved.length === (props.lineItemData.citiesIn.length  || props.lineItemData.citiesEx.length) ? true : false) : treeSelectCity.checked.length === cityRetrieved.length ? true : false)} onChange={toggleSelectAllCities} disabled={props.selectedArchived} />
                <Label className='m-3 mt-0 ' htmlFor='citiesSelectAll'>Select All </Label>
              </div>
            }
            {/* {cityData.length > 0 && selectAllCity > 0 &&
              <div className="form-group d-flex mt-1">
                <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="citiesUnSelectAll" id='citiesUnSelectAll' checked={selectAllCity} onChange={toggleSelectAllCities} />
                <Label className='m-3 mt-0 ' htmlFor='citiesUnSelectAll'>UnSelect All Cities</Label>
              </div>
            } */}
            <div className='row checkbox-tree rmsc'>

              <div className='col-9' ref={cityRef}>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isOptionsOpenCity}
                  className={'selectionButton ' + (isOptionsOpenCity ? "expanded" : "")}
                  onClick={toggleOptionsCity}
                  disabled={props.selectedArchived || !targetStates.cities}
                >
                  {treeSelectCity.checked && treeSelectCity.checked.length > 0 ? <span style={{ color: 'black' }}>{treeSelectCity.checked.length + " Cities are Selected"}</span> : "Please Select Cities"}
                </button>
                <Input
                  style={{ display: isOptionsOpenCity ? 'block' : 'none' }}
                  placeholder="Search Cities..."
                  className='mt-1'
                  value={treeSelectCity.keyword}
                  onChange={(event, data) => {
                    onSearchInputCityChange(event, event.target, treeSelectCity.keyword.trim()
                      ? keywordFilterCity(_.cloneDeep(cityData), treeSelectCity.keyword.trim())
                      : cityData);
                  }}
                />
                <ul
                  className={`options ${isOptionsOpenCity ? "show" : ""}`}
                  role="listbox"
                  tabIndex={-1}
                >
                  <CheckboxTree
                    nodes={treeSelectCity.keyword.trim()
                      ? keywordFilterCity(_.cloneDeep(cityData), treeSelectCity.keyword.trim())
                      : cityData}
                    checked={treeSelectCity.checked}
                    expanded={treeSelectCity.expanded}
                    onCheck={(checked, row) => {
                      checkCityValue(row, checked)
                    }}
                    onExpand={expanded => setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>


              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-3" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd12"
                  checked={citiesState}
                  onChange={cityStateChange}
                  disabled={props.selectedArchived || !targetStates.cities}
                />
                <label htmlFor='customSwitchsizemd12' className="form-check-label">
                  {citiesState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="weathers" id='weathers' checked={targetStates.weathers} onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='weathers'>Weather Targeting</Label>
            </div>
            <div className='row'>
              <MultiSelect
                options={weathers}
                value={selectedWheather}
                onChange={handleSelectWheather}
                overrideStrings={{ "selectSomeItems": "Please Select Weathers" }}
                className="col-9"
                disabled={props.selectedArchived || !targetStates.weathers}
              />
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-3" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd13"
                  checked={whetherState}
                  onChange={wheatherStateChange}
                  disabled={props.selectedArchived || !targetStates.weathers}
                />
                <label htmlFor='customSwitchsizemd13' className="form-check-label">
                  {whetherState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="zipCodes" id='zipCodes' checked={targetStates.zipCodes} onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='zipCodes'>ZipCode Targeting</Label>
            </div>
            <div className='row form-row'>
              <div className='col-9'>
                <Input
                  type="textarea"
                  id="textarea"
                  rows="1"
                  name="zipCode" value={zipCodeValues} onChange={handleZipCode}
                  placeholder="Enter ZipCode"
                  readOnly={props.selectedArchived || !targetStates.zipCodes}
                />
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-3" >
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd14"
                  checked={zipCodesState}
                  onChange={zipCodeStateChange}
                  disabled={props.selectedArchived || !targetStates.zipCodes}
                />
                <label htmlFor='customSwitchsizemd14' className="form-check-label">
                  {zipCodesState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>

          </div>
          <div className='mb-3'>

            <div className='row form-row'>
              <div className='col-9'>
                <div className="form-group d-flex mt-1">
                  <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="latLong" id='latLong' checked={targetStates.latLong} onChange={handleCheck} />
                  <Label className='m-3 mt-0 ' htmlFor='latLong'>Latitude and Longitude Targeting</Label>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <Label>
                      Latitude
                    </Label>
                    <Input
                      type="number"
                      name="lat"
                      value={latlang.lat}
                      onChange={changeLatLang}
                      readOnly={props.selectedArchived || !targetStates.latLong}
                    />
                  </div>
                  <div className='col-6'>
                    <Label>
                      Longitude
                    </Label>
                    <Input
                      type="number"
                      name="lng"
                      value={latlang.lng}
                      onChange={changeLatLang}
                      readOnly={props.selectedArchived || !targetStates.latLong}
                    />
                  </div>
                </div>
                {/* <span> Latitude : {latlang.lat} &nbsp;&nbsp; Longitude : {latlang.lng}</span> */}
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-3" >
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd15"
                  checked={mapState}
                  onChange={latLangChange}
                  disabled={props.selectedArchived || !targetStates.latLong}
                />
                <label htmlFor='customSwitchsizemd15' className="form-check-label">
                  {mapState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
            <div className="user-map mt-4" style={{ height: '35vh', border: '1px solid #8080808c ' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyAAn_BpK7mRTWXZoUJKQ_9w4Ns04rQfeY4" }}
                defaultCenter={geoLocation.center}
                defaultZoom={geoLocation.zoom}
                onClick={getLatLang}
              >
              </GoogleMapReact>
            </div>
          </div>
        </Col>

      </Row>
    </Form>
  )
}



const ValueContainer = ({ children, getValue, ...props }) => {
  let maxToShow = 2;
  var length = getValue().length;
  let displayChips = React.Children.toArray(children).slice(0, maxToShow);
  let shouldBadgeShow = length > maxToShow;
  let displayLength = length;

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue && displayChips}
      <div className="root">
        {shouldBadgeShow &&
          `${displayLength} ${length != 1 ? "Countries" : "Country"} Selected`}
      </div>

    </components.ValueContainer>
  );
};

export default Geo_Targeting

