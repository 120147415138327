import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Modal,
  Input,
  Spinner,
  UncontrolledPopover,
  Popover,
  PopoverHeader,
  PopoverBody,
  Label,
  Tooltip
} from "reactstrap"
import queryString from 'query-string';

import DateRangePickers from 'helpers/DateRangePicker'

import axios from "axios";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getAdUnit as onGetAdUnits,
  statusAdUnit as onStatusAdUnit,
  archiveAdUnit as onArchiveAdUnit,
  readAdUnit as onReadAdUnit,
  statusAdUnitSuccess,
  archiveAdUnitSuccess,
  addAdUnitFail,
  updateAdUnitFail,
} from "store/Supply/AdUnit/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Create_Publisher_AdUnit from "./Create_AdUnit";
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";
import Publisher_Supply_BreadCrumb from "components/Common/Publisher_Supply_BreadCrumb";

import LoadingTable from "pages/LoaderPages/LoadingTable";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var adUnitType = ''
var filterCount = 0

const Publisher_AdUnit = () => {
  const dispatch = useDispatch();
  const userRole = localStorage.getItem('UserRole');
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [btnprimary1, setBtnprimary1] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [jsTagModalState, setJsTagModalState] = useState(false);
  const [adUnitData, setAdUnitData] = useState()
  const inputRef = useRef();
  const [statusSearch, setStatusSearch] = useState('Active')
  const [adUnitTypeSearch, setAdUnitTypeSearch] = useState('')
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const [popoverdismiss, setpopoverdismiss] = useState(false);
  const [adUnits, setAdUnits] = useState([])
  const [readUnitData, setReadUnitData] = useState({
    url: '',
    adUnitName: '',
    adUnitType: ''
  })
  const [switchLoader, setSwitchLoader] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  console.log(parsed)

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const onToggleTooltip = (e) => {
    setIsTooltipOpen(!isTooltipOpen)
  }

  const [isTooltipOpen1, setIsTooltipOpen1] = useState(false);

  const onToggleTooltip1 = (e) => {
    setIsTooltipOpen1(!isTooltipOpen1)
  }

  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const adUnitTypeChange = (e) => {
    if (e.target.value === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (adUnitTypeSearch === '') {
      filterCount = filterCount + 1
    }
    setAdUnitTypeSearch(e.target.value)
  }

  const applyFilter = (e) => {
    getAdUnits(statusSearch, adUnitTypeSearch)
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setStatusSearch('Active')
    filterCount = 0
    setFilterState(false)
    getAdUnits('Active', '')
  }

  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
  }

  const toggleRightCanvas = () => {
    selectedData = ''
    getAdUnits(statusSearch, adUnitTypeSearch)
    setIsRight(!isRight);
  };

  const toggleDrawer = (close, adUnit, adUnitTypes) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('AdUnit Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('AdUnit Updated Successfully!...')
    }
    selectedData = adUnit
    adUnitType = adUnitTypes
    if (userRole === 'Publisher') {
      setIsArchived(true)
    } else {
      setIsArchived(adUnit.isAdUnitArchived)
    }
    getAdUnits(statusSearch, adUnitTypeSearch)
    setIsRight(close);
  };

  useEffect(() => {
    if (adUnits !== null) {
      getAdUnits(statusSearch, adUnitTypeSearch)
    }
  }, []);

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const getAdUnits = (status, TypeSearch) => {
    setLoading(true)
    const body = {
      "url": "http://127.0.0.1:3006/v1.0/AdUnits/read/byPartner",
      "requestBody": {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        partnerName: userInfo.name,
        filter: status,
        adUnitType: TypeSearch
      }
    }
    if (parsed.adUnitGroupId) {
      body.url = "http://127.0.0.1:3006/v1.0/AdUnits/read/byAdunitGroupID/" + parsed.adUnitGroupId
    }
    axios.post("/v1.0/connectPartner", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is adUnits ==>", res)
        setLoading(false)
        if (res.data.responseData.success) {
          if (res.data.responseData.response.length > 0) {
            res.data.responseData.response.map((ele, index) => {
              ele.id = index + 1
            })
            setAdUnits(res.data.responseData.response)
            setLoading(false)
          } else {
            setLoading(false)
            setAdUnits([])
          }
        } else {
          setLoading(false)
          setAdUnits([])
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log("e", err)
      });
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const adUnitListColumns = [
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "adUnitID",
      sort: true,
    },
    {
      dataField: "adUnitCreateTime",
      text: "Creation Date",
      sort: true,
    },
    {
      text: "AdUnit Name",
      dataField: "adUnitName",
      sort: true,
    },
    {
      text: "Publisher Name",
      dataField: "publisherName",
      sort: true,
    },
    {
      text: "AdUnit Group Name",
      dataField: "adUnitGroupName",
      sort: true,
    },
    {
      text: "AdUnit Type",
      dataField: "adUnitType",
      sort: true,
      formatter: (cellContent, adUnit) => (
        <div className=' ' >
          {adUnit.adUnitType === 'play' &&
            <span>Video</span>
          }
          {adUnit.adUnitType === 'stdb' &&
            <span>Standard Banner</span>
          }
          {adUnit.adUnitType === 'highImpact' &&
            <span>High Impact</span>
          }
          {adUnit.adUnitType === 'legacy' &&
            <span>Legacy</span>
          }
        </div>
      )
    },
    {
      dataField: "adUnitStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, adUnit) => (
        <>
          <span className={adUnit.newStatus === 'Active' ? "active-state" : adUnit.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{adUnit.newStatus}</span>
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',

      // eslint-disable-next-line react/display-name
      formatter: (cellContent, adUnit) => (
        <div className="d-flex gap-3">
          {parsed.adUnitGroupId &&
            <Link to={`/Publisher_AdUnit?adUnitGroupId=${parsed.adUnitGroupId}&adUnitGroupName=${parsed.adUnitGroupName}`} onClick={() => { tog_standard(adUnit); }}>
              <u>View</u>
            </Link>
          }
          {!parsed.adUnitGroupId &&
            <Link to={'#'} onClick={() => { tog_standard(adUnit); }}>
              <u>View</u>
            </Link>
          }
        </div>
      ),
    },
  ];


  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  function tog_standard(adUnit) {
    console.log(adUnit)
    if (adUnit !== '') {
      readAdUnit(adUnit.adUnitID)
      setJsTagModalState(!jsTagModalState);
    } else {
      setJsTagModalState(!jsTagModalState);
    }
  }

  const readAdUnit = (adUnitID) => {
    const body = {
      "url": "http://127.0.0.1:3006/v1.0/AdUnits/readByID/byPartner/" + adUnitID,
      "requestBody": {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        partnerName: userInfo.name,
      }
    }
    axios.post("/v1.0/connectPartner", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is adUnits ==>", res)
        if (res.data.responseData.success) {
          setReadUnitData(res.data.responseData.response)
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([readUnitData.url], {
      type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = (readUnitData.adUnitName + ".txt");
    document.body.appendChild(element);
    element.click();
  };

  const copyTag = (e) => {
    navigator.clipboard.writeText(readUnitData.url)
  }

  const copyAMPTag = (e) => {
    navigator.clipboard.writeText('amp.bidsxchange.com/AdCode.js')
  }

  console.log(adUnits)

  if (adUnits.length > 0) {
    adUnits.map((item, index) => {
      if (item.isAdUnitArchived) {
        selectRow.nonSelectable.push(index + 1)
      }
    })
  }

  useEffect(() => {
    console.log(loading)
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);


  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <MetaTags>
          <title>AdUnit | Bidsxchange</title>
        </MetaTags>
        <Container fluid style={{ pointerEvents: (loading) ? 'none' : 'unset' }}>
          {/* Render Breadcrumbs */}
          <div className=" d-sm-flex  justify-content-between">
            {parsed.adUnitGroupId ? (
              <Publisher_Supply_BreadCrumb title="Supply" breadcrumbItem="Ad Unit " adUnitGroupId={parsed.adUnitGroupId} />
            ) : (
              <SupplyBreadCrumb title="Supply" breadcrumbItem="Ad Unit " breadList="All Ad Units" />
            )}
            <div className=" text-sm-end">
              <div className="btn-group">
                <Dropdown
                  direction="left"
                  isOpen={isMenu}
                  toggle={toggleMenu}
                >
                  <DropdownToggle
                    tag="button"
                    className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                  >
                    <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                    {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                      Filter Applied
                    </span> : <span className="d-none font-16 d-sm-inline-block">
                      Filter
                    </span>
                    }
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                    <div className="dropdown-item-text">
                      <div className="row">
                        <h5 className="mb-0">Filters</h5>
                      </div>
                    </div>

                    <DropdownItem divider />
                    <Card>
                      <CardBody aria-hidden="true">
                        <Row >
                          <Col sm="12" className="mt-2">
                            <Label className="form-label">AdUnit Type</Label>
                            <div className=" text-sm-end">
                              <Input type="select" className="form-select" value={adUnitTypeSearch} onChange={adUnitTypeChange}>
                                <option value=''> All</option>
                                <option value='stdb' >Standard Banner</option>
                                <option value='highImpact'> High Impact</option>
                                <option value='play'> Video</option>
                              </Input>
                            </div>
                          </Col>
                          <Col sm="12" className="mt-2">
                            <Label className="form-label">Status</Label>
                            <div className=" text-sm-end">
                              <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                <option value=''> All</option>
                                <option value='Active' > Active</option>
                                <option value='Inactive'> InActive</option>
                                <option value='Archived'> Archived</option>
                              </Input>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <DropdownItem divider />
                    <Row>
                      <Col sm="6">
                        <DropdownItem
                          className="text-primary text-center"
                          onClick={clearAllFilter}
                        >
                          Clear ALL
                        </DropdownItem>
                      </Col>
                      <Col sm="6">
                        <DropdownItem
                          className="text-primary text-center"
                          onClick={applyFilter}
                        >
                          Apply Filter
                        </DropdownItem>
                      </Col>
                    </Row>

                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
          {switchLoader &&
            <LoadingTable loading={loading} targetLoading={handleSwitch} />
          }
          {!switchLoader &&
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                          <ToolkitProvider
                            keyField={keyField}
                            data={adUnits}
                            columns={adUnitListColumns}
                            // bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="8">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    {selectedRowsStatus && selectedIndex.length > 0 &&
                                      <div className="row m-0 React-Table-Selection">
                                        {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                        <div style={{ padding: '0.5% 2%' }}>

                                          {/* <button color="primary" className="btn btn-primary " onClick={statusAdUnit(true)}>Activate</button> */}
                                          <button color="primary" className="btn btn-primary " onClick={statusAdUnit(true)}>
                                            Activate
                                          </button>
                                          &nbsp;  &nbsp;
                                          <button color="primary" className="btn btn-primary " onClick={statusAdUnit(false)}>
                                            DeActivate
                                          </button>
                                          &nbsp;  &nbsp;
                                          <Button color="primary" className="btn btn-primary " onClick={archiveAdUnit} >
                                            Archive
                                          </Button>
                                          <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                            className="mdi mdi-close-thick font-size-18"
                                          ></i></Button>
                                        </div>
                                      </div>
                                    }
                                  </Col>
                                  <Col xl="12" className="table-responsive">
                                    <div >
                                      <BootstrapTable
                                        keyField={keyField}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        pagination={paginationFactory(pageOptions)}
                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                      // ref={node}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }

        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end' >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit AdUnit' : 'New ' + adUnitType + ' AdUnit'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_Publisher_AdUnit closeCanvas={toggleDrawer(false, '')} selectedAdUnit={selectedData} selectedArchived={isArchived} selectedAdUnitType={adUnitType} selectedAdUnitGroup={(parsed.status === 'AdUnitCreateByAdUnitGroup' || parsed.status === 'AdUnitCreateByParent') ? parsed : ''} />
        </OffcanvasBody>
      </Offcanvas>


      {readUnitData &&
        <Modal
          size="lg"
          isOpen={jsTagModalState}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Ad Unit : {readUnitData.adUnitName}
            </h5>
            <button
              type="button"
              onClick={() => {
                setJsTagModalState(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="row mb-2">
              <div className="col-md-11">
                <h5>JS Tag</h5>
              </div>
              <div className="col-md-1">
                <span className=" " id="controlledTooltip" onClick={copyTag} onMouseLeave={() => {
                  setIsTooltipOpen(false)
                }} style={{ cursor: 'pointer' }}>
                  <i
                    className="bx bx-copy-alt font-size-18"
                    id="edittooltip"
                  ></i>
                </span>
              </div>
            </div>
            <Tooltip
              isOpen={isTooltipOpen}
              placement="top"
              target="controlledTooltip"
              toggle={onToggleTooltip}
              trigger="click"
            >
              Copied
            </Tooltip>
            <Input
              ref={inputRef}
              type="textarea"
              id="textarea"
              rows="8"
              readOnly
              name="jsTag" value={readUnitData.url}
            />
            <br />
            {readUnitData.adUnitType === 'highImpact' &&
              <>
                <div className="row mb-2">
                  <div className="col-md-11">
                    <h5>AMP Code</h5>
                  </div>
                  <div className="col-md-1">
                    <span className="" id="controlledTooltip1" onClick={copyAMPTag} onMouseLeave={() => {
                      setIsTooltipOpen1(false)
                    }} style={{ cursor: 'pointer' }}>
                      <i
                        className="bx bx-copy-alt font-size-18"
                        id="edittooltip"
                      ></i>
                    </span>
                  </div>
                </div>
                <Tooltip
                  isOpen={isTooltipOpen1}
                  placement="top"
                  target="controlledTooltip1"
                  toggle={onToggleTooltip1}
                  trigger="click"
                >
                  Copied
                </Tooltip>
                <Input
                  type="textarea"
                  id="textarea"
                  className="mt-3"
                  rows="1"
                  readOnly
                  name="jsTag" value={'amp.bidsxchange.com/AdCode.js'}
                />
              </>
            }
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                setJsTagModalState(false);
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              Close
            </button>

            <Button
              color="primary"
              className="font-16 btn-block btn btn-primary"
              onClick={downloadTxtFile}
            >
              <i className="bx bx-download me-1" />
              Download Tag
            </Button>
          </div>
        </Modal>
      }
    </React.Fragment>
  )
}

export default Publisher_AdUnit





