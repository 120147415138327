import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import './index.css';
import './lettersSpacing.css';
import { ErrorBoundary } from 'react-error-boundary';

import store from "./store"
import ErrorPage from "pages/errorFile"
import { NextUIProvider } from '@nextui-org/react';

const path = window.location.pathname
const app = (
  // <ErrorBoundary FallbackComponent={() => (location.href = '/ErrorPage')}  onReset={() => window.history.back()}>
  <NextUIProvider>
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
  </NextUIProvider>

//  </ErrorBoundary>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
