import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ADMIN_ANALYTICS_REPORTS, GET_AS_ANALYTICS_REPORTS, GET_YM_ANALYTICS_REPORTS, GET_MIS_ANALYTICS_REPORTS, GET_PAGE_URL_REPORTS, GET_TEAM_PAGE_URL_REPORTS, GET_TEAM_HISTORY_REPORTS } from "./actionTypes"

import {
  getAdminAnalyticsReportsSuccess,
  getAdminAnalyticsReportsFail,

  getASAnalyticsReportsSuccess,
  getASAnalyticsReportsFail,

  getMISAnalyticsReportsSuccess,
  getMISAnalyticsReportsFail,

  getYMAnalyticsReportsSuccess,
  getYMAnalyticsReportsFail,

  getPageUrlReportsSuccess,
  getPageUrlReportsFail,

  getTeamPageUrlReportsSuccess,
  getTeamPageUrlReportsFail,

  getTeamHistoryReportsSuccess,
  getTeamHistoryReportsFail,

  loadReportsAdmin
} from "./actions"

//Include Both Helper File with needed methods
import { getAdminAnalyticsReports, getPageUrlReports, getTeamPageUrlReports, getTeamHistoryReports, getYMAnalyticsReports, getASAnalyticsReports, getMISAnalyticsReports } from "../../../helpers/Backend_helper"

function* fetchAnalyticsDisplayReports({ payload: AnalyticsReport }) {
  try {
    yield put(loadReportsAdmin(true))
    var getAnalyticsReportList
    if (AnalyticsReport) {
      getAnalyticsReportList = yield call(getAdminAnalyticsReports, AnalyticsReport)
      if (getAnalyticsReportList.responseData.dataAvailable === false) {
        if (Array.isArray(getAnalyticsReportList.responseData.response)) {
          yield put(getAdminAnalyticsReportsFail(null))
          yield put(getAdminAnalyticsReportsSuccess([]))
        } else {
          yield put(getAdminAnalyticsReportsFail(getAnalyticsReportList.responseData.response))
        }
      } else {
        getAnalyticsReportList.responseData.response.map((item, index) => {
          item.id = index + 1
        })
        yield put(getAdminAnalyticsReportsSuccess(getAnalyticsReportList.responseData.response))
      }
    }
    else {
      getAnalyticsReportList = []
      yield put(getAdminAnalyticsReportsSuccess(getAnalyticsReportList))
      yield put(getAdminAnalyticsReportsFail(null))
    }
  } catch (error) {
    yield put(getAdminAnalyticsReportsFail(error))
  }
}

function* fetchYMAnalyticsDisplayReports({ payload: AnalyticsReport }) {
  try {
    yield put(loadReportsAdmin(true))
    var getYMAnalyticsReportList
    if (AnalyticsReport) {
      getYMAnalyticsReportList = yield call(getYMAnalyticsReports, AnalyticsReport)
      if (getYMAnalyticsReportList.responseData.dataAvailable === false) {
        if (Array.isArray(getYMAnalyticsReportList.responseData.response)) {
          yield put(getYMAnalyticsReportsFail(null))
          yield put(getYMAnalyticsReportsSuccess([]))
        } else {
          yield put(getYMAnalyticsReportsFail(getYMAnalyticsReportList.responseData.response))
        }
      } else {
        getYMAnalyticsReportList.responseData.response.map((item, index) => {
          item.id = index + 1
        })
        yield put(getYMAnalyticsReportsSuccess(getYMAnalyticsReportList.responseData.response))
      }
    }
    else {
      getYMAnalyticsReportList = []
      yield put(getYMAnalyticsReportsSuccess(getYMAnalyticsReportList))
      yield put(getYMAnalyticsReportsFail(null))
    }
  } catch (error) {
    yield put(getYMAnalyticsReportsFail(error))
  }
}

function* fetchASAnalyticsDisplayReports({ payload: AnalyticsReport }) {
  try {
    yield put(loadReportsAdmin(true))
    var getASAnalyticsReportList
    if (AnalyticsReport) {
      getASAnalyticsReportList = yield call(getASAnalyticsReports, AnalyticsReport)
      if (getASAnalyticsReportList.responseData.dataAvailable === false) {
        if (Array.isArray(getASAnalyticsReportList.responseData.response)) {
          yield put(getASAnalyticsReportsFail(null))
          yield put(getASAnalyticsReportsSuccess([]))
        } else {
          yield put(getASAnalyticsReportsFail(getASAnalyticsReportList.responseData.response))
        }
      } else {
        getASAnalyticsReportList.responseData.response.map((item, index) => {
          item.id = index + 1
        })
        yield put(getASAnalyticsReportsSuccess(getASAnalyticsReportList.responseData.response))
      }
    }
    else {
      getASAnalyticsReportList = []
      yield put(getASAnalyticsReportsSuccess(getASAnalyticsReportList))
      yield put(getASAnalyticsReportsFail(null))
    }
  } catch (error) {
    yield put(getASAnalyticsReportsFail(error))
  }
}

function* fetchMISAnalyticsDisplayReports({ payload: AnalyticsReport }) {
  try {
    yield put(loadReportsAdmin(true))
    var getMISAnalyticsReportList
    if (AnalyticsReport) {
      getMISAnalyticsReportList = yield call(getMISAnalyticsReports, AnalyticsReport)
      if (getMISAnalyticsReportList.responseData.dataAvailable === false) {
        if (Array.isArray(getMISAnalyticsReportList.responseData.response)) {
          yield put(getMISAnalyticsReportsFail(null))
          yield put(getMISAnalyticsReportsSuccess([]))
        } else {
          yield put(getMISAnalyticsReportsFail(getMISAnalyticsReportList.responseData.response))
        }
      } else {
        getMISAnalyticsReportList.responseData.response.map((item, index) => {
          item.id = index + 1
        })
        yield put(getMISAnalyticsReportsSuccess(getMISAnalyticsReportList.responseData.response))
      }
    }
    else {
      getMISAnalyticsReportList = []
      yield put(getMISAnalyticsReportsSuccess(getMISAnalyticsReportList))
      yield put(getMISAnalyticsReportsFail(null))
    }
  } catch (error) {
    yield put(getMISAnalyticsReportsFail(error))
  }
}

function* fetchPageUrlReports({ payload: PageUrlReport }) {
  try {
    yield put(loadReportsAdmin(true))
    var getPageUrlReportList
    if (PageUrlReport) {
      getPageUrlReportList = yield call(getPageUrlReports, PageUrlReport)
      if (getPageUrlReportList.responseData.dataAvailable === false) {
        if (Array.isArray(getPageUrlReportList.responseData.response)) {
          yield put(getPageUrlReportsFail(null))
          yield put(getPageUrlReportsSuccess([]))
        } else {
          yield put(getPageUrlReportsFail(getPageUrlReportList.responseData.response))
        }
      } else {
        getPageUrlReportList.responseData.response.map((item, index) => {
          item.id = index + 1
        })
        console.log()
        yield put(getPageUrlReportsSuccess(getPageUrlReportList.responseData.response))
      }
    }
    else {
      getPageUrlReportList = []
      yield put(getPageUrlReportsSuccess(getPageUrlReportList))
      yield put(getPageUrlReportsFail(null))
    }
  } catch (error) {
    yield put(getPageUrlReportsFail(error))
  }
}

function* fetchTeamPageUrlReports({ payload: PageUrlReport }) {
  try {
    yield put(loadReportsAdmin(true))
    var getTeamPageUrlReportList
    if (PageUrlReport) {
      getTeamPageUrlReportList = yield call(getTeamPageUrlReports, PageUrlReport)
      console.log(getTeamPageUrlReportList)
      if (getTeamPageUrlReportList.responseData.dataAvailable === false) {
        if (Array.isArray(getTeamPageUrlReportList.responseData.response)) {
          yield put(getTeamPageUrlReportsFail(null))
          yield put(getTeamPageUrlReportsSuccess([]))
        } else {
          yield put(getTeamPageUrlReportsFail(getTeamPageUrlReportList.responseData.response))
        }
      } else {
        getTeamPageUrlReportList.responseData.response.map((item, index) => {
          item.id = index + 1
        })
        yield put(getTeamPageUrlReportsSuccess(getTeamPageUrlReportList.responseData.response))
      }

    }
    else {
      getTeamPageUrlReportList = []
      yield put(getTeamPageUrlReportsSuccess(getTeamPageUrlReportList))
      yield put(getTeamPageUrlReportsFail(null))
    }
  } catch (error) {
    yield put(getTeamPageUrlReportsFail(error))
  }
}

function* fetchTeamHistoryReports({ payload: PageUrlReport }) {
  try {
    yield put(loadReportsAdmin(true))
    var getTeamHistoryReportList
    if (PageUrlReport) {
      getTeamHistoryReportList = yield call(getTeamHistoryReports, PageUrlReport)
      console.log(getTeamHistoryReportList)
      getTeamHistoryReportList.responseData.response.map((item, index) => {
        item.id = index + 1
      })
      yield put(getTeamHistoryReportsSuccess(getTeamHistoryReportList.responseData.response))
    }
    else {
      getTeamHistoryReportList = []
      yield put(getTeamHistoryReportsSuccess(getTeamHistoryReportList))
      yield put(getTeamHistoryReportsFail(null))
    }
  } catch (error) {
    yield put(getTeamHistoryReportsFail(error))
  }
}

function* adminReportsSaga() {
  yield takeEvery(GET_ADMIN_ANALYTICS_REPORTS, fetchAnalyticsDisplayReports)
  yield takeEvery(GET_AS_ANALYTICS_REPORTS, fetchASAnalyticsDisplayReports)
  yield takeEvery(GET_YM_ANALYTICS_REPORTS, fetchYMAnalyticsDisplayReports)
  yield takeEvery(GET_MIS_ANALYTICS_REPORTS, fetchMISAnalyticsDisplayReports)
  yield takeEvery(GET_PAGE_URL_REPORTS, fetchPageUrlReports)
  yield takeEvery(GET_TEAM_PAGE_URL_REPORTS, fetchTeamPageUrlReports)
  yield takeEvery(GET_TEAM_HISTORY_REPORTS, fetchTeamHistoryReports)
}

export default adminReportsSaga;
