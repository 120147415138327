import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_LINEITEMS, ADD_LINEITEM, UPDATE_LINEITEM, READ_LINEITEM, STATUS_LINEITEM, ARCHIVE_LINEITEM } from "./actionTypes"

import {
  loadActivitiesLineItem,
  noDataLineItem,
  getLineItemSuccess,
  getLineItemFail,
  addLineItemSuccess,
  addLineItemFail,
  updateLineItemSuccess,
  updateLineItemFail,
  readLineItemSuccess,
  statusLineItemSuccess,
  archiveLineItemSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getLineItem, getLineItemByIOs, getLineItemType, getLineItemSTDB, getLineItemRCM, getLineItemVideo, createLineItem, updateLineItem, readLineItem, statusChangeLineItem, archiveLineItem } from "../../../helpers/Backend_helper"

function* fetchLineItems({ payload: adv }) {
  try {
    console.log(adv)
    yield put(loadActivitiesLineItem(true))
    var getLineItemList
    if (adv && adv.type) {
      if (adv && adv.iOID) {
        getLineItemList = yield call(getLineItemByIOs, adv)
        getLineItemList.responseData.response.map((item, index) => {
          item.id = index + 1
        })
        if (getLineItemList.responseData.response.length === 0) {
          yield put(noDataLineItem(true))
        }
        yield put(getLineItemSuccess(getLineItemList.responseData))
      } if (adv && adv.iOID === '') {
        delete adv.iOID
        getLineItemList = yield call(getLineItemType, adv)
        // if(adv.type === 'standardBanner'){
        //   getLineItemList = yield call(getLineItemSTDB,adv)
        // }else if(adv.type === 'richMedia'){
        //   getLineItemList = yield call(getLineItemRCM,adv)
        // }else{
        //   getLineItemList = yield call(getLineItemVideo,adv)
        // }
        getLineItemList.responseData.response.map((item, index) => {
          item.id = index + 1
        })
        if (getLineItemList.responseData.response.length === 0) {
          yield put(noDataLineItem(true))
        }
        yield put(getLineItemSuccess(getLineItemList.responseData))
      }
    } else {
      if (adv && adv.iOID) {
        getLineItemList = yield call(getLineItemByIOs, adv)
        getLineItemList.responseData.response.map((item, index) => {
          item.id = index + 1
        })
        if (getLineItemList.responseData.response.length === 0) {
          yield put(noDataLineItem(true))
        }
        yield put(getLineItemSuccess(getLineItemList.responseData))
      } else if (adv && adv.iOID === '') {
        delete adv.iOID
        getLineItemList = yield call(getLineItem, adv)
        getLineItemList.responseData.response.map((item, index) => {
          item.id = ((adv.pageNumber-1)*adv.pageSize) + index + 1
        })
        if (getLineItemList.responseData.response.length === 0) {
          yield put(noDataLineItem(true))
        }
        yield put(getLineItemSuccess(getLineItemList.responseData))
      } else {
        getLineItemList = []
        yield put(getLineItemSuccess(getLineItemList))
      }
    }
  } catch (error) {
    yield put(getLineItemFail(error))
  }
}

function* onReadLineItem({ payload: adv }) {
  try {
    const response = yield call(readLineItem, adv)
    yield put(readLineItemSuccess(response))
  } catch (error) {
    yield put(readLineItemSuccess(error))
  }
}

function* onAddLineItem({ payload: adv }) {
  try {
    const response = yield call(createLineItem, adv)
    if (response.responseData.success) {
      yield put(addLineItemSuccess(response.responseData))
    } else {
      yield put(addLineItemFail(response.responseData))
    }
  } catch (error) {
    yield put(addLineItemFail(error))
  }
}

function* onUpdateLineItem({ payload: adv }) {
  try {
    const response = yield call(updateLineItem, adv)
    if (response.responseData.success) {
      yield put(updateLineItemSuccess(response))
    } else {
      yield put(updateLineItemFail(response.responseData))
    }
  } catch (error) {
    yield put(updateLineItemFail(error))
  }
}

function* onStatusLineItem({ payload: adv }) {
  try {
    const response = yield call(statusChangeLineItem, adv)
    yield put(statusLineItemSuccess(response))
  } catch (error) {
    yield put(updateLineItemFail(error))
  }
}

function* onArchiveLineItem({ payload: adv }) {
  try {
    const response = yield call(archiveLineItem, adv)
    yield put(archiveLineItemSuccess(response))
  } catch (error) {
    yield put(archiveLineItemSuccess(error))
  }
}

function* lineItemSaga() {
  yield takeEvery(GET_LINEITEMS, fetchLineItems)
  yield takeEvery(ADD_LINEITEM, onAddLineItem)
  yield takeEvery(UPDATE_LINEITEM, onUpdateLineItem)
  yield takeEvery(READ_LINEITEM, onReadLineItem)
  yield takeEvery(STATUS_LINEITEM, onStatusLineItem)
  yield takeEvery(ARCHIVE_LINEITEM, onArchiveLineItem)
}

export default lineItemSaga;
