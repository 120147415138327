import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import axios from "axios";

import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Modal,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Tooltip,
    Spinner
} from "reactstrap"

import classnames from "classnames";

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone, PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';

import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';
import excelFile from 'assets/files/AdsTxtFile.xlsx'
import PDFFile from 'assets/files/AdsTxtEntries.pdf'

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import P_Ads_Txt_EntrtiesUpdate from "./P_Ads_Txt_EntrtiesUpdate";
import Ads_TxtViewEntries from "./Ads_TxtViewEntries";
import LoadingTable from "pages/LoaderPages/LoadingTable";

var selectedItem = []
var selectedData = []
var selectedIndex = []

const Publisher_Ads_Txt_Entries = () => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [adsTxtEntries, setAdsTxtEntries] = useState([])
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [uploadModal, setUploadModal] = useState(false);
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [isRight, setIsRight] = useState(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [editState, setEditState] = useState(false);
    const [editEntriesRow, setEditEntriesRow] = useState([])
    const [tagList, setTagList] = useState([])

    const [switchLoader, setSwitchLoader] = useState(true)
    const [loading, setLoading] = useState(false)
  
    const handleSwitch = () => {
      setSwitchLoader(!switchLoader)
    }

    
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

    const onToggleTooltip = (e) => {
        setIsTooltipOpen(!isTooltipOpen)
    }

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const copyCrawled = (data) => (e) => {
        navigator.clipboard.writeText(data)
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            partnerName: userInfo.name
        }
    }

    useEffect(() => {

        getAdsEntries()
    }, []);

    const getAdsEntries = (event) => {
        setLoading(true)
        body.url = "http://127.0.0.1:3006/v1.0/ads.txtEntries/read/forPartner"
        axios.post("/v1.0/connectPartner", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                setLoading(false)
                if (res.data.responseData.responseData.length > 0) {
                    res.data.responseData.responseData.map((item, i) => {
                        item.id = i + 1
                    })
                    setAdsTxtEntries([...res.data.responseData.responseData])
                    setLoading(false)
                    console.log(selectRow.nonSelectable)
                } else {
                    setLoading(false)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log("e", err)
            });
    }



    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        setFromDate(event.startDate._d.toISOString());
        setToDate(event.endDate._d.toISOString());
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [selected, setSelected] = useState([])
    const [deleteState, setDeleteState] = useState(false)

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.id)
                selectedItem.push(row.id)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.id)
                selectedIndex.splice(index, 1)
                let index2 = selectedItem.indexOf(row.id)
                selectedItem.splice(index2, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.id)
                    selectedItem.push(item.id)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                selectedItem = []
            }
        }
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
    }

    const keyField = "id";

    const adsTxtEntriesListColumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "publisherSite",
            text: "Entry Site",
            sort: true,
        },
        {
            dataField: "publisherName",
            text: "Publisher",
            sort: true,
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
            formatter: (cellContent, adsTxtEntries) => (
                <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
                    <Link className="text-success" to="#" onClick={toggleDrawer(true, adsTxtEntries.publisherAdsTxtEntries)}>
                        View
                    </Link>
                </div>
            ),
        }
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} entries
      </span>
    );
  
  
    const pageOptions = {
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const [newEntryList, setNewEntryList] = useState([{ sSPEntryAccount: '', sSPEntry: '', sSPEntryInventoryType: '' }])

    const addNewEntry = () => {
        let entryList = [...adsTxtEntries]
        entryList.push({ SSPEntryID: entryList.length + 1, SSPEntry: '', id: entryList.length + 1, })
        setAdsTxtEntries(entryList)
    }

    const handleEntryChange = (index) => (e) => {
        const { name, value } = e.target
        let entryList = [...newEntryList]
        let entryData = { ...entryList[index], [name]: value }
        entryList[index] = entryData
        setNewEntryList(entryList)
    }

    const removeEntry = (index) => (e) => {
        let entryList = [...newEntryList]
        entryList.splice(index, 1)
        setNewEntryList(entryList)
    }

    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };

    const toggleDrawer = (close, ads) => (event) => {
        console.log(event)
        selectedData = ads
        setIsRight(close);
    };

    const changeValue = (e) => {
        let values = [...adsTxtEntries]
    }

    const editEntries = (e) => {
        setEditState(true)
    }

    const updateEntries = (e) => {
        console.log(adsTxtEntries)
        let entries = [...adsTxtEntries]
        entries.map((item, i) => {
            delete item.id
        })
        if (tagList.length > 0) {
            entries = [...entries, ...tagList]
        }

        let bodyEntries = {
            url: "http://127.0.0.1:3006/v1.0/ads.txtEntries/update/forPartner",
            "requestBody": {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                partnerName: userInfo.name,
                adstxt: entries
            }
        }
        console.log(bodyEntries)
        debugger
        axios.post("/v1.0/connectPartner", bodyEntries,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.responseData.success) {
                    toastr.success('ADS.txt Entries are Updated Successfully!...')
                    setEditState(false)
                    setLoading(true)
                    getAdsEntries()
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    const tagChange = (index) => (e) => {
        let value = e.target.value
        let tags = [...tagList]
        tags[index].SSPEntry = e.target.value
        console.log(tags)
        setTagList(tags)
    }

    const removeTag = (index) => (e) => {
        console.log("adfaf")
        let tags = [...tagList]
        tags.splice(index, 1)
        setTagList(tags)
    }

    const addNewTag = () => {
        console.log("click")
        let entryList = [...adsTxtEntries]
        let tags = [...tagList]
        tags.push({ SSPEntry: '', SSPEntryID: entryList.length + 1, ownedByStreamlyn: false })
        console.log(tags)
        setTagList(tags)
    }

    const deleteAdsTxt = (event) => {
        console.log(adsTxtEntries)
        let entries = [...adsTxtEntries]
        console.log(selectedItem)
        let arr = entries.filter(item => !selectedItem.includes(item.id))
        console.log(arr)
        arr.map((item, i) => {
            delete item.id
        })
        if (tagList.length > 0) {
            arr = [...entries, ...tagList]
        }

        let bodyEntries = {
            url: "http://127.0.0.1:3006/v1.0/ads.txtEntries/update/forPartner",
            "requestBody": {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                partnerName: userInfo.name,
                adstxt: arr
            }
        }
        console.log(bodyEntries)
        axios.post("/v1.0/connectPartner", bodyEntries,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.responseData.success) {
                    toastr.success('ADS.txt Entries are Deleted Successfully!...')
                    setEditState(false)
                    setLoading(true)
                    getAdsEntries()
                    selectedItem = []
                    setSelected([])
                    setSelectedRowsStatus(false)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    if (adsTxtEntries.length > 0) {
        adsTxtEntries.map((item, index) => {
            if (item.ownedByStreamlyn) {
                selectRow.nonSelectable.push(index + 1)
            }
        })
    }

    useEffect(() => {
        console.log(loading)
        if (loading) {
            setSwitchLoader(true)
        }
    }, [loading]);

    return (
        <React.Fragment >
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
                <div className="page-content">
                    <MetaTags>
                        <title>ADS.txt| Bidsxchange</title>
                    </MetaTags>
                    <Container fluid={true} style={{ pointerEvents: (loading) ? 'none' : 'unset' }}>
                        <Breadcrumbs title="Tools" breadcrumbItem="ADS.txt Entries" />
                        {switchLoader &&
                            <LoadingTable loading={loading} targetLoading={handleSwitch} />
                        }
                        {!switchLoader &&
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                                        <ToolkitProvider
                                                            keyField={keyField}
                                                            data={adsTxtEntries}
                                                            columns={adsTxtEntriesListColumns}
                                                            // bootstrap4
                                                            search
                                                        >
                                                            {toolkitProps => (
                                                                <React.Fragment>
                                                                    <Row className="mb-2">
                                                                        <Col sm="3">
                                                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                                                <div className="position-relative">
                                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                                    <i className="bx bx-search-alt search-icon" />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl="12" className="table-responsive">
                                                                            <div >
                                                                                <BootstrapTable
                                                                                    keyField={keyField}
                                                                                    responsive
                                                                                    bordered={false}
                                                                                    striped={false}
                                                                                    defaultSorted={defaultSorted}
                                                                                    classes={
                                                                                        "table align-middle table-nowrap"
                                                                                    }
                                                                                    headerWrapperClasses={"thead-light"}
                                                                                    {...toolkitProps.baseProps}
                                                                                    pagination={paginationFactory(pageOptions)}
                                                                                    noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                                // ref={node}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            )}
                                                        </ToolkitProvider>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                    </Container>
                </div>
            {/* </div> */}
            <Offcanvas isOpen={isRight} direction='end'  >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {' Ads.Txt Entries'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Ads_TxtViewEntries closeCanvas={toggleDrawer(false, '')} selectedAdsTxtEntries={selectedData} />
                </OffcanvasBody>
            </Offcanvas>
        </React.Fragment >
    )
}

export default Publisher_Ads_Txt_Entries