/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import {
    Card,
    CardBody,
    CardTitle,
    Container,
    Row, Col, Input,
    Button,
    Spinner, Label, Form,FormFeedback
} from "reactstrap"

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

var list = []
var brandList = []
var itemList = []

const ROW = ["AF","AG","AI","AM","AN","AO","AQ","AR","AS","AW","AZ","BB","BF","BI","BJ","BL","BM","BN","BO","BR","BS","BT","BV","BW","BZ","CC","CD","CF","CG","CI","CK","CL","CM","CO","CR","CU","CV","CX","DJ","DM","DO","DZ","EC","EG","EH","ER","ET","FJ","FK","FM","GA","GD","GE","GF","GH","GL","GM","GN","GP","GQ","GS","GT","GU","GW","GY","HM","HN","HT","IL","IO","IQ","IR","JM","JO","KG","KI","KM","KN","KP","KR","KY","KZ","LA","LC","LK","LR","LS","LY","MA","MF","MG","MH","ML","MM","MN","MO","MP","MQ","MR","MS","MU","MV","MW","MX","MZ","NA","NC","NE","NF","NI","NP","NR","NT","NU","PA","PE","PF","PG","PM","PN","PR","PS","PW","PY","RE","RW","SB","SC","SD","SH","SN","SO","SR","ST","SV","SY","SZ","TC","TD","TF","TG","TJ","TK","TL","TM","TN","TO","TR","TT","TV","TZ","UG","UM","UY","UZ","VC","VE","VG","VI","VU","WF","WS","YD","YE","YT","ZM","ZW"]
const APAC = ["BD","CN","HK","ID","IN","JP","KH","MY","PH","PK","SG","SL","TH","TW","VN"]
const Tier1 = ["AU","CA","NZ","US"]
const GCC = ["AE","BH","KE","KW","LB","NG","OM","QA","SA","ZA"]

function NewGeoSettings(props) {
    /* eslint-disable react/prop-types */
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [loader, setLoader] = useState(false);
    const [publisherReport, setPublisherReport] = useState({
        GeoGroupName: '',
        networkBlockList: [],
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
    });
    const [itemBlock, setItemBlock] = useState('')

    const [isEdit, setIsEdit] = useState(false);
    const [tags, setTags] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [itemListData, setItemListData] = useState([]);

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const dataFeedChange = (e) => {
        const { name, value } = e.target;
        setPublisherReport(publisherReport => ({ ...publisherReport, [name]: value }));
    }

    useEffect(() => {
        if (props.selectedData !== '') {
            list = []
            brandList = []
            let dfs = []
            console.log(props.selectedData)
            setIsEdit(true)
                if (props.selectedData.GeoGroupCountries.length > 0) {
                    brandList = props.selectedData.GeoGroupCountries.map((item, index) =>
                        Object.assign({}, { text: item, id: item })
                    )
                    console.log(brandList)
                    props.selectedData.GeoGroupCountries.map((item, index) => {
                        list.push(item)
                        dfs.push({ name: item, id: index + 1 })
                        itemList = dfs
                        setItemListData(dfs)
                        return itemList
                    })
                    setTags(brandList)
                }
                setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: props.selectedData.GeoGroupCountries, GeoGroupName: props.selectedData.GeoGroupName }));
            
        } else {
            list = []
            brandList = []
        }
    }, []);

    const handleDelete = (data) => (e) => {
        console.log(list, itemList)
        list.splice(data.id - 1, 1)
        let arr = [...itemList]
        let index = arr.findIndex(x => x.name === data.name)
        console.log(index)
        arr.splice(index, 1)
        let FinalList = []
        arr.map((item, index) => {
            item.id = index + 1
            FinalList.push(item.name)
        })
        itemList = arr
        setItemListData(arr)
        console.log(list, arr)
        setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: [...FinalList] }));
    };

    const [msg1, setMsg1] = useState('');
    const [msg2, setMsg2] = useState('');

    const handleAddition = tag => {
        let arr = itemBlock.split(',')
        let concatenatedArray = arr.concat(props.allGeoData)
        console.log(concatenatedArray)
        // let concatenatedArray = arr
        let findDuplicates = concatenatedArray.filter((item, index) => concatenatedArray.indexOf(item) !== index)
        console.log(findDuplicates)
        if (findDuplicates.length > 0) {
            setMsg1("Please Remove Duplicate Country Codes")
            return
        } else {
            setMsg1("")
        }
        let findSingleElement = arr.filter((item, index) => item.length === 1)
        console.log(findSingleElement)
        if (findSingleElement.length > 0) {
            setMsg2("Please follow ISO-2 Format Standard Codes")
            return
        } else {
            setMsg2("")
        }
        arr =objectModalForCountries(arr)
        list = arr
        let arr4 = [...itemListData, ...arr]
        itemList = arr4
        let FinalList = []
        arr4.map((item, index) => {
            FinalList.push(item.name)
        })
        setItemListData(arr4)
        console.log(itemList,list)
        setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: [...FinalList] }));
        setItemBlock('')
    };

    const objectModalForCountries = (response) => {
        var dataList = []
        response.map((item, index) => {
          let obj = {}
          obj.id = itemListData.length + index + 1
          obj.name = item
          dataList.push(obj)
        })
        return dataList
      }

    const allowOnlyCommaless = (e) => {
        setMsg1("")
        setMsg2("")
        console.log(e.target.value, itemBlock)
        if (((e.target.value.charAt(e.target.value.length - 1) === ',') && (itemBlock.charAt(itemBlock.length - 1) === ','))) {
            setItemBlock(itemBlock)
            e.preventDefault();
        } else {
            setItemBlock(e.target.value)
        }
    }

    function allowOnlyChars(e) {
        var code = ('charCode' in e) ? e.charCode : e.keyCode;
        console.log(e.target.value)
        if (!(code >= 65 && code < 91) && !(code >= 44 && code < 45)) {
            e.preventDefault();
        }
        let arr = e.target.value.split(',')
        if (arr.length > 0) {
            if ((arr[arr.length - 1]).length === 2 && !(code >= 44 && code < 45)) {
                e.preventDefault();
            }
        }
    };

    const keyField = "id";

    const roleListColumns = [
        {
            text: "Sl.No",
            dataField: "id",
            sort: true,
        },
        {
            text: "Name",
            dataField: "name",
            sort: true,
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, deviceControl) => (
                <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
                    <Link className="text-danger" to="#" onClick={handleDelete(deviceControl)}>
                        <i
                            className="mdi mdi-delete font-size-18"
                            id="edittooltip"
                        ></i>
                    </Link>
                </div>
            ),
        },
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size}
        </span>
    );

    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "asc", // desc or asc
        },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        console.log(publisherReport)
        let requestBody = {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name,
        }
        if (publisherReport.GeoGroupName && (publisherReport.networkBlockList.length > 0)) {
                if (publisherReport.networkBlockList.length > 0) {
                    requestBody = { ...requestBody,  GeoGroupCountries: publisherReport.networkBlockList }
                }
        }
        console.log(requestBody)
        setLoader(true)
        const bodyReport = {
            "url": "http://127.0.0.1:2002/v1.0/HB_Buyers_geo/update/" + publisherReport.GeoGroupName,
            "requestBody": requestBody
        }
        axios.post("/v1.0/connectOP", bodyReport, {
            headers: headers
        })
            .then((res) => {
                console.log("publisherReport Upload ==>", res)
                setLoader(false)
                if (res.data.responseData) {
                    if (res.data.responseData.success) {
                        if (props.selectedData !== '') {
                            props.closeCanvas('Update')
                        } else {
                            props.closeCanvas('Create')
                        }
                    }
                }
            })
            .catch((err) => {
                setLoader(false)
                console.log("e", err)
            });
    }

    return (
        <React.Fragment>
            <div >
                {loader &&
                    <Spinner className="ms-2 loader" color="primary" />
                }
                <Form onSubmit={handleSubmit}>
                    <div className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                        <div className="col-md-12">
                            <div className="mb-3 mt-3">
                                <Label>Geo Group Name</Label>
                                <Input type="text" className="form-control" name="GeoGroupName" value={publisherReport.GeoGroupName} onChange={dataFeedChange}>
                                </Input>
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Add Countries</Label>
                                {/* <Input type="text" className="form-control" name="itemBlock" value={itemBlock} onChange={(e) => { setItemBlock(e.target.value) }} onKeyPress={handleAddition} placeholder="Press enter to add new Item">
                                </Input> */}
                                <div className='row'>
                                    <div className='col-9'>
                                        <Input
                                            name="itemBlock"
                                            type="text"
                                            className={'form-control' + ((msg1 || msg2)  ? ' is-invalid' : '')} value={itemBlock}
                                            onChange={allowOnlyCommaless} onKeyPress={allowOnlyChars} placeholder="Please enter Comma Seperated or Single ISO-2 Format Country Codes"
                                        />
                                        {msg1 ? (
                                            <FormFeedback type="invalid">{msg1}</FormFeedback>
                                        ) : null}
                                          {msg2 ? (
                                            <FormFeedback type="invalid">{msg2}</FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className='col-3'>
                                        <div className="text-end">
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary"
                                                onClick={handleAddition}
                                            >
                                                Add Countries
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="mb-3">
                                <Label>Country List</Label>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <ToolkitProvider
                                                keyField={keyField}
                                                data={itemList}
                                                columns={roleListColumns}
                                                // bootstrap4
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col sm="4">
                                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12" className="table-responsive">
                                                                <div className="">
                                                                    <BootstrapTable
                                                                        keyField={keyField}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        pagination={paginationFactory(pageOptions)}
                                                                        noDataIndication={<h5 className="text-center">No Data</h5>} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>

                        </div>
                    </div>

                    <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
                        <Col>
                            <div className="text-start">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    // eslint-disable-next-line react/prop-types
                                    onClick={props.closeCanvas}
                                >
                                    Back
                                </button>
                                &nbsp;&nbsp;
                                {!isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Save
                                    </button>
                                }
                                &nbsp;&nbsp;
                                {isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Update
                                    </button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Form>

            </div>

        </React.Fragment >
    )
}

NewGeoSettings.propTypes = {

}

export default NewGeoSettings

