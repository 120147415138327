import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import Breadcrumb from 'components/Common/Breadcrumb';
import { Button, Card, CardBody, Container, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Col, Label, Input, Modal, ModalBody, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import Add_Playlist from './Add_Playlist';



//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Playlist_View from './Playlist_View';


// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';


import { getPlaylist as onGetPlaylist } from 'store/Media/Playlist/actions'
import {
  archivePlaylist as onArchivePlaylist,
  statusPlaylist as onStatusPlaylist,
  archivePlaylistSuccess,
  archivePlaylistFail,
  statusPlaylistSuccess
}
  from 'store/Media/Playlist/actions';

import {
  getPubPlaylist as onGetPubPlaylist,
  archivePubPlaylist as onArchivePubPlaylist,
  statusPubPlaylist as onStatusPubPlaylist,
  archivePubPlaylistSuccess,
  archivePubPlaylistFail,
  statusPubPlaylistSuccess
} from 'store/Media_Publisher/Playlist/actions'

//redux
import { useSelector, useDispatch } from "react-redux";
import Cdn_Playlists from './Cdn_Playlists';

var playlistSelected = []
var playlistSelectedId = []
var playlistDataLocal = []
var filterCount = 0

const Video_Playlists_Pub = props => {
  const [addPlaylistCanvas, setaddPlaylistCanvas] = useState(false)
  const [perticularPlaylistCanvas, setPerticularPlaylistCanvas] = useState(false);
  const [cdnLinks, setCdnLinks] = useState(false)
  const [playlistsData, setPlaylistsData] = useState([])
  const [activePlaylist, setActivePlaylist] = useState([])
  const [statusSearch, setStatusSearch] = useState('Active')
  const [checkedAll, setCheckedAll] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [hoverStateID, setHoverStateID] = useState('');
  const [btnprimary1, setBtnprimary1] = useState(false)
  const [actionType, setActionType] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [nChildStatus, setNchildStatus] = useState(false)
  console.log(searchInput)



  const [activeState, setActiveState] = useState('grid')
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)

  const [playlistSelected, setPlaylistSelected] = useState([])
  const [playlistSelectedId, setPlaylistSelectedId] = useState([])



  const dispatch = useDispatch();

  const { SearchBar } = Search;

  console.log(playlistSelected)
  console.log(playlistSelectedId)

  const checkStatusChild = (props) => {
    console.log(props)
    setNchildStatus(props)
  }

  const escFunction = useCallback((event) => {
    console.log("called esc function in parent", event.key)
    if (event.key === "Escape") {
      console.log()
      setaddPlaylistCanvas(false);
      if (!perticularPlaylistCanvas) {
        setPerticularPlaylistCanvas(false)
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);



  useEffect(() => {
    setSelectedRowsStatus(playlistSelected.length > 0);
  }, [playlistSelected]);

  const { playlists } = useSelector(state => ({
    playlists: state.pubPlaylistsReducer.playlist
  }));

  useEffect(() => {
    if (playlists !== null) {
      dispatch(onGetPubPlaylist({ filter: statusSearch }));
    }
  }, [addPlaylistCanvas, perticularPlaylistCanvas, cdnLinks]);


  useEffect(() => {
    if (playlists !== null && playlists.length > 0) {
      setPlaylistsData(playlists)
    }

  }, [playlists.length, perticularPlaylistCanvas, cdnLinks]);

  const { playlistStatusResponse } = useSelector(state => ({
    playlistStatusResponse: state.pubPlaylistsReducer.statusMsg,
  }));

  const { archivePlaylistResponse } = useSelector(state => ({
    archivePlaylistResponse: state.pubPlaylistsReducer.archiveMsg,
  }));


  useEffect(() => {
    if (playlistStatusResponse !== null) {
      if (playlistStatusResponse.responseData.success) {
        toastr.success('Playlist Status Changed Successfully')
        dispatch(onGetPubPlaylist({ filter: statusSearch }));

        dispatch(statusPubPlaylistSuccess(null));
        handleClose()
      } else if (!playlistStatusResponse.responseData.success) {
        toastr.error('This playlist assigned to Player settings')
        dispatch(statusPubPlaylistSuccess(null));
        handleClose()
      }
    }
  }, [playlistStatusResponse]);

  useEffect(() => {
    if (archivePlaylistResponse !== null) {
      if (archivePlaylistResponse.success) {
        toastr.success('Advertiser Archived Successfully')
        dispatch(onGetPubPlaylist({ filter: statusSearch }));

        dispatch(archivePubPlaylistSuccess(null));
        handleClose()
      } else if (!archivePlaylistResponse.success) {
        toastr.error('This playlist assigned to Player settings')
        dispatch(archivePubPlaylistFail(null));
        handleClose()
      }
    }
  }, [archivePlaylistResponse]);

  const archivePlaylist = async () => {
    setShowAlert(false)
    dispatch(onArchivePubPlaylist({ idList: playlistSelectedId }));
  }

  const statusPlaylist = (state) => (event) => {
    dispatch(onStatusPubPlaylist({ idList: playlistSelectedId, playListStatus: state }));
  }

  const toggleaddPlaylistCanvas = value => {
    dispatch(onGetPubPlaylist({ filter: statusSearch }));
    setActionType(value)
    setaddPlaylistCanvas(!addPlaylistCanvas)
  }


  const togglePerticularPlaylistCanvas = () => {
    setPerticularPlaylistCanvas(!perticularPlaylistCanvas)
  }

  const toggleCdnLinks = () => {
    setCdnLinks(!cdnLinks)
  }


  const handleClickPlaylist = (each) => {
    console.log(each)
    if (playlistSelected.length === 0) {
      setActivePlaylist(each)
      setPerticularPlaylistCanvas(!perticularPlaylistCanvas)
    }
  }

  const handleEditState = (id) => (e) => {
    setHoverStateID(id)
  }

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: playlistSelected,
    onSelect: (row, isSelect, rowIndex, e) => {
      let playlists = [...playlistsData];
      console.log(playlists)
      playlists[row.id - 1].checked = isSelect;
      setPlaylistsData(playlists);

      if (isSelect) {
        setPlaylistSelected([...playlistSelected, row.id]);
        setPlaylistSelectedId([...playlistSelectedId, row.playListOtherID]);
      } else {
        setPlaylistSelected(playlistSelected.filter((id) => id !== row.id));
        setPlaylistSelectedId(playlistSelectedId.filter((id) => id !== row.playListOtherID));
      }

      setSelectedRowsStatus(playlistSelected.length > 0);
    },
    onSelectAll: (isSelect, rows, e) => {
      let playlists = [...playlistsData];
      let selectedIds = [];

      if (isSelect) {
        rows.forEach((row) => {
          selectedIds.push(row.playListOtherID);
        });
      }

      setPlaylistSelectedId(isSelect ? playlists.map((item) => item.playListOtherID) : []);
      setPlaylistSelected(isSelect ? playlists.map((item) => item.id) : []);
      setSelectedRowsStatus(isSelect);

      setPlaylistsData(
        playlists.map((playlist) => ({
          ...playlist,
          checked: isSelect,
        }))
      );
    },
  };


  const selectVideo = (item, index) => (e) => {
    const isChecked = e.target.checked;
    const updatedPlaylists = playlistsData.map((playlist, i) => {
      console.log("-------------", index, i)
      if (i === index) {
        console.log("-----------------", index)
        return { ...playlist, checked: isChecked };
      }
      return playlist;
    });
    setPlaylistsData(updatedPlaylists);

    setPlaylistSelected(prevSelected => {
      if (isChecked) {
        return [...prevSelected, item.id];
      } else {
        return prevSelected.filter(id => id !== item.id);
      }
    });

    setPlaylistSelectedId(prevSelectedId => {
      if (isChecked) {
        return [...prevSelectedId, item.playListOtherID];
      } else {
        return prevSelectedId.filter(id => id !== item.playListOtherID);
      }
    });

    setSelectedRowsStatus(playlistSelected.length > 0);
  };

  const selectAllVideo = (e) => {
    const isChecked = e.target.checked;
    const updatedPlaylists = playlistsData.map((item) => {
      return { ...item, checked: isChecked };
    });
    setCheckedAll(isChecked);

    let selectedIds = [];

    if (isChecked) {
      selectedIds = updatedPlaylists
        .filter((item) => !playlistSelectedId.includes(item.playListOtherID))
        .map((item) => item.playListOtherID);
    }

    setPlaylistSelectedId(isChecked ? updatedPlaylists.map((item) => item.playListOtherID) : []);
    setPlaylistSelected(isChecked ? updatedPlaylists.map((item) => item.id) : []);
    setSelectedRowsStatus(isChecked);

    setPlaylistsData(updatedPlaylists);
  };


  console.log(playlistsData)

  // clearing the values
  // useEffect(() => {
  //   return () => {
  //     playlistSelected = []
  //     playlistSelectedId = []
  //   };
  // }, []);

  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const applyFilter = (e) => {
    dispatch(onGetPubPlaylist({ filter: statusSearch }));
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setStatusSearch('Active')
    filterCount = 0
    setFilterState(false)
    dispatch(onGetPubPlaylist({ filter: 'Active' }));
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 4000,
    extendedTimeOut: 1000
  };

  const toggleDrawer = () => {
    setaddPlaylistCanvas(false)
    toastr.success('Video Playlist Created Successfully!...')
  }

  const toggleDrawerPlaylist = () => {
    setPerticularPlaylistCanvas(false)
  }

  const handleClose = () => {

    console.log("called")
    // playlistSelected = []
    // playlistSelectedId = []
    setPlaylistSelected([])
    setPlaylistSelectedId([])
    let playists = [...playlistsData]
    playists.map((item, index) => {
      item.checked = false
    })
    setCheckedAll(false)
    setPlaylistsData(playlists)
    setSelectedRowsStatus(false)
  }

  const listView = () => {
    setActiveState('list')
  }

  const gridView = () => {
    setActiveState('grid')
  }




  const playListCoulumns = [
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "playlist Id",
      dataField: "playListOtherID",
      sort: true
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Thumbnail",
      align: 'left',
      formatter: (cellContent, eachRow) => (
        // console.log(eachRow.playListOtherVideoData.videoContentOtherThumbnailURL)
        <img src={eachRow.isCDNLink ? eachRow.playListOtherVideoData[0].thumbnailURL : eachRow.playListOtherVideoData[0].videoContentOtherThumbnailURL} alt="thumbnail" style={{ height: "3.5rem", width: "6 rem" }} />
        // <video type="video/mp4" style={{ height: "3.5rem", width: "6 rem" }}
        //   src={eachRow.videoContentURL}   >
        // </video>
      )
    },
    {
      text: 'Name',
      dataField: 'playListOtherName',
    },
    // {
    //   text: 'Category',
    //   dataField: 'playListCategory',
    //   formatter: (cellContent, eachRow) => (
    //     <p>{eachRow.playListCategory}</p>
    //   )
    // },
    // {
    //   text: 'Language',
    //   dataField: 'playListLanguage',
    //   formatter: (cellContent, eachRow) => (
    //     <p>{eachRow.playListLanguage}</p>
    //   )
    // },
    {
      text: 'Created At',
      dataField: 'playListOtherCreateTime',
    },
    {
      text: 'No of Videos',
      dataField: 'playListOtherVideoData',
      align: 'left',
      formatter: (cellContent, eachRow) => (
        <p>{eachRow.playListOtherVideoData.length}</p>
      )
    }
  ]


  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    totalSize: playlistsData.length,
    custom: true,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const keyField = "id";




  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const rowEvents = {
    onClick: (e, row) => {
      if (playlistSelected.length === 0) {
        setActivePlaylist(row)
        setPerticularPlaylistCanvas(!perticularPlaylistCanvas)
      }
    }
  }


  return (
    <>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <MetaTags>
          <title>Video Playlists | Bidsxchange</title>
        </MetaTags>
        <Container fluid={true}>
          <div className='d-flex justify-content-between align-items-center'>
            <Breadcrumb title="Video Playlists" breadcrumbItem="Video Playlists" />
            <div className="text-sm-end">
              <div className="btn-group">
                <Dropdown
                  direction="left"
                  isOpen={isMenu}
                  toggle={toggleMenu}
                >
                  <DropdownToggle
                    tag="button"
                    className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                  >
                    <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                    {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                      Filter Applied
                    </span> : <span className="d-none font-16 d-sm-inline-block">
                      Filter
                    </span>
                    }
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                    <div className="dropdown-item-text">
                      <div className="row">
                        <h5 className="mb-0">Filters</h5>
                      </div>
                    </div>

                    <DropdownItem divider />
                    <Card>
                      <CardBody aria-hidden="true">
                        <Row >
                          <Col sm="12" className="mt-2">
                            <Label className="form-label">Status</Label>
                            <div className=" text-sm-end">
                              <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                <option value=''> All</option>
                                <option value='Active' > Active</option>
                                <option value='Inactive'> InActive</option>
                                {/* <option value='Archived'> Archived</option> */}
                              </Input>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <DropdownItem divider />
                    <Row>
                      <Col sm="6">
                        <DropdownItem
                          className="text-primary text-center"
                          onClick={clearAllFilter}
                        >
                          Clear ALL
                        </DropdownItem>
                      </Col>
                      <Col sm="6">
                        <DropdownItem
                          className="text-primary text-center"
                          onClick={applyFilter}
                        >
                          Apply Filter
                        </DropdownItem>
                      </Col>
                    </Row>

                  </DropdownMenu>
                </Dropdown>
              </div> &nbsp;&nbsp;
              {/* <Button
                color="primary"
                className="font-16 btn-block btn btn-primary"
                onClick={toggleaddPlaylistCanvas}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create Playlist
              </Button> */}
              <div className="btn-group">
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    <i className="mdi mdi-plus-circle-outline me-1" />Create Playlist&nbsp; <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => toggleaddPlaylistCanvas('manual')}>Uploaded Contents</DropdownItem>
                    <DropdownItem onClick={() => toggleCdnLinks()}>CDN links</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>

          </div>
          <Card className='mt-2 p-3'>
            <Row className="mb-2">
              <Col sm="12">
                <div className="text-end d-flex justify-content-end" >
                  <div style={{ backgroundColor: `${activeState === 'list' ? "#dedede" : "#ffffff"}` }}>
                    <i className='bx bx-sm bx-list-ul m-1' onClick={listView} style={{ cursor: "pointer" }}></i>
                  </div>
                  <div style={{ backgroundColor: `${activeState === 'grid' ? "#dedede" : "#ffffff"}` }}>
                    <i className='bx bx-sm bx-grid-alt m-1' onClick={gridView} style={{ cursor: "pointer" }}></i>
                  </div>
                </div>
              </Col>
            </Row>
            {activeState === 'grid' && 
              <Row className="mb-2">
                <Col sm="4">
                  <div className="search-box ms-2 mb-2 d-inline-block mt-2">
                    <div className="position-relative">
                      <label htmlFor="search-bar-0" className="search-label">
                        <span id="search-bar-0-label" className="sr-only">Search this table</span>
                        <input id="search-bar-0" type="text" aria-labelledby="search-bar-0-label" className="form-control " placeholder="Search" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                      </label><i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </Col>
              </Row>
            }
            <Row className="mb-2">
              <Col xl="12">
                {selectedRowsStatus && activeState === 'grid' &&
                  <div className="row m-0 React-Table-Selection">
                    <div style={{ padding: '0.5% 2%' }}>
                      <input
                        className="form-check-input"
                        type="checkbox" checked={checkedAll}
                        onChange={selectAllVideo}
                        style={{ marginTop: '0.4rem', padding: '10px' }}
                      />         &nbsp;  &nbsp;
                      <span color="inherit" style={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{playlistSelected.length} Selected </span>           &nbsp;  &nbsp;
                      <button color="primary" className="btn btn-primary " onClick={statusPlaylist(true)}>
                        Activate
                      </button>

                      &nbsp;  &nbsp;
                      <button color="primary" className="btn btn-primary " onClick={statusPlaylist(false)}>
                        DeActivate
                      </button>
                      &nbsp;  &nbsp;
                      {/* <Button color="primary" className="btn btn-primary " onClick={() => { setShowAlert(!showAlert) }} >
                        Archive
                      </Button> */}
                      <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                        className="mdi mdi-close-thick font-size-18"
                      ></i></Button>
                    </div>
                  </div>
                }
              </Col>
            </Row>
            {activeState === 'grid' &&
              <Row>
                {playlistsData !== null && playlistsData.length !== 0 ?
                  playlistsData
                    .filter(item => item.playListOtherName.includes(searchInput))
                    .map((item, index) => {
                      if (item.playListOtherVideoData.length !== 0) {
                        return (
                          <Col sm={4} xxl={3} key={index} >
                            <Card onMouseOver={handleEditState(item.id)} onMouseLeave={handleEditState('')} style={{ backgroundColor: (item.checked || hoverStateID === item.id) ? '#556ee61f' : '', cursor: 'pointer', boxShadow: (hoverStateID && hoverStateID === item.id) ? 'rgb(149 157 165 / 20%) 0px 8px 24px' : 'none' }}>
                              {(item.checked || hoverStateID === item.id) &&
                                <div>
                                  <div className="form-check mt-2 mb-2" style={{ position: 'absolute', left: '1pc', top: '0.5pc', zIndex: '200' }}>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={item.id} style={{ width: '1.5pc', height: '1.5pc' }}
                                      checked={item.checked}
                                      onChange={selectVideo(item, index)}
                                    />
                                  </div>
                                </div>
                              }

                              <CardBody className="row justify-content-center " style={{ cursor: 'pointer' }} onClick={() => handleClickPlaylist(item)}>
                                <i className="bx bxs-videos bx-md text-center" style={{ position: 'absolute', fontSize: '2rem', color: 'white', zIndex: '200', top: '5vw' }} />
                                <img className='img-container' style={{ padding: '12px', borderRadius: '1.2rem', width: '100%', maxHeight: '20rem' }}
                                  src={item.isCDNLink ? item.playListOtherVideoData[0].thumbnailURL : item.playListOtherVideoData[0].videoContentOtherThumbnailURL} alt='image url have issue'/>
                                {/* isCDNLink ? eachRow.playListOtherVideoData[0].thumbnailURL : eachRow.playListOtherVideoData[0].videoContentOtherThumbnailURL */}
                                <h5 className="text-center m-3">{item.playListOtherName}</h5>
                              </CardBody>
                            </Card>
                          </Col>
                        )
                      }
                    })
                  :
                  <div style={{ padding: '1% 3%', height: '50vh', overflow: 'auto' }} className='d-flex justify-content-center'>
                    <h5>No Playlist, Create One!</h5>
                  </div>
                }
              </Row>
            }
            {activeState === 'list' &&
              <Row>
                <Col lg="12">

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField={keyField}
                    columns={playListCoulumns}
                    data={playlistsData}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField={keyField}
                          data={playlistsData}
                          columns={playListCoulumns}
                          // bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              {activeState === 'list' &&
                                <Row className="mb-2">
                                  <Col sm="8">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              }
                              <Row>
                                <Col xl="12">
                                  {selectedRowsStatus && activeState === 'list' &&
                                    <div className="row m-0 React-Table-Selection">
                                      <div style={{ padding: '0.5% 2%' }}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox" checked={checkedAll}
                                          onChange={selectAllVideo}
                                          style={{ marginTop: '0.4rem', padding: '10px' }}
                                        />         &nbsp;  &nbsp;
                                        <span color="inherit" style={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{playlistSelected.length} Selected </span>           &nbsp;  &nbsp;
                                        <button color="primary" className="btn btn-primary " onClick={statusPlaylist(true)}>
                                          Activate
                                        </button>

                                        &nbsp;  &nbsp;
                                        <button color="primary" className="btn btn-primary " onClick={statusPlaylist(false)}>
                                          DeActivate
                                        </button>
                                        &nbsp;  &nbsp;
                                        {/* <Button color="primary" className="btn btn-primary " onClick={() => { setShowAlert(!showAlert) }} >
                                          Archive
                                        </Button> */}
                                        <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                          className="mdi mdi-close-thick font-size-18"
                                        ></i></Button>
                                      </div>
                                    </div>
                                  }
                                </Col>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={keyField}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      rowEvents={rowEvents}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      noDataIndication={<h5 className="text-center">No Data</h5>}
                                    // ref={node}
                                    />
                                    {console.log({ ...toolkitProps.baseProps })}

                                  </div>
                                </Col>
                              </Row>
                              {/* <Row className="align-items-md-center mt-30">
                               <Col className="pagination pagination-rounded justify-content-end mb-2">
                                 <PaginationListStandalone
                                   {...paginationProps}
                                 />
                               </Col>
                             </Row> */}
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <div className="text-md-center d-inline " style={{ marginLeft: '5%' }} >
                                    <span>Show Rows : </span> &nbsp;&nbsp;
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      );
                    }}
                  </PaginationProvider>

                </Col>
              </Row>
            }
          </Card>
        </Container>
      </div>
      {/* </div> */}
      <Offcanvas isOpen={addPlaylistCanvas} direction='end'  >
        <OffcanvasHeader toggle={toggleaddPlaylistCanvas}>
          {'Add Playlist'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Add_Playlist closeCanvas={toggleDrawer} type={actionType} />
        </OffcanvasBody>
      </Offcanvas>

      <Offcanvas isOpen={perticularPlaylistCanvas} direction='end'  >
        <OffcanvasHeader toggle={togglePerticularPlaylistCanvas}>
          {activePlaylist.playListOtherName}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Playlist_View closeCanvas={toggleDrawerPlaylist} statusChild={checkStatusChild} activePlaylistId={activePlaylist.playListOtherID} />
        </OffcanvasBody>
      </Offcanvas>

      <Offcanvas isOpen={cdnLinks} direction='end'  >
        <OffcanvasHeader toggle={toggleCdnLinks}>
          {'Add Playlist'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Cdn_Playlists closeCanvas={toggleCdnLinks} statusChild={checkStatusChild} activePlaylistId={activePlaylist} />
        </OffcanvasBody>
      </Offcanvas>


      <Modal size="sm" isOpen={showAlert} toggle={() => { setShowAlert(!showAlert) }} backdrop={'static'} centered={true}
        id="staticBackdrop"
      >
        <div className="modal-content">
          <ModalBody className="px-2 py-4 text-center ">
            <i className="bx bx-error-circle text-warning fon-size-32" style={{ fontSize: '2rem' }}></i> &nbsp;
            <h5 className="text-muted font-size-16 mb-4">  Are you sure you want to Archive this Playlist?</h5>
            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-light" onClick={() => { setShowAlert(!showAlert) }}>No</button>
              <button type="button" className="btn btn-primary" onClick={archivePlaylist}>Yes</button>
            </div>
          </ModalBody>
        </div>
      </Modal>

    </>
  )
}

Video_Playlists_Pub.propTypes = {}

export default Video_Playlists_Pub