import MetaTags from "react-meta-tags";
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner,
  Collapse,
  Modal
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getNotRead_Notifications as onGetNotificationLists,
} from "store/Notifications/Publisher_Notification/actions";

import axios from "axios";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

var selectedItem = []
var selectedData = ''
var selectedIndex = []

const UserProfile = props => {
  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [idx, setidx] = useState(1);

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName);
        setemail(obj.email);
        setidx(obj.uid);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username);
        setemail(obj.email);
        setidx(obj.uid);
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || '',
      idx: idx || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    }
  });

  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [selected, setSelected] = useState([])
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedNotification, setSelectedNotification] = useState({})
  const [jsTagModalState, setJsTagModalState] = useState(false);


  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  const { notificationLists } = useSelector(state => ({
    notificationLists: state.pub_notifications.notReadNotList,
  }));

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      isRead:''
    }
  }

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.notificationID)
        selectedItem.push(row.notificationID)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.notificationID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.notificationID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.notificationID)
          selectedItem.push(item.notificationID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }
    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
  }

  useEffect(() => {
    getNotifications()
  }, []);

  const getNotifications = () => {
    body.url = "http://127.0.0.1:4009/v1.0/Notifications/readByUserID"
    axios.post("/v1.0/connectOP", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is notifications ==>", res)
        if (res.data.success) {
          res.data.responseData.data.map((ele, index) => {
            ele.id = index + 1
          })
          setNotifications(res.data.responseData.data)
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const notificationListColumns = [
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "notificationID",
      sort: true,
      formatter: (cellContent, notification) => (
        <>
          <span>{notification.notificationID}</span>
        </>
      ),
    },
    {
      dataField: "notificationCreateTime",
      text: "Creation Time",
      sort: true,
    },

    {
      text: "Notification Name",
      dataField: "notificationName",
      sort: true,
    },

    {
      text: "Message",
      dataField: "message",
      sort: true,
    },
    {
      text: "Status",
      dataField: "isRead",
      sort: true,
      formatter: (cellContent, notification) => (
        <>
            <span className={notification.isRead ? "active-state"  : "archived-state" }>{notification.isRead ? "Read"  : "UnRead" }</span>
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',

      // eslint-disable-next-line react/display-name
      formatter: (cellContent, notification) => (
        <div className="d-flex gap-3">
                <Link  to={'#'} onClick={() => { tog_standard(notification); }}>
                <u>View</u> 
              </Link>
        </div>
      ),
    },

  ];

  function tog_standard(notification) {
    console.log(notification)
    if (notification !== '') {
      setSelectedNotification(notification)
      setJsTagModalState(!jsTagModalState);
    } else {
      setJsTagModalState(!jsTagModalState);
    }
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );

  const { SearchBar } = Search;
  const pageOptions = {
    sizePerPageList: [{ text: "5", value: 5 },{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    totalSize: notifications.length,
    custom: true,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

 const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.isRead) {
      style.backgroundColor = '#adb5bd36';
    } else {
      style.backgroundColor = 'rgba(54, 163, 173, .10)';
    }
    return style;
  }


  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

const readNotification = (notificationID) => (event) => {
  selectedItem.push(notificationID)
  console.log(notificationID)
  statusNotification()
}

  const statusNotification = (event) => {
    console.log('clicked')
    let body2 = {
      url : "http://127.0.0.1:4009/v1.0/Notifications/updateIsRead",
      "requestBody": {
        userID: userInfo.userID,
        notificationID: selectedItem,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
      }
    }
      axios.post("/v1.0/connectOP", body2,
        {
          headers: headers
        })
        .then((res) => {
          console.log("result is notifications ==>", res)
          if (res.data.success) {
            toastr.success('Notification Marked as Read Successfully!...')
            getNotifications()
            selectedItem = []
            setSelected([])
            setSelectedRowsStatus(false)
            setJsTagModalState(false)
            dispatch(onGetNotificationLists({isRead:'notRead'}));
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
  }

  console.log(notifications)

  if (notifications.length > 0) {
    // loader = false
    notifications.map((item, index) => {
      if (item.isRead) {
        selectRow.nonSelectable.push(index + 1)
      }
    })
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | Bidsxchange</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change User Name</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">User Name</Label>
                  <Input
                    name="username"
                    // value={name}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username ? true : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update User Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>

          <h4 className="card-title mb-4">Notifications</h4>

          {loading &&
            <Spinner className="ms-2 loader" color="primary" />
          }
          {!loading &&
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody aria-hidden="true">
                  <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField={keyField}
                  columns={notificationListColumns}
                  data={notifications}
                >
                  {({ paginationProps, paginationTableProps }) => {
                    return (
                      <ToolkitProvider
                        keyField={keyField}
                        data={notifications}
                        columns={notificationListColumns}
                        // bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            {/* <Row className="mb-2">
                              <Col sm="8">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row> */}
                            <Row>
                              <Col xl="12">
                                {selectedRowsStatus && selectedIndex.length > 0 &&
                                  <div className="row m-0 React-Table-Selection">
                                    {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                    <div style={{ padding: '0.5% 2%' }}>

                                      <button color="primary" className="btn btn-primary " onClick={statusNotification}>
                                        Mark as Read
                                      </button>
                                      <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                        className="mdi mdi-close-thick font-size-18"
                                      ></i></Button>
                                    </div>
                                  </div>
                                }
                              </Col>
                              <Col xl="12">
                                <div className="table-responsive" style={{height:'30vh'}}>
                                  <BootstrapTable
                                    keyField={keyField}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    rowStyle={rowStyle}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    noDataIndication={<h5 className="text-center">No Data</h5>}
                                  // ref={node}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {/* <Row className="align-items-md-center mt-30">
                           <Col className="pagination pagination-rounded justify-content-end mb-2">
                             <PaginationListStandalone
                               {...paginationProps}
                             />
                           </Col>
                         </Row> */}
                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                <div className="text-md-center d-inline " style={{ marginLeft: '5%' }} >
                                  <span>Show Rows : </span> &nbsp;&nbsp;
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    );
                  }}
                </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
                {selectedNotification &&
            <Modal
            size="lg"
            isOpen={jsTagModalState}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {selectedNotification.notificationName}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setJsTagModalState(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            
            <div className="modal-body">
              <div className="row mb-2">
                <div className="col-md-11">
                  <h5>Message</h5>
                </div>
              </div>
              <Input
                type="textarea"
                id="textarea"
                rows="8"
                readOnly
                name="message" value={selectedNotification.message}
              />
              <br />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setJsTagModalState(false);
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Close
              </button>
        
              <Button
                color="primary"
                className="font-16 btn-block btn btn-primary"
                onClick={readNotification(selectedNotification.notificationID)}
                disabled={selectedNotification.isRead}
              >
                Mark as Read
              </Button>
            </div>
          </Modal>
      }
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
