import axios from "axios";

//apply base url for axios
const API_URL = "";

const axiosApi = axios.create({
  baseURL: API_URL,
});

var APICalls = []

export async function APICallRequest() {
  // console.log(APICalls)
  let apiResponse = []
  if(APICalls.length > 0){
    apiResponse =  APICalls
    // const userInfo = JSON.parse(localStorage.getItem('authUser'));
    // var Headers =  {
    //   "Content-Type": "application/json",
    //   'Accept': "application/json",
    //   "Authorization": `Bearer ${userInfo.token}`
    // }
    //  apiResponse =  axiosApi
    // .post("/v1.0/getWhichAsChanged", APICalls, {headers: Headers})
    // .then(response => response.data);
  }else{
    apiResponse = []
  }
  // APICalls = []
  return apiResponse
}

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export async function demandPost(url, data,enityName, config = {}) {
  console.log(url, data,enityName)
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  if(enityName){
    APICalls = []
    APICalls.push({
      "enitity": enityName
    },{
      data:data
    })
  }
  const adminDetails = JSON.parse(localStorage.getItem('AdminDetails'));
  var Headers =  {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`
  }
    let body = {
        url : url,
        requestBody:{ ...data,    userID:userInfo.userID,
            userRole:userInfo.userRole,
            companyID:userInfo.companyID,
            name:userInfo.name }
    }
    if(userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency'){
      body.requestBody = {...body.requestBody,partnerName:userInfo.name,partnerID:userInfo.userID}
      if(adminDetails){
        body.requestBody = {...body.requestBody, userlog: {
            userID: adminDetails.userID,
            userRole: adminDetails.userRole,
            companyID: adminDetails.companyID,
            name:adminDetails.name
          }}
    }
    }
  return axiosApi
    .post(userInfo.userRole === 'Partner' ? "/v1.0/connectPartner" :userInfo.userRole === 'Agency' ? "/v1.0/connectPartner" : "/v1.0/connectOP", body, {headers: Headers})
    .then(response => response.data);
}

export async function reportPost(url, data, config = {}) {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  var Headers =  {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`
  }
    let body = {
        url : url,
        requestBody:{ ...data,    userID:userInfo.userID,
            userRole:userInfo.userRole,
            companyID:userInfo.companyID,
            name:userInfo.name }
    }
  return axiosApi
    .post("/v1.0/connectReport", body, {headers: Headers})
    .then(response => response.data);
}

export async function contextualSettingsPost(url, data,enityName, config = {}) {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  if(enityName){
    APICalls = []
    APICalls.push({
      "enitity": enityName
    },{
      data:{ ...data}
    })
  }
  console.log(APICalls)
  var Headers =  {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`
  }
    let body = { 
        url : url,
        requestBody:{ ...data,    userID:userInfo.userID,
            userRole:userInfo.userRole,
            companyID:userInfo.companyID,name:userInfo.name }
    }
    if(userInfo.userRole === 'Partner'){
      body.requestBody = {...body.requestBody,partnerName:userInfo.name,partnerID:userInfo.userID}
    }
  return axiosApi
    .post(userInfo.userRole === 'Partner' ? "/v1.0/connectPartner" :"/v1.0/connectReportAPI", body, {headers: Headers})
    .then(response => response.data);
}

export async function supportTicketPost(url, data, config = {}) {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  var Headers =  {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`
  }
    let body = {
        url : url,
        requestBody:{body: {...data,    userID:userInfo.userID,
            userRole:userInfo.userRole,
            companyID:userInfo.companyID,name:userInfo.name,partnerName:userInfo.name} }
    }
    // if(userInfo.userRole === 'Partner'){
    //   body.requestBody = {...body.requestBody,partnerName:userInfo.name}
    // }
  return axiosApi
    .post("/v1.0/connectReportAPI", body, {headers: Headers})
    .then(response => response.data);
}


export async function partnerManagementPost(url, data,enityName, config = {}) {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  if(enityName){
    APICalls = []
    APICalls.push({
      "enitity": enityName
    },{
      data:{ ...data}
    })
  }
  console.log(APICalls)
  var Headers =  {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`
  }
    let body = { ...data,partnerCountry:'', partnerType:'', userID:userInfo.userID,
            userRole:userInfo.userRole,
            companyID:userInfo.companyID,name:userInfo.name
    }
  return axiosApi
    .post(url, body, {headers: Headers})
    .then(response => response.data);
}

export async function userManagementPost(url, data,enityName, config = {}) {
  console.log(data)
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  if(enityName){
    APICalls = []
    APICalls.push({
      "enitity": enityName
    },{
      data:{ ...data}
    })
  }
  console.log(APICalls)
  var Headers =  {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`
  }
    let body = { ...data,userID:userInfo.userID,
            userRole:userInfo.userRole,
            companyID:userInfo.companyID, name:userInfo.name
    }
  return axiosApi
    .post(url, body, {headers: Headers})
    .then(response => response.data);
}

export async function partnerManagementCreatePost(url, data, config = {}) {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  var Headers =  {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`
  }
  return axiosApi
    .post(url, data, {headers: Headers})
    .then(response => response.data);
}