import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ADUNITS,GET_ADUNITS_TC,GET_ADUNITS_HIS,ADD_ADUNIT , UPDATE_ADUNIT,READ_ADUNIT,STATUS_ADUNIT,ARCHIVE_ADUNIT} from "./actionTypes"

import {
  loadActivitiesAdUnit,
  noDataAdUnit,
  getAdUnitSuccess,
  getAdUnitFail,
  getAdUnitTcSuccess,
  getAdUnitTcFail,
  getAdUnitHISSuccess,
  getAdUnitHISFail,
  addAdUnitSuccess,
  addAdUnitFail,
  updateAdUnitSuccess,
  updateAdUnitFail,
  readAdUnitSuccess,
  statusAdUnitSuccess,
  archiveAdUnitSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getAdUnit,getAdUnitRichMedia,getAdUnitStandardBanner,getAdUnitbyTC,getAdUnitbyHIS,getAdUnitVideo,getAdUnitsByAdUnitGroup,createAdUnit,updateAdUnit,readAdUnit,statusChangeAdUnit,archiveAdUnit } from "../../../helpers/Backend_helper"

function* fetchAdUnits({ payload: adUnit }) {
  try {
    yield put(loadActivitiesAdUnit(true))
    var getAdUnitList
    // if(adUnit === 'richMedia'){
    //   getAdUnitList = yield call(getAdUnitRichMedia)
    //   getAdUnitList.responseData.response.map((item, index) => {
    //     item.id = index + 1
    // })
    // yield put(getAdUnitSuccess(getAdUnitList.responseData.response))
    // }else if(adUnit === 'standardBanner'){
    //   getAdUnitList = yield call(getAdUnitStandardBanner)
    //   getAdUnitList.responseData.response.map((item, index) => {
    //     item.id = index + 1
    // })
    // yield put(getAdUnitSuccess(getAdUnitList.responseData.response))
    // }else if(adUnit === 'video'){
    //   getAdUnitList = yield call(getAdUnitVideo)
    //   getAdUnitList.responseData.response.map((item, index) => {
    //     item.id = index + 1
    // })
    // yield put(getAdUnitSuccess(getAdUnitList.responseData.response))
    // }
   if(adUnit && adUnit.adUnitGroupID !== '' ){
      getAdUnitList = yield call(getAdUnitsByAdUnitGroup,adUnit)
      getAdUnitList.responseData.response.map((item, index) => {
        item.id = index + 1
    })
    if(getAdUnitList.responseData.response.length === 0){
      yield put(noDataAdUnit(true))
    }
      yield put(getAdUnitSuccess(getAdUnitList.responseData))
    }else if(adUnit && adUnit.adUnitGroupID === ''){
      delete adUnit.adUnitGroupID
      getAdUnitList = yield call(getAdUnit,adUnit)
      getAdUnitList.responseData.response.map((item, index) => {
        item.id = index + 1
    })
    if(getAdUnitList.responseData.response.length === 0){
      yield put(noDataAdUnit(true))
    }
      yield put(getAdUnitSuccess(getAdUnitList.responseData))
    }else {
      getAdUnitList = []
      yield put(getAdUnitSuccess(getAdUnitList))
    }
  } catch (error) {
    yield put(getAdUnitFail(error))
  }
}

function* fetchAdUnitsTC({ payload: adUnit }) {
  try {
    var getAdUnitListbyTC
    if(adUnit ){
      getAdUnitListbyTC = yield call(getAdUnitbyTC,adUnit)
      getAdUnitListbyTC.responseData.data.map((item, index) => {
        item.id = index + 1
    })
    console.log(getAdUnitListbyTC)
      yield put(getAdUnitTcSuccess(getAdUnitListbyTC.responseData.data))
    }else {
      getAdUnitListbyTC = []
      yield put(getAdUnitTcSuccess(getAdUnitListbyTC))
    }
  } catch (error) {
    yield put(getAdUnitTcFail(error))
  }
}

function* fetchAdUnitsHIS({ payload: adUnit }) {
  try {
    var getAdUnitListbyHIS
    if(adUnit ){
      getAdUnitListbyHIS = yield call(getAdUnitbyHIS,adUnit)
      getAdUnitListbyHIS.responseData.data.map((item, index) => {
        item.id = index + 1
    })
    console.log(getAdUnitListbyHIS)
      yield put(getAdUnitHISSuccess(getAdUnitListbyHIS.responseData.data))
    }else {
      getAdUnitListbyHIS = []
      yield put(getAdUnitHISSuccess(getAdUnitListbyHIS))
    }
  } catch (error) {
    yield put(getAdUnitHISFail(error))
  }
}

function* onReadAdUnit({ payload: adUnit }) {
  try {
    const response = yield call(readAdUnit, adUnit)
    yield put(readAdUnitSuccess(response))
  } catch (error) {
    yield put(readAdUnitSuccess(error))
  }
}

function* onAddAdUnit({ payload: adUnit }) {
  try {
    const response = yield call(createAdUnit, adUnit)
    if(response.responseData.success){
      yield put(addAdUnitSuccess(response.responseData))
    }else{
      yield put(addAdUnitFail(response.responseData))
    }
  } catch (error) {
    yield put(addAdUnitFail(error))
  }
}

function* onUpdateAdUnit({ payload: adUnit }) {
  try {
    const response = yield call(updateAdUnit, adUnit)
    if(response.responseData.success){
      yield put(updateAdUnitSuccess(response.responseData))
    }else{
      yield put(updateAdUnitFail(response.responseData))
    }
  } catch (error) {
    yield put(updateAdUnitFail(error))
  }
}

function* onStatusAdUnit({ payload: adUnit }) {
  try {
    const response = yield call(statusChangeAdUnit, adUnit)
    yield put(statusAdUnitSuccess(response))
  } catch (error) {
    yield put(statusAdUnitSuccess(error))
  }
}

function* onArchiveAdUnit({ payload: adUnit }) {
  try {
    const response = yield call(archiveAdUnit, adUnit)
    yield put(archiveAdUnitSuccess(response))
  } catch (error) {
    yield put(archiveAdUnitSuccess(error))
  }
}

function* adUnitSaga() {
  yield takeEvery(GET_ADUNITS, fetchAdUnits)
  yield takeEvery(GET_ADUNITS_TC, fetchAdUnitsTC)
  yield takeEvery(GET_ADUNITS_HIS,fetchAdUnitsHIS)
  yield takeEvery(ADD_ADUNIT, onAddAdUnit)
  yield takeEvery(UPDATE_ADUNIT, onUpdateAdUnit)
  yield takeEvery(READ_ADUNIT, onReadAdUnit)
  yield takeEvery(STATUS_ADUNIT, onStatusAdUnit)
  yield takeEvery(ARCHIVE_ADUNIT, onArchiveAdUnit)
}

export default adUnitSaga;
