import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Modal,
  Input,
  Spinner,
  UncontrolledPopover,
  Popover,
  PopoverHeader,
  PopoverBody,
  Label
} from "reactstrap"
import queryString from 'query-string';

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getAdUnit as onGetAdUnits,
  statusAdUnit as onStatusAdUnit,
  archiveAdUnit as onArchiveAdUnit,
  readAdUnit as onReadAdUnit,
  statusAdUnitSuccess,
  archiveAdUnitSuccess,
  addAdUnitFail,
  updateAdUnitFail,
} from "store/Supply/AdUnit/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Create_AdUnit from "./Create_AdUnit";
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import LineItemDataTable from "Product_Helpers/ProductDataTable"
import ProductDataTableLI from "Product_Helpers/ProductDataTableLI";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var adUnitType = ''

var filterCount = 0

let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const AdUnit = () => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [isRight, setIsRight] = useState(false);
  const userRole = localStorage.getItem('UserRole');
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [btnprimary1, setBtnprimary1] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [jsTagModalState, setJsTagModalState] = useState(false);
  const [adUnitData, setAdUnitData] = useState()
  const inputRef = useRef();
  const [statusSearch, setStatusSearch] = useState('Active')
  const [adUnitTypeSearch, setAdUnitTypeSearch] = useState('')
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const [popoverdismiss, setpopoverdismiss] = useState(false);
  const [createState, setCreateState] = useState(true)

  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
  const UserInventoryType = localStorage.getItem('InventoryType');

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [switchLoader, setSwitchLoader] = useState(true)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);


  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const adUnitTypeChange = (e) => {
    if (e.target.value === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (adUnitTypeSearch === '') {
      filterCount = filterCount + 1
    }
    setAdUnitTypeSearch(e.target.value)
  }

  const applyFilter = (e) => {
    if (parsed.adUnitGroupId) {
      dispatch(onGetAdUnits({ adUnitGroupID: parsed.adUnitGroupId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
    } else {
      dispatch(onGetAdUnits({ adUnitGroupID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
    }
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setStatusSearch('Active')
    setAdUnitTypeSearch('')
    filterCount = 0
    setFilterState(false)
    if (parsed.adUnitGroupId) {
      dispatch(onGetAdUnits({ adUnitGroupID: parsed.adUnitGroupId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: 'Active',userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: '', adUnitSize: '' }));
    } else {
      dispatch(onGetAdUnits({ adUnitGroupID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: 'Active',userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: '', adUnitSize: '' }));
    }
  }

  // const statusChange = (e) => {
  //   setStatusSearch(e.target.value)
  //   if (parsed.adUnitGroupId) {
  //     dispatch(onGetAdUnits({ adUnitGroupID: parsed.adUnitGroupId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: e.target.value,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
  //   } else {
  //     dispatch(onGetAdUnits({ adUnitGroupID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: e.target.value,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
  //   }
  // }

  // const adUnitTypeChange = (e) => {
  //   setAdUnitTypeSearch(e.target.value)
  //   if (parsed.adUnitGroupId) {
  //     dispatch(onGetAdUnits({ adUnitGroupID: parsed.adUnitGroupId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: e.target.value, adUnitSize: '' }));
  //   } else {
  //     dispatch(onGetAdUnits({ adUnitGroupID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: e.target.value, adUnitSize: '' }));
  //   }
  // }

  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    dispatch(onGetAdUnits(null));
    totalRecords = 0
    if (parsed.status === 'AdUnitCreateByAdUnitGroup') {
      adUnitType = parsed.adUnitType
      setIsRight(true);
      setCreateState(!createState)
    }
  }, []);

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.adUnitID)
        selectedItem.push(row.adUnitID)
        console.log(selectedIndex)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.adUnitID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.adUnitID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.adUnitID)
          selectedItem.push(item.adUnitID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }

    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { adUnits,paginationData } = useSelector(state => ({
    adUnits: state.adUnits.adUnitList,
    paginationData: state.adUnits.pagination
  }));

  totalRecords = paginationData ? paginationData.total_records ? paginationData.total_records : 0 : 0

  const { loading } = useSelector(state => ({
    loading: state.adUnits.loading,
  }));

  const { noData } = useSelector(state => ({
    noData: state.adUnits.noData,
  }));

  const { adUnitsResponse } = useSelector(state => ({
    adUnitsResponse: state.adUnits.response,
  }));

  const { archiveAdUnitsResponse } = useSelector(state => ({
    archiveAdUnitsResponse: state.adUnits.archiveMsg,
  }));

  const { readUnitData } = useSelector(state => ({
    readUnitData: state.adUnits.adUnit,
  }));

  // const { errorMsg, successMsg } = useSelector(state => ({
  //   errorMsg: state.adUnits.errorMsg,
  //   successMsg: state.adUnits.successMsg,
  // }));

  useEffect(() => {
    console.log(loading)
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);

  console.log(adUnitsResponse)
  useEffect(() => {
    if (adUnitsResponse !== null) {
      if (adUnitsResponse.success) {
        toastr.success('AdUnit Status Changed Successfully')
        if (parsed.adUnitGroupId) {
          dispatch(onGetAdUnits({ adUnitGroupID: parsed.adUnitGroupId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
        } else {
          dispatch(onGetAdUnits({ adUnitGroupID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(statusAdUnitSuccess(null));
      }
    }
  }, [dispatch, adUnitsResponse]);

  useEffect(() => {
    if (archiveAdUnitsResponse !== null) {
      if (archiveAdUnitsResponse.success) {
        toastr.success('AdUnit Archived Successfully')
        if (parsed.adUnitGroupId) {
          dispatch(onGetAdUnits({ adUnitGroupID: parsed.adUnitGroupId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
        } else {
          dispatch(onGetAdUnits({ adUnitGroupID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(archiveAdUnitSuccess(null));
      }
    }
  }, [dispatch, archiveAdUnitsResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    if (parsed.adUnitGroupId) {
      dispatch(onGetAdUnits({ adUnitGroupID: parsed.adUnitGroupId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
    } else {
      dispatch(onGetAdUnits({ adUnitGroupID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
    }
    dispatch(addAdUnitFail(""));
    dispatch(updateAdUnitFail(""));
    setIsRight(!isRight);
    setCreateState(!createState)
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, adUnit,adUnitTypes) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('AdUnit Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('AdUnit Updated Successfully!...')
    }
    selectedData = adUnit
    adUnitType = adUnitTypes
      setIsArchived(adUnit.isAdUnitArchived)
    if (parsed.adUnitGroupId) {
      dispatch(onGetAdUnits({ adUnitGroupID: parsed.adUnitGroupId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
    } else {
      dispatch(onGetAdUnits({ adUnitGroupID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
    }
    dispatch(addAdUnitFail(""));
    dispatch(updateAdUnitFail(""));
    setIsRight(close);
    setCreateState(!createState)
  };

  useEffect(() => {
    console.log(adUnits)
    if (parsed.adUnitGroupId) {
      dispatch(onGetAdUnits({ adUnitGroupID: parsed.adUnitGroupId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
    }
  }, []);

  useEffect(() => {
    if (adUnits !== null && !parsed.adUnitGroupId) {
      dispatch(onGetAdUnits({ adUnitGroupID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
    }
  }, []);



  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const adUnitListColumns = [
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "adUnitID",
      sort: true,
    },
    {
      dataField: "adUnitCreateTime",
      text: "Creation Date",
      sort: true,
    },
    {
      text: "AdUnit Name",
      dataField: "adUnitName",
      sort: true,
      style: {
        minWidth: "30em"
      },
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, adUnit) => (
        <>
          {userRole === 'Publisher' ?
            <span className="font-size-12 mb-1" >
              {adUnit.adUnitName}
            </span> :
            <span className="font-size-12 mb-1" >
              {(adUnit.adUnitType === 'highImpact') &&
                <Link to={`/ChildAdUnit?adUnitId=${adUnit.adUnitID}&adUnitName=${adUnit.adUnitName}&childAdUnitType=${adUnit.adUnitType}&status=ChildAdUnitCreateByParent`} className="color-dark-blue ">
                  {adUnit.adUnitName}
                </Link>
              }
              {(adUnit.adUnitType !== 'highImpact') &&
                <span>{adUnit.adUnitName}</span>
              }
              <br />
              {(!adUnit.isLegecy && !adUnit.takeTag) &&
                (adUnit.adUnitType === 'stdb' ? <Link to={`/Stbr_Settings?adUnitID=${adUnit.adUnitID}&adUnitName=${adUnit.adUnitName}&status=StandardBannerCreateByAdUnit`} className=" " style={{ color: 'red' }}>
                  * Need to Attach Standard Banner Settings | <span style={{ color: 'blue' }}> + Add Standard Banner Settings </span>
                </Link> : adUnit.adUnitType === 'highImpact' ? <Link to={`/High_Impact?adUnitID=${adUnit.adUnitID}&adUnitName=${adUnit.adUnitName}&status=HighImpactCreateByAdUnit`} className=" " style={{ color: 'red' }}>
                  * Need to Attach High Impact Settings | <span style={{ color: 'blue' }}> + Add High Impact Settings </span>
                </Link> : <Link to={`/Player_Settings?adUnitID=${adUnit.adUnitID}&adUnitName=${adUnit.adUnitName}&status=PlayerSettingCreateByAdUnit`} className=" " style={{ color: 'red' }}>
                  * Need to Attach Player Settings | <span style={{ color: 'blue' }}> + Add Player Settings </span>
                </Link>)
              }
            </span>}
        </>
      ),
    },
    {
      text: "AdUnit Type",
      dataField: "adUnitType",
      sort: true,
      formatter: (cellContent, adUnit) => (
        <div className=' ' >
          {adUnit.adUnitType === 'play' &&
            <span>Player</span>
          }
          {adUnit.adUnitType === 'vast3.0' &&
            <span>Vast</span>
          }
          {adUnit.adUnitType === 'vpaid2.0' &&
            <span>VPaid</span>
          }
          {adUnit.adUnitType === 'stdb' &&
            <span>Standard Banner</span>
          }
          {adUnit.adUnitType === 'highImpact' &&
            <span>High Impact</span>
          }
          {adUnit.adUnitType === 'legacy' &&
            <span>Legacy</span>
          }
        </div>
      )
    },
    {
      dataField: "isHBEnabled",
      text: "HB",
      formatter: (cellContent, adUnit) => (
        <>
          <span style={{ color: adUnit.isHBEnabled ? '#34c38f' : 'gray' }}>
            <i
              className="bx bxs-circle
                  font-size-18"
              id="edittooltip"
            ></i></span>
        </>
      ),
    },
    {
      dataField: "adUnitStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, adUnit) => (
        <>
          <span className={adUnit.newStatus === 'Active' ? "active-state" : adUnit.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{adUnit.newStatus}</span>
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, adUnit) => (
        <div className="d-flex gap-3">
          {(UserRolePrivileges.Supply && UserRolePrivileges.Supply.AdUnits) && (UserRolePrivileges.Supply.AdUnits.update) &&
            <span className="text-success " onClick={toggleDrawer(true, adUnit, adUnit.adUnitType)}>
              {adUnit.newStatus === 'Archived' &&
                <i
                  className="mdi mdi-eye font-size-18"
                  id="edittooltip"
                ></i>
              }
              {adUnit.newStatus !== 'Archived' &&
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                ></i>
              }
            </span>
          }
          {adUnit.isLegecy &&
            <span onClick={() => { tog_standard(adUnit); }} style={{ cursor: 'pointer' }}>
              <i
                className="bx bx-copy-alt font-size-18"
                id="edittooltip"
              ></i>
            </span>
          }
          {!adUnit.isLegecy && adUnit.takeTag &&
            <span onClick={() => { tog_standard(adUnit); }} style={{ cursor: 'pointer' }}>
              <i
                className="bx bx-copy-alt font-size-18"
                id="edittooltip"
              ></i>
            </span>
          }

        </div>
      ),
    },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archiveAdUnit = async () => {
    dispatch(onArchiveAdUnit({ idList: selectedItem,userRole:userInfo.userRole,userID:userInfo.userID }));
  }

  const statusAdUnit = (state) => (event) => {
    console.log(state)
    dispatch(onStatusAdUnit({ idList: selectedItem, adUnitStatus: state,userRole:userInfo.userRole,userID:userInfo.userID }));
  }

  console.log(readUnitData)

  useEffect(() => {
    if (readUnitData !== null) {
      if (readUnitData.responseData.success) {
        setAdUnitData(readUnitData.responseData.response)
      }
    }
  }, [readUnitData]);

  function tog_standard(adUnit) {
    console.log(adUnit)
    if (adUnit !== '') {
      dispatch(onReadAdUnit(adUnit.adUnitID));
      setJsTagModalState(!jsTagModalState);
    } else {
      setJsTagModalState(!jsTagModalState);
    }
  }

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([readUnitData.responseData.response.url], {
      type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = (readUnitData.responseData.response.adUnitName + ".txt");
    document.body.appendChild(element);
    element.click();
  };

  const copyTag = (e) => {
    setpopoverdismiss(!popoverdismiss)
    navigator.clipboard.writeText(readUnitData.responseData.response.url)
  }

  console.log(adUnits)

  if (adUnits.length > 0) {
    if (UserRolePrivileges.Supply && UserRolePrivileges.Supply.AdUnits) {
      if (!UserRolePrivileges.Supply.AdUnits.archive && !UserRolePrivileges.Supply.AdUnits.status) {
        adUnits.map((item, index) => {
          selectRow.nonSelectable.push(index + 1)
        })
      } else {
        adUnits.map((item, index) => {
          if (item.isAdUnitArchived) {
            selectRow.nonSelectable.push(index + 1)
          }
        })
      }
    }
  }

  const paginationChange = async (selectedPages) => {
    console.log(selectedPages)
    pageSize = selectedPages.pageSize
    pageNumber = selectedPages.pageNumber
    if (parsed.adUnitGroupId) {
      dispatch(onGetAdUnits({ adUnitGroupID: parsed.adUnitGroupId, pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
    } else {
      dispatch(onGetAdUnits({ adUnitGroupID: '', pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText,filter: statusSearch,userRole:userInfo.userRole,userID:userInfo.userID, adUnitType: adUnitTypeSearch, adUnitSize: '' }));
    }
  }

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <MetaTags>
          <title>AdUnit | Bidsxchange</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div className='row'>
            <div className='col-8'>
              {parsed.adUnitGroupId ? (
                <SupplyBreadCrumb title="Supply" breadcrumbItem="Ad Unit " adUnitGroupId={parsed.adUnitGroupId} />
              ) : (
                <SupplyBreadCrumb title="Supply" breadcrumbItem="Ad Unit " breadList="All Ad Units" />
              )}
            </div>
            <div className='col-4'>
              <div className='row'>
                {UserRolePrivileges.Supply && UserRolePrivileges.Supply.AdUnits && UserRolePrivileges.Supply.AdUnits.create &&
                  <div className="text-sm-end">
                    <div className="btn-group">
                      <Dropdown
                        direction="left"
                        isOpen={isMenu}
                        toggle={toggleMenu}
                      >
                        <DropdownToggle
                          tag="button"
                          className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                        >
                          <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                          {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                            Filter Applied
                          </span> : <span className="d-none font-16 d-sm-inline-block">
                            Filter
                          </span>
                          }
                          {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px',color:'red' }}>{filterCount}</span>
                              } */}
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                          <div className="dropdown-item-text">
                            <div className="row">
                              <h5 className="mb-0">Filters</h5>
                            </div>
                          </div>

                          <DropdownItem divider />
                          <Card>
                            <CardBody aria-hidden="true">
                              <Row >
                                {UserInventoryType !== 'video' &&
                                  <Col sm="12" className="mt-2">
                                    <Label className="form-label">AdUnit Type</Label>
                                    <div className=" text-sm-end">
                                      <Input type="select" className="form-select" value={adUnitTypeSearch} onChange={adUnitTypeChange}>
                                        <option value=''> All</option>
                                        <option value='stdb' >Standard Banner</option>
                                        <option value='highImpact'> High Impact</option>
                                        {UserInventoryType === 'both' &&
                                          <option value='play'> Video</option>
                                        }
                                      </Input>
                                    </div>
                                  </Col>}
                                <Col sm="12" className="mt-2">
                                  <Label className="form-label">Status</Label>
                                  <div className=" text-sm-end">
                                    <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                      <option value=''> All</option>
                                      <option value='Active' > Active</option>
                                      <option value='Inactive'> InActive</option>
                                      <option value='Archived'> Archived</option>
                                    </Input>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>

                          <DropdownItem divider />
                          <Row>
                            <Col sm="6">
                              <DropdownItem
                                className="text-primary text-center"
                                onClick={clearAllFilter}
                              >
                                Clear ALL
                                {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                              } */}
                              </DropdownItem>
                            </Col>
                            <Col sm="6">
                              <DropdownItem
                                className="text-primary text-center"
                                onClick={applyFilter}
                              >
                                Apply Filter
                                {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                              } */}
                              </DropdownItem>
                            </Col>
                          </Row>

                        </DropdownMenu>
                      </Dropdown>
                    </div> &nbsp;&nbsp;
                    {(parsed.status === 'AdUnitCreateByAdUnitGroup' || parsed.status === 'AdUnitCreateByParent') &&
                      <div className="btn-group">
                        {(parsed.adUnitType === 'video') &&
                          <div className="btn-group">
                            <Dropdown
                              isOpen={btnprimary1}
                              toggle={() => setBtnprimary1(!btnprimary1)}
                            >
                              <DropdownToggle tag="button" className="btn btn-primary">
                                <i className="mdi mdi-plus-circle-outline me-1" />  Add AdUnit &nbsp; <i className="mdi mdi-chevron-down" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem header>Video</DropdownItem>
                                <DropdownItem onClick={toggleDrawer(true, '', 'play')}>Player</DropdownItem>
                                <DropdownItem onClick={toggleDrawer(true, '', 'vast3.0')} disabled>Vast 3.0</DropdownItem>
                                <DropdownItem onClick={toggleDrawer(true, '', 'vpaid2.0')} disabled>VPaid 2.0</DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        }
                        {(parsed.adUnitType !== 'video') &&
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={toggleDrawer(true, '', parsed.adUnitType)}
                          >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            Add AdUnit
                          </Button>
                        }

                      </div>
                    }
                    {(!parsed.status) &&
                      <div className="btn-group">
                        {UserInventoryType !== 'video' ?
                          <Dropdown
                            isOpen={btnprimary1}
                            toggle={() => setBtnprimary1(!btnprimary1)}
                          >
                            <DropdownToggle tag="button" className="btn btn-primary">
                              <i className="mdi mdi-plus-circle-outline me-1" />  Add AdUnit &nbsp; <i className="mdi mdi-chevron-down" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem header>Standard</DropdownItem>
                              <DropdownItem onClick={toggleDrawer(true, '', 'stdb')}>Standard Banner</DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem onClick={toggleDrawer(true, '', 'highImpact')}>High Impact Settings</DropdownItem>
                              <DropdownItem divider />
                              {UserInventoryType === 'both' &&
                                <>
                                  <DropdownItem header>Video</DropdownItem>
                                  <DropdownItem onClick={toggleDrawer(true, '', 'play')}>Player</DropdownItem>
                                  <DropdownItem onClick={toggleDrawer(true, '', 'vast3.0')} disabled>Vast 3.0</DropdownItem>
                                  <DropdownItem onClick={toggleDrawer(true, '', 'vpaid2.0')} disabled>VPaid 2.0</DropdownItem>
                                </>
                              }
                            </DropdownMenu>
                          </Dropdown> : <Dropdown
                            isOpen={btnprimary1}
                            toggle={() => setBtnprimary1(!btnprimary1)}
                          >
                            <DropdownToggle tag="button" className="btn btn-primary">
                              <i className="mdi mdi-plus-circle-outline me-1" />  Add AdUnit &nbsp; <i className="mdi mdi-chevron-down" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem header>Video</DropdownItem>
                              <DropdownItem onClick={toggleDrawer(true, '', 'play')}>Player</DropdownItem>
                              <DropdownItem onClick={toggleDrawer(true, '', 'vast3.0')} disabled>Vast 3.0</DropdownItem>
                              <DropdownItem onClick={toggleDrawer(true, '', 'vpaid2.0')} disabled >VPaid 2.0</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>}
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
          <ProductDataTableLI statusAccess={(UserRolePrivileges.Supply && UserRolePrivileges.Supply.AdUnits && UserRolePrivileges.Supply.AdUnits.status) ? true : false}
            archiveAccess={(UserRolePrivileges.Supply && UserRolePrivileges.Supply.AdUnits && UserRolePrivileges.Supply.AdUnits.archive) ? true : false} loading={loading} data={adUnits} columns={adUnitListColumns} statusChange={statusAdUnit} archiveLineItem={archiveAdUnit} rowSelection={selectRow} handleClose={handleClose} selectedIndex={selectedIndex} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize}  />
          
        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end' >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit AdUnit' : 'New ' + adUnitType + ' AdUnit'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_AdUnit closeCanvas={toggleDrawer(false, '', '')} selectedAdUnit={selectedData} selectedArchived={isArchived} selectedAdUnitType={adUnitType} selectedAdUnitGroup={(parsed.status === 'AdUnitCreateByAdUnitGroup' || parsed.status === 'AdUnitCreateByParent') ? parsed : ''} />
        </OffcanvasBody>
      </Offcanvas>


      {readUnitData &&
        <Modal
          size="lg"
          isOpen={jsTagModalState}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Ad Unit : {readUnitData.responseData.response.adUnitName}
            </h5>
            <button
              type="button"
              onClick={() => {
                setJsTagModalState(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h5>JS Tag</h5>
            <br />
            <Input
              ref={inputRef}
              type="textarea"
              id="textarea"
              rows="8"
              readOnly
              name="jsTag" value={readUnitData.responseData.response.url}
            />
            <br />
            {readUnitData.responseData.response.adUnitType === 'highImpact' &&
              <>
                <h5>AMP Code</h5>
                <Input
                  type="textarea"
                  id="textarea"
                  className="mt-3"
                  rows="1"
                  readOnly
                  name="jsTag" value={'amp.bidsxchange.com/AdCode.js'}
                />
              </>
            }


          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                setJsTagModalState(false);
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              Close
            </button>
            {readUnitData.responseData.response.adUnitType === 'highImpact' &&
              <Button
                color="primary"
                id="Popoverdismiss1"
                className="font-16 btn-block btn btn-primary"
                onClick={(e) => navigator.clipboard.writeText('amp.bidsxchange.com/AdCode.js')}
              >
                <i className="bx bx-copy me-1" />
                Copy AMP Code
              </Button>

            }
            <ReactTooltip
              anchorSelect="#Popoverdismiss1"
              place="top"
              variant="dark"
              openOnClick="true"
              delayHide={800}
              content="Copied"
            />
            <Button
              color="primary"
              id="Popoverdismiss2"
              className="font-16 btn-block btn btn-primary"
              onClick={copyTag}
              onMouseLeave={() => {
                setpopoverdismiss(!popoverdismiss)
              }}
            >
              <i className="bx bx-copy me-1" />
              Copy Tag
            </Button>
            <ReactTooltip
              anchorSelect="#Popoverdismiss2"
              place="top"
              variant="dark"
              openOnClick="true"
              delayHide={800}
              content="Copied"
            />
            <Button
              color="primary"
              className="font-16 btn-block btn btn-primary"
              onClick={downloadTxtFile}
            >
              <i className="bx bx-download me-1" />
              Download Tag
            </Button>
          </div>
        </Modal>
      }
    </React.Fragment>
  )
}

export default AdUnit


