import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import axios from "axios";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Input,
    Label
} from "reactstrap"

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
// import Create_PM from "./Create_PM"

import {
    getPartners as onGetPartners,
    statusPartner as onStatusPartner,
    archivePartner as onArchivePartner,
    statusPartnerSuccess,
    archivePartnerSuccess
} from "store/PartnerManagement/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";

var selectedItem = ''
var selectedData = ''
var selectedIndex = []
var partners = []

const ReportQueryHistory = props => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const changeDatePick = (event) => {
      console.log(event)
      console.log("start: ", event.startDate._d);
      console.log("end: ", event.endDate._d);
      setFromDate(event.startDate._d.toISOString());
      setToDate(event.endDate._d.toISOString());
  }


  toastr.options = {
      positionClass: "toast-top-center",
      closeButton: true,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 5000,
      extendedTimeOut: 1000
  };

  const selectRow = {
      mode: "checkbox",
      onSelect: (row, isSelect, rowIndex, e) => {
          console.log(row, isSelect, rowIndex)
          if (isSelect) {
              setSelectedRowsStatus(true)
              selectedIndex.push(row.partnerID)
              selectedItem = row.partnerID
              console.log(selectedIndex)
          } else {
              let index = selectedIndex.indexOf(row.partnerID)
              selectedIndex.splice(index, 1)
              if (selectedIndex.length === 0) {
                  setSelectedRowsStatus(false)
              }
              console.log(selectedIndex)
          }
      },
      onSelectAll: (isSelect, rows, e) => {
          console.log(isSelect);
          console.log(rows);
          console.log(e);
      }
  };

  const handleClose = () => {
      setSelectedRowsStatus(false)
  }

  // const { partners } = useSelector(state => ({
  //     partners: state.partners.partners,
  // }));

  const { partnersResponse } = useSelector(state => ({
      partnersResponse: state.partners.response,
  }));

  const { archivePartnersResponse } = useSelector(state => ({
      archivePartnersResponse: state.partners.archiveMsg,
  }));

  console.log(partnersResponse)
  useEffect(() => {
      if (partnersResponse !== null) {
          if (partnersResponse.success) {
              toastr.success('Partner Status Changed Successfully')
              dispatch(onGetPartners());
              dispatch(statusPartnerSuccess(null));
              setSelectedRowsStatus(false)
              selectedItem = ''
              selectedIndex = []
              selectRow.selected = []
          }
      }
  }, [dispatch, partnersResponse]);

  useEffect(() => {
      if (archivePartnersResponse !== null) {
          if (archivePartnersResponse.success) {
              toastr.success('Partner Archived Successfully')
              dispatch(onGetPartners());
              dispatch(archivePartnerSuccess(null));
              setSelectedRowsStatus(false)
              selectedItem = ''
              selectRow.selected = []
              selectedIndex = []
          }
      }
  }, [dispatch, archivePartnersResponse]);

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
}

const body = {
    "url": '',
    "requestBody": {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        userName:userInfo.username,
        role:userInfo.userRole,
        name:userInfo.name,
    }
}

useEffect(() => {
    body.url = "http://127.0.0.1:2006/bsrv/queryHistoryreport"
    axios.post("/v1.0/connectReport", body,
        {
            headers: headers
        })
        .then((res) => {
            console.log("result is ==>", res)
            if (res.data.responseData) {
                // setFilterData(res.data.responseData)
            }
        })
        .catch((err) => {
            console.log("e", err)
        });
}, []);

  const toggleRightCanvas = () => {
      selectedData = ''
      dispatch(onGetPartners());
      setIsRight(!isRight);
  };
  const toggleEnableScroll = () => {
      setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, partner) => (event) => {
      console.log(event)
      if (event === 'Create') {
          toastr.success('Partner Saved Successfully!...')
      }
      if (event === 'Update') {
          toastr.success('Partner Updated Successfully!...')
      }
      selectedData = partner
      dispatch(onGetPartners());
      setIsRight(close);
  };

  useEffect(() => {
      if (partners !== null) {
          dispatch(onGetPartners());
      }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
      if (
          node &&
          node.current &&
          node.current.props &&
          node.current.props.pagination &&
          node.current.props.pagination.options
      ) {
          node.current.props.pagination.options.onPageChange(page);
      }
  };

  const keyField = "id";

  const partnerListColumns = [
      {
          text: "ID",
 headerClasses:"ID",
          dataField: "id",
          sort: true,
          hidden: true,
          // eslint-disable-next-line react/display-name
          formatter: user => <>{user.id}</>,
      },
      {
          text: "Creation Date",
          dataField: "queryID",
          sort: true,
      },
 
      {
          text: "Name",
          dataField: "name",
          sort: true,
      },
      {
        dataField: "period",
        text: "Period",
        sort: true,
    },
      {
          dataField: "Configurations",
          text: "Configurations",
          sort: true,
      },

      {
          dataField: "",
          isDummyField: true,
          editable: false,
          text: "Action",
          align: 'center',

          // eslint-disable-next-line react/display-name
          // formatter: (cellContent, partner) => (
          //     <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
          //         <Link className="text-success" to="#" onClick={toggleDrawer(true, partner)}>
          //             <i
          //                 className="mdi mdi-download font-size-18"
          //                 id="edittooltip"
          //             ></i>
          //         </Link>
          //     </div>
          // ),
      },
  ];

  const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing { from } to { to } of { size } entries
      </span>
    );

  const { SearchBar } = Search;
  const pageOptions = {
      sizePerPage: 10,
      totalSize: partners.length, 
      custom: true,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
  };

  const defaultSorted = [
      {
          dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
          order: "desc", // desc or asc
      },
  ];

  const archivePartner = async () => {
      dispatch(onArchivePartner({ partnerID: selectedItem }));
  }

  const statusPartner = (state) => (event) => {
      console.log(state)
      dispatch(onStatusPartner({ partnerID: selectedItem, userStatus: state }));
  }

  console.log(partners)

  return (
      <React.Fragment>
                  <Container fluid={true}>
                      <div className="page-title-box d-sm-flex  justify-content-between">
                          <SupplyBreadCrumb title="Supply" breadcrumbItem="Report List" />
                      </div>
                      <Row>
                          <Col lg="12">
                              <Card>
                                  <CardBody>
                                      <PaginationProvider
                                          pagination={paginationFactory(pageOptions)}
                                          keyField={keyField}
                                          columns={partnerListColumns}
                                          data={partners}
                                      >
                                          {({ paginationProps, paginationTableProps }) => {
                                              return (
                                                  <ToolkitProvider
                                                      keyField={keyField}
                                                      data={partners}
                                                      columns={partnerListColumns}
                                                      // bootstrap4
                                                      search
                                                  >
                                                      {toolkitProps => (
                                                          <React.Fragment>
                                                              <Row className="mb-2">
                                                              <Col sm="8">
                                                                      <div className="search-box ms-2 mb-2 d-inline-block">
                                                                      <div className="position-relative">
                                                                          <SearchBar {...toolkitProps.searchProps} />
                                                                          <i className="bx bx-search-alt search-icon" />
                                                                      </div>
                                                                      </div>
                                                                  </Col>
                                                              </Row>
                                                              <Row>
                                                                  <Col xl="12">
                                                                      <div className="table-responsive">
                                                                          <BootstrapTable
                                                                              keyField={keyField}
                                                                              responsive
                                                                              bordered={false}
                                                                              striped={false}
                                                                              defaultSorted={defaultSorted}
                                                                              classes={
                                                                                  "table align-middle table-nowrap"
                                                                              }
                                                                              headerWrapperClasses={"thead-light"}
                                                                              {...toolkitProps.baseProps}
                                                                              {...paginationTableProps}
                                                                              noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                          // ref={node}
                                                                          />
                                                                      </div>
                                                                  </Col>
                                                              </Row>
                                                      <Row className="align-items-md-center mt-30">
                                                                  <Col className="inner-custom-pagination d-flex">
                                                                      <div className="d-inline">
                                                                          <PaginationTotalStandalone
                                                                              {...paginationProps}
                                                                          />
                                                                      </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                      <div className="text-md-center d-inline " style={{ marginLeft: '5%' }} >
                                                                          <span>Show Rows : </span> &nbsp;&nbsp;
                                                                          <SizePerPageDropdownStandalone
                                                                              {...paginationProps}
                                                                          />
                                                                      </div>
                                                                      <div className="text-md-right ms-auto">
                                                                          <PaginationListStandalone
                                                                              {...paginationProps}
                                                                          />
                                                                      </div>
                                                                  </Col>
                                                              </Row>
                                                          </React.Fragment>
                                                      )}
                                                  </ToolkitProvider>
                                              );
                                          }}
                                      </PaginationProvider>
                                  </CardBody>
                              </Card>
                          </Col>
                      </Row>
                  </Container>
      </React.Fragment>
  )
}

ReportQueryHistory.propTypes = {}

export default ReportQueryHistory