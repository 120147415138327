/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import axios from "axios";
import SweetAlert from 'react-bootstrap-sweetalert';


import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Modal,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap"

import classnames from "classnames";

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone, PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import cellEditFactory , { Type } from 'react-bootstrap-table2-editor';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DetachHISbyAdUnit from "./DetachHISbyAdUnit";

var selectedItem = []
var selectedIndex = []

var highImpactSettingsData = []

const DetachHighImpact = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [highImpactSettings, setHighImpactSettings] = useState([])
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [uploadModal, setUploadModal] = useState(false);
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [selected, setSelected] = useState([])
    const [deleteState,setDeleteState] = useState(false)
    const [selectedData,setSelectedData] = useState('')
    const [selectedAdUnit, setSelectedAdUnit] = useState({})
    const [selectedAdUnitState, setSelectedAdUnitState] = useState(false);
    // const [highImpactSettingsData,setHighImpactSettingsData] = useState([])

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };


      const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
      }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }



    useEffect(() => {
        getHighImpactSettings()
    }, []);

    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        setFromDate(event.startDate._d.toISOString());
        setToDate(event.endDate._d.toISOString());
    }



    const getHighImpactSettings = (event) => {
        const body = {
            "url": (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') ? 'http://127.0.0.1:3006/v1.0/Supply/HighImpactSettings/read/forDetachment' : "http://127.0.0.1:2002/v1.0/HighImpactSettings/read/forDetachment" ,
            "requestBody": {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                name:userInfo.name,
            }
        }
        if(userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency'){
            body.requestBody = {...body.requestBody,partnerName:userInfo.name,partnerID:userInfo.userID}
        }
        axios.post(userInfo.userRole === 'Partner' ? "/v1.0/connectPartner" :userInfo.userRole === 'Agency' ? "/v1.0/connectPartner" : "/v1.0/connectOP", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.responseData.data) {
                    res.data.responseData.data.map((item, index) => {
                        item.id = index + 1
                    })
                    setHighImpactSettings([...res.data.responseData.data])
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    const closeHighImpact = (event) => {
        console.log(event)
        if(event === 'Update'){
            toastr.success('High Impact Settings are Datched Successfully..')
        }
        setSelectedAdUnitState(false)
        getHighImpactSettings()
    }


    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const adUnitListColoumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "AdUnit ID",
            dataField: "adUnitID",
            sort: true,
        },
        {
            text: "AdUnit",
            dataField: "aUnitName",
            sort: true,
        },
        {
            text: "High Impact Presents",
            dataField: "",
            sort: true,
            align: 'center',
            headerAlign: 'center',
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, highImpact) => (
              <>
                  <span className="">
                    {highImpact.highImpactData.length}
                  </span>
              </>
            ),
          },
  
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
      
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, adUnit) => (
              <div className="d-flex gap-3">
                      <Link  to={'#'} onClick={() => { tog_standard(adUnit); }} >
                      <u>View HIS</u> 
                    </Link>
              </div>
            ),
          },
    ];

    function tog_standard(adUnit) {
        console.log(adUnit)
        if (adUnit !== '') {
          setSelectedAdUnit(adUnit)
          if(adUnit.highImpactData.length > 0){
            adUnit.highImpactData.map((item,index)=>{
                item.id = index + 1
            })
          }
          console.log(adUnit.highImpactData)
          highImpactSettingsData = adUnit.highImpactData
          console.log(highImpactSettingsData)
          setSelectedAdUnitState(true)
        } else {
            setSelectedAdUnitState(false)
        }
      }
  

 
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const { SearchBar } = Search;
    const pageOptions = {
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        totalSize: highImpactSettings.length,
        custom: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };


    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    return (
        <React.Fragment >
                    <Container fluid={true}>
                        {selectedAdUnitState ?  <DetachHISbyAdUnit selectedAdUnit={selectedAdUnit} closeDetach={closeHighImpact} /> :   <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField={keyField}
                                            columns={adUnitListColoumns}
                                            data={highImpactSettings}
                                        >
                                            {({ paginationProps, paginationTableProps }) => {
                                                return (
                                                    <ToolkitProvider
                                                        keyField={keyField}
                                                        data={highImpactSettings}
                                                        columns={adUnitListColoumns}
                                                        // bootstrap4
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row className="mb-2">
                                                                    <Col sm="3">
                                                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                                                            <div className="position-relative">
                                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                                <i className="bx bx-search-alt search-icon" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                <Col xl="12">
                                                                    {selectedRowsStatus && selectedIndex.length > 0 &&
                                                                        <div className="row m-0 React-Table-Selection">
                                                                        {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                                                        <div style={{ padding: '0.5% 2%' }}>

                                                                            {/* <button color="primary" className="btn btn-primary " onClick={statusAdvertiser(true)}>Activate</button> */}
                                                                            {/* <button color="primary" className="btn btn-primary " onClick={statusAdvertiser(true)}>
                                                                            Activate
                                                                            </button> */}
                                                                            &nbsp;  &nbsp;
                                                                            <button color="primary" className="btn btn-primary " onClick={()=> setDeleteState(true)} >
                                                                            Delete Entry
                                                                            </button>
                                                                            &nbsp;  &nbsp;
                                                                            {/* <Button color="primary" className="btn btn-primary " onClick={archiveAdvertiser} >
                                                                            Archive
                                                                            </Button> */}
                                                                            <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                                            className="mdi mdi-close-thick font-size-18"
                                                                            ></i></Button>
                                                                        </div>
                                                                        </div>
                                                                    }
                                                                    </Col>
                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            <BootstrapTable
                                                                                keyField={keyField}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                // selectRow={selectRow}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                                noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                            // ref={node}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">
                                                                            <PaginationTotalStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        <div className="text-md-center d-inline " style={{ marginLeft: '5%' }} >
                                                                            <span>Show Rows : </span> &nbsp;&nbsp;
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                );
                                            }}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> 
                       
                        }
                     
                    </Container>
                        {/* <SweetAlert
                            warning
                            showCancel
                            confirmBtnText="Yes, Detach it!"
                            confirmBtnBsStyle="danger"
                            title="Are you sure?"
                            onConfirm={detachAdUnit}
                            onCancel={()=> setDeleteState(false)}
                            show={deleteState}
                            focusCancelBtn
                            >
                            You will not be able to recover !
                        </SweetAlert> */}
 
        </React.Fragment >
    )
}

DetachHighImpact.propTypes = {}

export default DetachHighImpact