/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Row, Col, Input, Label, Form, FormFeedback, DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown, Spinner
} from "reactstrap";
import Select from "react-select";
import axios from "axios";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewPublisher as onADDPublisher,
  updatePublisher as onUpdatePublisher,
  readPublisher as onReadPublisher,
  addPublisherSuccess,
  addPublisherFail,
  updatePublisherSuccess,
  updatePublisherFail,
} from "store/Supply/Publisher/actions";

import {
  getPartners as onGetPartners,
} from "store/PartnerManagement/actions";

import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";


const Create_Publisher = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [publisher, setPublisher] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [partnerData, setPartnerData] = useState([])
  const [selectedPartner, setSelectedPartner] = useState(userInfo.userRole === 'Partner' ? { value: userInfo.userID, label: userInfo.name } : null);
  const nextRef = useRef();
  const [nextTab, setNextTab] = useState(false);
  const [btnprimary1, setBtnprimary1] = useState(false)
  const [adUnitGroupType, setAdUnitGroupType] = useState('');
  const [loader, setLoader] = useState(false);
  console.log(props)

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  useEffect(() => {
    dispatch(onGetPartners(null));
  }, []);

  useEffect(() => {
    if (props.selectedPublisher !== '') {
      setIsEdit(true)
      setPublisher(props.selectedPublisher)
      console.log(props.selectedPublisher)
      getParentPublisher()
    }
    if (props.selectedPartner !== '' && props.selectedPublisher === '') {
      setPublisher({ partnerID: props.selectedPartner.partnerId })
      console.log(publisher)
      setSelectedPartner({ value: props.selectedPartner.partnerId, label: props.selectedPartner.partnerName });
    }
  }, []);

  const getParentPublisher = () => {
    var filterBody = {
      url:(userInfo.userRole === 'Partner'|| userInfo.userRole === 'Agency') ? 'http://127.0.0.1:3006/v1.0/supply/getParent' : "http://127.0.0.1:2002/v1.0/supply/getParent",
      requestBody: {
        "partnerID": 0,
        "publisherID": 0,
        "adUnitGroupID": 0,
        "adUnitID": 0,
        "childAdUnitID": 0,
        "playerSettingID": 0,
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name:userInfo.name,
      }
    }
    if(userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency'){
      filterBody.requestBody = {...filterBody.requestBody,partnerName:userInfo.name,partnerID:userInfo.userID}
  }
    if (props.selectedPublisher !== '') {
      filterBody.requestBody.publisherID = props.selectedPublisher.publisherID
    }
    axios.post(userInfo.userRole === 'Partner' ? "/v1.0/connectPartner" :userInfo.userRole === 'Agency' ? "/v1.0/connectPartner" : "/v1.0/connectOP", filterBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("cdsresult is ==>", res)
        if (res.data.responseData) {
          setSelectedPartner({ value: res.data.responseData.partnerID, label: res.data.responseData.username });
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      publisherName: (publisher && publisher.publisherName) || '',
      partnerID: (selectedPartner ? selectedPartner.value : '') || '',
      publisherSite: (publisher && publisher.publisherSite) || '',
    },
    validationSchema: Yup.object({
      publisherName: Yup.string().required("Publisher Name is required"),
      partnerID: Yup.string().required("Partner is required"),
      publisherSite: Yup.string().required("Publisher Site is required"),
    }),
    onSubmit: (values) => {
      setLoader(true)
      if (props.selectedPublisher !== '') {
        dispatch(onUpdatePublisher({ ...values, publisherID: props.selectedPublisher.publisherID,publisherName:values.publisherName.trim(),publisherSite:values.publisherSite.trim(),userRole:userInfo.userRole,userID:userInfo.userID }));
      } else {
        dispatch(onADDPublisher({ ...values,publisherName:values.publisherName.trim(),publisherSite:values.publisherSite.trim(),userRole:userInfo.userRole,userID:userInfo.userID }));
      }
    }
  });

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.publishers.errorMsg,
    successMsg: state.publishers.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addPublisherSuccess(""));
        dispatch(addPublisherFail(""));
        dispatch(updatePublisherSuccess(""));
        dispatch(updatePublisherFail(""));
        setLoader(false)
        if (nextTab) {
          props.closeCanvas('')
          nextRef.current.click()
        } else {
          if (props.selectedPublisher !== '') {
            props.closeCanvas('Update')
          } else {
            props.closeCanvas('Create')
          }
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if(errorMsg.status && errorMsg.status === 500){
      }else{
        dispatch(addPublisherSuccess(""));
        dispatch(addPublisherFail(""));
        dispatch(updatePublisherSuccess(""));
        dispatch(updatePublisherFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addPublisherSuccess(""));
    dispatch(addPublisherFail(""));
    dispatch(updatePublisherSuccess(""));
    dispatch(updatePublisherFail(""));
        props.closeCanvas('')
  };

  function handleSelectPartner(selectPartner) {
    console.log(selectPartner)
    setSelectedPartner(selectPartner);
  }

  const { partners } = useSelector(state => ({
    partners: state.partners.partners,
  }));

  console.log(partners)

  useEffect(() => {
    if (partners !== null) {
      dispatch(onGetPartners({filter:'Active'}));
    }
  }, []);

  useEffect(() => {
    if (partners && partners.length > 0) {
      setPartnerData(objectModal(partners))
      console.log(partners)
    }
  }, [partners]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.partnerID
      obj.label = item.userName
      dataList.push(obj)
    })
    return dataList
  }

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if ( !(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault();}
  };




  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>

        <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
          <Row style={{ padding: '3%' }}>
            <Col xs={12}>
            {userInfo.userRole !== 'Partner' && 
              <div className="mb-3">
              <Label>Partner <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
              <Select
                value={selectedPartner}
                onChange={s => {
                  handleSelectPartner(s)
                }}
                options={partnerData}
                invalid={
                  validation.touched.partnerID && validation.errors.partnerID ? true : false
                }
               
                isDisabled={props.selectedArchived || isEdit}
                className={ (validation.touched.partnerID && validation.errors.partnerID ? ' is-invalid' : '') }
                classNamePrefix="select2-selection"
              />
             
              {validation.touched.partnerID && validation.errors.partnerID ? (
                <FormFeedback type="invalid">{validation.errors.partnerID}</FormFeedback>
              ) : null}

            </div>
            }
              <div className="mb-3">
                <Label className="form-label">Publisher Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  name="publisherName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  onKeyPress={allowAlphaNumericSpace}
                  maxLength={65}
                  value={validation.values.publisherName || ""}
                  invalid={
                    validation.touched.publisherName && validation.errors.publisherName ? true : false
                  }
                  readOnly={props.selectedArchived }
                />
                {validation.touched.publisherName && validation.errors.publisherName ? (
                  <FormFeedback type="invalid">{validation.errors.publisherName}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Publisher Site <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  name="publisherSite"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.publisherSite || ""}
                  invalid={
                    validation.touched.publisherSite && validation.errors.publisherSite ? true : false
                  }
                  readOnly={props.selectedArchived}
                />
                {validation.touched.publisherSite && validation.errors.publisherSite ? (
                  <FormFeedback type="invalid">{validation.errors.publisherSite}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
            <Col>
              <div className="text-start">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;
                {!isEdit &&
                  <div className="btn-group">
                    <Dropdown
                      isOpen={btnprimary1}
                      toggle={() => setBtnprimary1(!btnprimary1)}
                    >
                      <DropdownToggle tag="button" type="button" className="btn btn-primary">
                        <i className="mdi mdi-plus-circle-outline me-1" />Save & Add Ad Unit Group &nbsp; <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
           
                      <DropdownMenu>
                        {/* <DropdownItem header>Display</DropdownItem> */}
                        <button type="submit" onClick={() => { setNextTab(true); setAdUnitGroupType('stdb') }} style={{ border: 'none', background: 'none', margin: '0 15% 3%' }}>Standard</button>
                        <br />
                        <button type="submit" onClick={() => { setNextTab(true); setAdUnitGroupType('highImpact') }} style={{ border: 'none', background: 'none', margin: '0 15% 3%' }}>High Impact</button>
                        <br />
                        <button type="submit" onClick={() => { setNextTab(true); setAdUnitGroupType('video') }} style={{ border: 'none', background: 'none', margin: '0 15% 3%' }}>Video</button>
                        {/* <DropdownItem divider />
                        <DropdownItem header>Video</DropdownItem>
                        <button type="submit" onClick={() => { setNextTab(true); setAdUnitGroupType('play') }} style={{ border: 'none', background: 'none', margin: '0 15% 3%' }}>Video</button>
                        <br />
                        <button type="submit" onClick={() => { setNextTab(true); setAdUnitGroupType('vast') }} style={{ border: 'none', background: 'none', margin: '0 15% 3%' }} disabled>Vast</button> */}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                }
                {nextTab && successMsg &&
                  <Link ref={nextRef} to={`/AdUnitGroup?publisherId=${successMsg.data.publisherID}&publisherName=${successMsg.data.publisherName}&adUnitGroupType=${adUnitGroupType}&status=AdUnitGroupCreateByPublisher`}  > </Link>
                }
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
    </React.Fragment>
  );
};

export default withRouter(Create_Publisher);

Create_Publisher.propTypes = {
  history: PropTypes.object,
};