/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback,  Spinner } from "reactstrap";
import {Card, CardBody, Image, Button, Slider} from "@nextui-org/react";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewAdvertiser as onADDAdvertiser,
  updateAdvertiser as onUpdateAdvertiser,
  readAdvertiser as onReadAdvertiser,
  addAdvertiserSuccess,
  addAdvertiserFail,
  updateAdvertiserSuccess, 
  updateAdvertiserFail,
} from "store/Demand/Advertiser/actions";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";


const Create_Advertiser = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [advertiser, setAdvertiser] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const nextRef = useRef();
  const [nextTab, setNextTab] = useState(false);
  const [loader, setLoader] = useState(false);
  // console.log(props)

  const [liked, setLiked] = React.useState(false);


  useEffect(() => {
    if(props.selectedAdvertiser  !== '' ){
      setIsEdit(true)
      setAdvertiser(props.selectedAdvertiser)
    }
}, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      advertiserName: (advertiser && advertiser.advertiserName) || '',
    },
    validationSchema: Yup.object({
      advertiserName: Yup.string().required("Advertiser Name is required"),
    }),
    onSubmit: (values) => {
      setLoader(true)
      if(props.selectedAdvertiser  !== ''){
        dispatch(onUpdateAdvertiser({...values,advertiserID:props.selectedAdvertiser.advertiserID,advertiserName:values.advertiserName.trim(),userRole:userInfo.userRole,userID:userInfo.userID}));
      }else{
        dispatch(onADDAdvertiser({...values,advertiserName:values.advertiserName.trim(),userRole:userInfo.userRole,userID:userInfo.userID}));
      }
    }
  });

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.advertisers.errorMsg,
    successMsg: state.advertisers.successMsg,
  }));

    useEffect(() => {
    if(successMsg !== null){
       if(successMsg.success){
        dispatch(addAdvertiserSuccess(""));
        dispatch(addAdvertiserFail(""));
        dispatch(updateAdvertiserSuccess(""));
        dispatch(updateAdvertiserFail(""));
        setLoader(false)
        if(nextTab){
          props.closeCanvas('')
          nextRef.current.click()
        }else{
          if(props.selectedAdvertiser  !== ''){
            props.closeCanvas('Update')
          }else{
            props.closeCanvas('Create')
          }
        }
       }
   }
   }, [ successMsg]);

   useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if(errorMsg.status && errorMsg.status === 500){
      }else{
        dispatch(addAdvertiserFail(""));
        dispatch(updateAdvertiserFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
        dispatch(addAdvertiserFail(""));
        dispatch(updateAdvertiserFail(""));
        props.closeCanvas('')
  };

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if ( !(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault();}
  };

  return (
    <React.Fragment>
      {loader &&
              <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{pointerEvents:(loader) ? 'none' : 'unset'}}>
        <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
          <Row  style={{ padding: '3%' }}>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Advertiser Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  name="advertiserName"
                  type="text"
                  maxLength={65}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  onKeyPress={allowAlphaNumericSpace}
                  value={validation.values.advertiserName || ""}
                  invalid={
                    validation.touched.advertiserName && validation.errors.advertiserName ? true : false
                  }
                  readOnly={props.selectedArchived}
                />
                {validation.touched.advertiserName && validation.errors.advertiserName ? (
                  <FormFeedback type="invalid">{validation.errors.advertiserName}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>


          <Card
      isBlurred
      className="border-none bg-background/60 dark:bg-default-100/50 max-w-[610px]"
      shadow="sm"
    >
      <CardBody>
        <div className="grid grid-cols-6 md:grid-cols-12 gap-6 md:gap-4 items-center justify-center">
          <div className="relative col-span-6 md:col-span-4">
            <Image
              alt="Album cover"
              className="object-cover"
              height={200}
              shadow="md"
              src="https://nextui.org/images/album-cover.png"
              width="100%"
            />
          </div>

          <div className="flex flex-col col-span-6 md:col-span-8">
            <div className="flex justify-between items-start">
              <div className="flex flex-col gap-0">
                <h3 className="font-semibold text-foreground/90">Daily Mix</h3>
                <p className="text-small text-foreground/80">12 Tracks</p>
                <h1 className="text-large font-medium mt-2">Frontend Radio</h1>
              </div>
              <Button
                isIconOnly
                className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
                radius="full"
                variant="light"
                onPress={() => setLiked((v) => !v)}
              >
              </Button>
            </div>

            <div className="flex flex-col mt-3 gap-1">
              <Slider
                aria-label="Music progress"
                classNames={{
                  track: "bg-default-500/30",
                  thumb: "w-2 h-2 after:w-2 after:h-2 after:bg-foreground",
                }}
                color="foreground"
                defaultValue={33}
                size="sm"
              />
              <div className="flex justify-between">
                <p className="text-small">1:23</p>
                <p className="text-small text-foreground/50">4:32</p>
              </div>
            </div>

            <div className="flex w-full items-center justify-center">
              <Button
                isIconOnly
                className="data-[hover]:bg-foreground/10"
                radius="full"
                variant="light"
              >
              </Button>
              <Button
                isIconOnly
                className="data-[hover]:bg-foreground/10"
                radius="full"
                variant="light"
              >
              </Button>
              <Button
                isIconOnly
                className="w-auto h-auto data-[hover]:bg-foreground/10"
                radius="full"
                variant="light"
              >
              </Button>
              <Button
                isIconOnly
                className="data-[hover]:bg-foreground/10"
                radius="full"
                variant="light"
              >
              </Button>
              <Button
                isIconOnly
                className="data-[hover]:bg-foreground/10"
                radius="full"
                variant="light"
              >
              </Button>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
          <Row className="drawer-footer"  style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
            <Col>
              <div className="text-start">
              &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {!isEdit && 
                  <button
                  type="submit"
                  className="btn btn-primary save-user"
                >
                  Save
                </button>
              }
                  &nbsp;&nbsp;
              {!isEdit && 
                   <button
                   type="submit"
                   className="btn btn-primary save-user"
                   onClick={() => setNextTab(true)}
                 >
                   Save & Add IO Group
                 </button>
              }
          
              {isEdit && !props.selectedArchived &&
                 <button
                 type="submit"
                 className="btn btn-primary save-user"
               >
                Update
               </button>
              }
                   {nextTab && successMsg &&
                    <Link ref={nextRef} to={`/Insertion_Order_Group?advertiserId=${successMsg.data.advertiserID}&advertiserName=${successMsg.data.advertiserName}&status=IOGroupCreateByAdvertiser`}  > </Link>
                  }
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
  
    </React.Fragment>
  );
};

export default withRouter(Create_Advertiser);

Create_Advertiser.propTypes = {
  history: PropTypes.object,
};