/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import Select from "react-select";
import axios from "axios";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Modal,
    Input,
    Spinner,
    Label, Form,
} from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import moment from "moment";

import excelFile from 'assets/files/AdsTxtFile.xlsx'
import PDFFile from 'assets/files/AdsTxtEntries.pdf'

import {
    getProxyAdUnitsReports as onGetProxyAdUnitsReports,
} from "store/Proxy/ProxyAdUnits/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1

var fromDate =  moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
var toDate =  moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')
// var loader = true

const CustomReport = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [reportOpen, setReportOpen] = useState(false);
    const [btnprimary1, setBtnprimary1] = useState(false)
    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [requestTotal, setRequestTotal] = useState(0)
    const [clicksTotal, setClicksTotal] = useState(0)

    const [selectAll, setSelectAll] = useState(false)
    const [selectAllMTC, setSelectAllMTC] = useState(false)
    const [selectMetrice, setSelectMetrice] = useState(false)
    const [range, setRange] = useState('daily')

  
    const [Publisher, setPublisher] = useState([]);
    const [AdUnitName, setAdUnitName] = useState([]);
    const [AdUnitSize, setAdUnitSize] = useState([]);
    const [Creative, setCreative] = useState([]);
    const [Category, setCategory] = useState([]);

 
    const [selectedPublisher, setSelectedPublisher] = useState(null);
    const [selectedAdUnitName, setSelectedAdUnitName] = useState(null);
    const [selectedAdUnitSize, setSelectedAdUnitSize] = useState(null);
    const [selectedCreative, setSelectedCreative] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [selectAllDM, setSelectAllDM] = useState({
        name: false,
        size: false,
        category: false,
        creative: false,
        publisher: false
    });

    const [selectedMTC, setSelectedMTC] = useState({
        requests: false,
        clicks: false,
    });

    const [filters, setFilters] = useState({
        name: '',
        size: '',
        category: '',
        creative: '',
        publisher: ''
    });

    const [filterData, setFilterData] = useState({
        name: [],
        size: [],
        category: [],
        creative: [],
        publisher: []
    })


    const handleSelectPublisher = publisher => {
        setSelectedPublisher(publisher)
        setFilters(filters => ({ ...filters, publisher: publisher.value }));
        changeData('Publisher',publisher.value)
      }
    
      const handleSelectAdUnitName = adUnitName => {
        setSelectedAdUnitName(adUnitName)
        setFilters(filters => ({ ...filters, name: adUnitName.value }));
        changeData('AdUnitName',adUnitName.value)
      }
      
      const handleSelectAdUnitSize = adUnitSize => {
        setSelectedAdUnitSize(adUnitSize)
        setFilters(filters => ({ ...filters, size: adUnitSize.value }));
        changeData('AdUnitSize',adUnitSize.value)
      }
    
      const handleSelectCreative = creative => {
        setSelectedCreative(creative)
        setFilters(filters => ({ ...filters, creative: creative.value }));
        changeData('Creative',creative.value)
      }
    
      const handleSelectCategory = category => {
        setSelectedCategory(category)
        setFilters(filters => ({ ...filters, category: category.value }));
        changeData('Category',category.value)
      }

    const changeFilter = (e) => {
        const { name, value } = e.target;
        setFilters(filters => ({ ...filters, [name]: value }));
        changeData(e)
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name:userInfo.name,
        }
    }

    useEffect(() => {

        fromDate =  moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
        toDate =  moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')

        body.url = "http://127.0.0.1:4012/v1.0/Fsrv/filter"
        axios.post("/v1.0/connectOP", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.responseData) {
                    objectModalData(res.data.responseData)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }, []);

    const changeData = (name,value) => {
        resetSelectedData(name)
        body.url = "http://127.0.0.1:4012/v1.0/Fsrv/specificFilter"
        body.requestBody = {
            ...body.requestBody,
            "name": name === 'AdUnitName' ? value : '',
            "size": name === 'AdUnitSize' ? value : '',
            "category": name === 'Category' ? value : '',
            "creative": name === 'Creative' ? value : '',
            "publisher": name === 'Publisher' ? value : '',
        }
        axios.post("/v1.0/connectOP", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.responseData) {
                    objectModalData(res.data.responseData)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    };

    const resetSelectedData = (name) => {
        if(name === 'Publisher'){
            setSelectedAdUnitName(null)
            setSelectedAdUnitSize(null)
            setSelectedCreative(null)
            setSelectedCategory(null)
        }
        else if(name === 'AdUnitName'){
            setSelectedAdUnitSize(null)
            setSelectedCreative(null)
            setSelectedCategory(null)
        }
        else if(name === 'AdUnitSize'){
            setSelectedCreative(null)
            setSelectedCategory(null)
        }
        else if(name === 'Creative'){
            setSelectedCategory(null)
        }
        else {
        }
}

    const objectModalData = (response) => {
        if(response.publisher){
            setPublisher(objectModal(response.publisher))
        }
        if(response.name){
            setAdUnitName(objectModal(response.name))
        }
        if(response.size){
            setAdUnitSize(objectModal(response.size))
        }
        if(response.category){
            setCategory(objectModal(response.category))
        }
        if(response.creative){
            setCreative(objectModal(response.creative))
        }
      }

      const objectModal = (response) => {
        console.log
        var dataList = []
        response.map((item, index) => {
          let obj = {}
          obj.value = item
          obj.label = item
          dataList.push(obj)
        })
        return dataList
      }

    const changeDMSelect = (e) => {
        setSelectAll(e.target.checked)
        setSelectAllDM({
            name: e.target.checked,
            size: e.target.checked,
            category: e.target.checked,
            creative: e.target.checked,
            publisher: e.target.checked
        })
    }

    const changeMTCSelect = (e) => {
        setSelectAllMTC(e.target.checked)
        setSelectedMTC({
            requests: e.target.checked,
            clicks: e.target.checked,
        })
    }

    const changeSelection = (e) => {
        const { name, value } = e.target;
        setSelectAllDM(selectAllDM => ({ ...selectAllDM, [name]: e.target.checked }));
        let newDim = { ...selectAllDM, [name]: e.target.checked }
        let DimArr = Object.values(newDim)
        console.log(DimArr)
        if (!DimArr.includes(false)) {
            setSelectAll(true)
        } else {
            setSelectAll(false)
        }
    }

    const changeSelectionMTC = (e) => {
        const { name, value } = e.target;
        setSelectedMTC(selectedMTC => ({ ...selectedMTC, [name]: e.target.checked }));
        let newMTC = { ...selectedMTC, [name]: e.target.checked }
        let MtcArr = Object.values(newMTC)
        if (!MtcArr.includes(false)) {
            setSelectAllMTC(true)
        } else {
            setSelectAllMTC(false)
        }
    }

    const changeRange = (e) => {
        setRange(e.target.value)
    }

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.proxyAdUnit.errorMsg,
        successMsg: state.proxyAdUnit.successMsg,
    }));

    console.log(errorMsg, successMsg)

    const { houseAdUnitsReports } = useSelector(state => ({
        houseAdUnitsReports: state.proxyAdUnit.proxyAdURpt,
    }));

    const { loading } = useSelector(state => ({
        loading: state.proxyAdUnit.loading,
      }));

    console.log(houseAdUnitsReports)

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const houseAdUnitsReportsColumns = [
        {
            text: "No",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
            footer: ''
        },
    ];

    const createColoumn = () => {

    }

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} entries
      </span>
    );
  
  
    const pageOptions = {
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "requests", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        let DimArr = Object.values(selectAllDM)
        let MtcArr = Object.values(selectedMTC)
        if ( !MtcArr.includes(true)) {
            toastr.warning('Please select atleast one Metrices')
        }
        else {

            let query = {
                "dimensions": selectAllDM,
                "startDate": moment(fromDate).format("YYYY-MM-DD"),
                "endDate": moment(toDate).format("YYYY-MM-DD"),
                "filters": filters,
                "metrices": selectedMTC,
                "range": range,
                "type": 'houseAd'
            }
            console.log(query)
            dispatch(onGetProxyAdUnitsReports({ ...query }));
            setReportOpen(true)
        }
    }

    if (houseAdUnitsReports.length > 0) {
        let keys = [];
        for (var k in houseAdUnitsReports[0]) keys.push(k);
        console.log(keys)
        if(keys.includes("hour")){
            houseAdUnitsReports.map((item, index) => {
                item.hour = parseInt(item.hour)
            })
        }
        if (keys.includes("date")) {
            houseAdUnitsReportsColumns.push({
                dataField: "date",
                text: "Date",
                sort: true,
                footer: 'Total'
            })
        }
        if (keys.includes("month")) {
            houseAdUnitsReportsColumns.push({
                dataField: "month",
                text: "Month",
                sort: true,
                footer: 'Total'
            })
        }
        if (keys.includes("hour")) {
            houseAdUnitsReportsColumns.push({
                dataField: "hour",
                text: "Hour",
                sort: true,
                footer: ''
            })
        }
        if (keys.includes("ad_unit_name")) {
            houseAdUnitsReportsColumns.push({
                dataField: "ad_unit_name",
                text: "House Ad Unit",
                sort: true,
                footer: '',
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("ad_unit_size")) {
            houseAdUnitsReportsColumns.push({
                dataField: "ad_unit_size",
                text: "Size",
                sort: true,
                footer: ''
            })
        }
        if (keys.includes("category_name")) {
            houseAdUnitsReportsColumns.push({
                dataField: "category_name",
                text: "Category",
                sort: true,
                footer: '',
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("creative_name")) {
            houseAdUnitsReportsColumns.push({
                dataField: "creative_name",
                text: "Creative ",
                sort: true,
                footer: '',
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("publisher_name")) {
            houseAdUnitsReportsColumns.push({
                dataField: "publisher_name",
                text: "Publisher",
                sort: true,
                footer: '',
                style:{wordBreak: 'break-word'}
            })
        }
        if (keys.includes("requests")) {
            houseAdUnitsReportsColumns.push({
                dataField: "requests",
                text: "Requests",
                sort: true,
                footer: `${requestTotal}`
            })
        }
        if (keys.includes("clicks")) {
            houseAdUnitsReportsColumns.push({
                dataField: "clicks",
                text: "Clicks",
                sort: true,
                footer: `${clicksTotal}`
            })
        }
        console.log(houseAdUnitsReportsColumns)
    } 

    useEffect(() => {
        console.log(houseAdUnitsReports)
        if (houseAdUnitsReports !== null && houseAdUnitsReports.length > 0) {
            let totalRequest = 0
            let totalClicks = 0
            houseAdUnitsReports.map((item, index) => {
                totalRequest = totalRequest + item.requests
                totalClicks = totalClicks + item.clicks
            })
            setRequestTotal(totalRequest)
            setClicksTotal(totalClicks)
        }

    }, [houseAdUnitsReports]);

    var CSVProp
    const CSVClick = () => {
        CSVProp.onExport();
    }

    const MyExportCSV = (csvprops) => {
        CSVProp = csvprops
        return (<></>);
    };

    const [state, setState] = useState({
        start: moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'),
        end: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
      });
      
      const { start, end } = state;
      
      const handleEvent = (startDate, endDate,label) => {
        console.log(startDate,endDate)
        setState({ start: startDate, end: endDate,});
        fromDate = startDate._d.toISOString();
        toDate = endDate._d.toISOString();
      };
      
       const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
                <div className="page-content">
                    <MetaTags>
                        <title>House Ads Report | Bidsxchange</title>
                    </MetaTags>
                    {!reportOpen &&
                        <Container fluid>
                            {/* Render Breadcrumbs */}
                            <div className=" d-sm-flex  justify-content-between">
                                <SupplyBreadCrumb title="House Ad" breadcrumbItem="House Ad Report Query " />
                            </div>
                            <Row>
                                <div className="col-12">
                                    <Card>
                                        <CardBody>
                                            <Form onSubmit={handleSubmit}>
                                                <Row style={{ padding: '1% 3%', }}>
                                                    <Col xs={4} >
                                                        <div className="mb-3">
                                                            <h5>SELECT DATE</h5> <br />
                                                            <DateRangePicker
                                                                initialSettings={{
                                                                opens: 'left',
                                                                startDate: start.toDate(),
                                                                endDate: end.toDate(),
                                                                maxDate: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
                                                                ranges: {
                                                                    Today: [ moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
                                                                    moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),],
                                                                    Yesterday: [
                                                                        moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                        moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                        ],
                                                                        'Last 7 Days': [moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                        'Last 30 Days': [moment().subtract(estCurrentDate > 1 ? 31 : 30, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                                    'Last Month': [
                                                                    moment().subtract(1, 'month').startOf('month'),
                                                                    moment().subtract(1, 'month').endOf('month'),
                                                                    ],
                                                                },
                                                                }}
                                                                onCallback={handleEvent}
                                                                >
                                                                <button className='datePicker' type="button">
                                                                <i  className="bx bx-calendar font-size-18 " id="edittooltip" style={{margin:'3% 3% 2%'}} ></i>{labelDate}
                                                                </button>
                                                                </DateRangePicker>
                                                        </div>
                                                        <br />
                                                        <h5>FILTERS</h5>
                                                        <div className="mb-3">
                                                            <Label className="form-label">Publishers</Label>
                                                            <Select
                                                                value={selectedPublisher}
                                                                onChange={s => {
                                                                    handleSelectPublisher(s)
                                                                }}
                                                                options={Publisher}
                                                                classNamePrefix="select2-selection"
                                                                />

                                                        </div>
                                                        <div className="mb-3">
                                                            <Label className="form-label">Ad Unit </Label>
                                                            <Select
                                                                value={selectedAdUnitName}
                                                                onChange={s => {
                                                                    handleSelectAdUnitName(s)
                                                                }}
                                                                options={AdUnitName}
                                                                classNamePrefix="select2-selection"
                                                                />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label> Ad Unit Size</Label>
                                                            <Select
                                                                value={selectedAdUnitSize}
                                                                onChange={s => {
                                                                    handleSelectAdUnitSize(s)
                                                                }}
                                                                options={AdUnitSize}
                                                                classNamePrefix="select2-selection"
                                                                />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label className="form-label">Creative</Label>
                                                            <Select
                                                                value={selectedCreative}
                                                                onChange={s => {
                                                                    handleSelectCreative(s)
                                                                }}
                                                                options={Creative}
                                                                classNamePrefix="select2-selection"
                                                                />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label className="form-label">Creative Category</Label>
                                                            <Select
                                                                value={selectedCategory}
                                                                onChange={s => {
                                                                    handleSelectCategory(s)
                                                                }}
                                                                options={Category}
                                                                classNamePrefix="select2-selection"
                                                                />
                                                        </div>
                                                    </Col>
                                                    <Col xs={4} style={{ padding: '0% 3%', }}>

                                                        <div className="mb-3">
                                                            <h5> SELECT RANGE</h5><br />
                                                            <Input
                                                                name="range"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={changeRange}
                                                                value={range}
                                                            >
                                                                <option value='' disabled>Select</option>
                                                                <option value='alltime'>All Time</option>
                                                                <option value='monthly'>Monthly</option>
                                                                <option value='daily'>Daily</option>
                                                                <option value='hourly'>Hourly</option>
                                                            </Input>
                                                        </div>
                                                        <br />
                                                        <h5 className="mb-3 ">DIMENSIONS</h5>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectAll}
                                                                onChange={changeDMSelect}
                                                                id="selectall"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="selectall"
                                                            >
                                                                Select All
                                                            </label>
                                                        </div>
                                                        {/* <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectAllDM.partner}
                                                                name="partner"
                                                                onChange={changeSelection}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                            >
                                                                Partners
                                                            </label>
                                                        </div> */}
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectAllDM.publisher}
                                                                name="publisher"
                                                                onChange={changeSelection}
                                                                id="publishers"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="publishers"
                                                            >
                                                                Publishers
                                                            </label>
                                                        </div>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectAllDM.name}
                                                                name="name"
                                                                onChange={changeSelection}
                                                                id="adunit"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="adunit"
                                                            >
                                                                Ad Unit
                                                            </label>
                                                        </div>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectAllDM.size}
                                                                name="size"
                                                                onChange={changeSelection}
                                                                id="adsize"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="adsize"
                                                            >
                                                                Ad Size
                                                            </label>
                                                        </div>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectAllDM.creative}
                                                                name="creative"
                                                                onChange={changeSelection}
                                                                id="creative"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="creative"
                                                            >
                                                                Creative
                                                            </label>
                                                        </div>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectAllDM.category}
                                                                name="category"
                                                                onChange={changeSelection}
                                                                id="creativeCategory"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="creativeCategory"
                                                            >
                                                                Creative Category
                                                            </label>
                                                        </div>

                                                    </Col>
                                                    <Col xs={4} style={{ padding: '0 3%', }}>
                                                        <div className="mb-3">
                                                            <h5> SELECT Time Zone</h5><br />
                                                            <Input
                                                                name="timeZone"
                                                                type="select"
                                                                className="form-select"
                                                                defaultValue={'EST'}
                                                            >
                                                                <option value='' disabled>Select</option>
                                                                <option value='EST'>EST</option>
                                                            </Input>
                                                        </div>
                                                        <br />
                                                        <h5 className="mb-3">METRICES</h5>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectAllMTC}
                                                                onChange={changeMTCSelect}
                                                                id="selectAllMetrices"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="selectAllMetrices"
                                                            >
                                                                Select All
                                                            </label>
                                                        </div>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectedMTC.requests}
                                                                name="requests"
                                                                onChange={changeSelectionMTC}
                                                                id="request"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="request"
                                                            >
                                                                Request
                                                            </label>
                                                        </div>
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectedMTC.clicks}
                                                                name="clicks"
                                                                onChange={changeSelectionMTC}
                                                                id="clicks"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="clicks"
                                                            >
                                                                Clicks
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className=" text-sm-end">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary save-user"
                                                            >
                                                                Run Report
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Row>
                        </Container>
                    }
                    {reportOpen &&
                        <Container fluid={true}>
                            <div className=" d-sm-flex  justify-content-between">
                                <SupplyBreadCrumb title="House Ad" breadcrumbItem="HouseAd Report " />
                                <div className=" text-sm-end">
                                    <div className="btn-group">
                                        <Dropdown
                                            isOpen={btnprimary1}
                                            toggle={() => setBtnprimary1(!btnprimary1)}
                                        >
                                            <DropdownToggle tag="button" className="btn btn-primary">
                                                Export
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem >Copy</DropdownItem>
                                                <DropdownItem onClick={CSVClick}>CSV</DropdownItem>
                                                <DropdownItem ><a href={PDFFile} download="House_Ad_Report.pdf">PDF</a></DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        className="font-16 btn-block btn btn-primary"
                                        onClick={(e) => setReportOpen(false)}
                                    >
                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                        Modify Report
                                    </Button>
                                </div>
                            </div>
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody aria-hidden="true">
                                            {loading &&
                                                <>
                                                    <Spinner className="ms-2 loader" color="primary" />
                                                    <br />
                                                    <h5 className="text-center mt-5" style={{ padding: '15%', fontSize: '25px' }}></h5>
                                                    <br />
                                                </>
                                            }
                                            {houseAdUnitsReports.length === 0 && !loading &&
                                                <h5 className="text-center" style={{ padding: '15%', fontSize: '25px' }}>No Data</h5>
                                            }
                                            {houseAdUnitsReports.length > 0 && !loading &&
                                                            <ToolkitProvider
                                                                keyField={keyField}
                                                                data={houseAdUnitsReports}
                                                                columns={houseAdUnitsReportsColumns}
                                                                // bootstrap4
                                                                search
                                                                exportCSV={{
                                                                    ignoreFooter: false
                                                                }}
                                                            >
                                                                {toolkitProps => (
                                                                    <React.Fragment>
                                                                        <Row className="mb-2">
                                                                            <Col sm="8">
                                                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                                                    <div className="position-relative">
                                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                                        <i className="bx bx-search-alt search-icon" />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col sm="1">
                                                                                <MyExportCSV {...toolkitProps.csvProps} />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    <BootstrapTable
                                                                                        keyField={keyField}
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        defaultSorted={defaultSorted}
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={"thead-light"}
                                                                                        {...toolkitProps.baseProps}
                                                                                        pagination={paginationFactory(pageOptions)}
                                                                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                            }

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    }
                </div>
            {/* </div> */}
        </React.Fragment>
    )
}

CustomReport.propTypes = {}

export default CustomReport