/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner, Button } from "reactstrap";
import Select from "react-select";
import axios from "axios";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

const RSSSettings = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [tagList, setTagList] = useState([])

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
    }
  }

  useEffect(() => {
    body.url = "http://127.0.0.1:4009/v1.0/JsonFile/list"
    axios.post("/v1.0/connectOP", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is ==>", res)
        if (res.data.responseData) {
          setTagList(objectModal(res.data.responseData.feeds))
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }, []);

  const objectModal = (response) => {
    console.log(response)
    var dataList = []
    response.map((item, index) => {
        let obj = {}
        obj.feeds = item
        dataList.push(obj)
    })
    return dataList
}

  const tagChange = (index) => (e) => {
    let value = e.target.value
    let tags = [...tagList]
    tags[index].feeds = e.target.value
    console.log(tags)
    setTagList(tags)
  }

  const removeTag = (index) =>(e) => {
    console.log("adfaf")
    let tags  = [...tagList]
    tags.splice(index,1)
    setTagList(tags)
  }

  const addNewTag = () => {
    console.log("click")
    let tags = [...tagList]
    tags.push({feeds:''})
    console.log(tags)
    setTagList(tags)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let finalFeedList = []
    tagList.map((item) => {
      finalFeedList.push(item.feeds)
    })
    let bodyFeed = {
      url: "http://127.0.0.1:4009/v1.0/JsonFile/create",
      requestBody: {
        feeds: finalFeedList,
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
      }
    }
    console.log(bodyFeed)
    axios.post("/v1.0/connectOP", bodyFeed,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is ==>", res)
        if (res.data.responseData) {
          props.closeCanvas('Create')
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  return (
    <React.Fragment>
      <div className="col-12" >

        <Form onSubmit={handleSubmit}>
          <Row className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto', width: '100%' }}>
            <Col xs={6}>
              <div className="mb-3">
                {
                  tagList.map((subItem, i) => {
                    return (
                      <div className="vast-settings" key={i}>
                        <div className="accordion-body">
                          <div >
                            <div className="form-group">
                              <div className="row ">
                                <div className="col-10">
                                  <Input
                                    name="feeds"                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
                                    type="text"
                                    // value={subItem.tag}
                                    // onChange={tagChange(i)}
                                    placeholder={'Add RSS Feed'}
                                    value={subItem.feeds}
                                    onChange={tagChange(i)}
                                  >
                                  </Input>
                                </div>
                                <div className="col-2">
                                  <div className='row form-group '>
                                      <Button type="button" className='btn btn-danger ml-auto ' style={{ marginRight: '1pc', marginTop: '0pc', background: '#dc3545', float: 'right', width: '3pc', }} onClick={removeTag(i)}
                                      >X</Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
                <div className='row'>
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    style={{ margin: '1% 2rem 0% ', width: 'auto' }}
                    onClick={addNewTag}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Add RSS Feed
                  </Button>
                </div>
              </div>

            </Col>
          </Row>
          <Row className="drawer-footer"  style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                <button
                  type="submit"
                  className="btn btn-primary save-user"
                >
                  Submit
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  )
}

RSSSettings.propTypes = {}

export default RSSSettings