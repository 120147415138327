import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ADVERTISERS,ADD_ADVERTISER , UPDATE_ADVERTISER,READ_ADVERTISER,STATUS_ADVERTISER,ARCHIVE_ADVERTISER} from "./actionTypes"

import {
  loadAdvertisers,
  noDataAdvertisers,
  getAdvertisersSuccess,
  getAdvertisersFail,
  addAdvertiserSuccess,
  addAdvertiserFail,
  updateAdvertiserSuccess,
  updateAdvertiserFail,
  readAdvertiserSuccess,
  statusAdvertiserSuccess,
  archiveAdvertiserSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getAdvertisers,postAdvertiser,getAdvertisersType,updateAdvertiser,readAdvertiser,statusChangeAdvertiser,archiveAdvertiser } from "../../../helpers/Backend_helper"

function* fetchAdvertisers({ payload: adv }) {
  try {
    yield put(loadAdvertisers(true))
    var getAdvList
    if(adv && adv.type){
      getAdvList = yield call(getAdvertisersType,adv)
      getAdvList.responseData.response.map((item, index) => {
        item.id = index + 1
    })
    if(getAdvList.responseData.response.length === 0){
      yield put(noDataAdvertisers(true))
    }
      yield put(getAdvertisersSuccess(getAdvList.responseData))
    }else{
      if(adv){
        getAdvList = yield call(getAdvertisers,adv)
        getAdvList.responseData.response.map((item, index) => {
          item.id = index + 1
      })
      if(getAdvList.responseData.response.length === 0){
        yield put(noDataAdvertisers(true))
      }
        yield put(getAdvertisersSuccess(getAdvList.responseData))
      }
      else{
        getAdvList = []
        yield put(getAdvertisersSuccess(getAdvList))
      }
    }

  } catch (error) {
    console.log(error)
    yield put(getAdvertisersFail(error))
  }
}

function* onReadAdvertiser({ payload: adv }) {
  try {
    const response = yield call(readAdvertiser, adv)
    yield put(readAdvertiserSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(readAdvertiserSuccess(error))
  }
}

function* onAddAdvertiser({ payload: adv }) {
  try {
    const response = yield call(postAdvertiser, adv)
    if(response.responseData.success){
      yield put(addAdvertiserSuccess(response.responseData))
    }else{
      yield put(addAdvertiserFail(response.responseData))
    }
  } catch (error) {
    console.log(error)
    yield put(addAdvertiserFail(error))
  }
}

function* onUpdateAdvertiser({ payload: adv }) {
  try {
    const response = yield call(updateAdvertiser, adv)
    if(response.responseData.success){
      yield put(updateAdvertiserSuccess(response.responseData))
    }else{
      yield put(updateAdvertiserFail(response.responseData))
    }
  } catch (error) {
    console.log(error)
    yield put(updateAdvertiserFail(error))
  }
}

function* onStatusAdvertiser({ payload: adv }) {
  try {
    const response = yield call(statusChangeAdvertiser, adv)
    yield put(statusAdvertiserSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(statusAdvertiserSuccess(error))
  }
}

function* onArchiveAdvertiser({ payload: adv }) {
  try {
    const response = yield call(archiveAdvertiser, adv)
    yield put(archiveAdvertiserSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(archiveAdvertiserSuccess(error))
  }
}

function* advertisersSaga() {
  yield takeEvery(GET_ADVERTISERS, fetchAdvertisers)
  yield takeEvery(ADD_ADVERTISER, onAddAdvertiser)
  yield takeEvery(UPDATE_ADVERTISER, onUpdateAdvertiser)
  yield takeEvery(READ_ADVERTISER, onReadAdvertiser)
  yield takeEvery(STATUS_ADVERTISER, onStatusAdvertiser)
  yield takeEvery(ARCHIVE_ADVERTISER, onArchiveAdvertiser)
}

export default advertisersSaga;
