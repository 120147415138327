// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23fff%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offcanvas-header {
  border-bottom: 1px solid #9e9e9e;
}

.radioStyle {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  border-radius: 50%;
  float: left;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
}

.high {
  border-color: #f61414;
  background-color: #f61414;
}

.medium {
  border-color: #e2ce20;
  background-color: #e2ce20;
}

.low {
  border-color: #10973b;
  background-color: #10973b;
}`, "",{"version":3,"sources":["webpack://./src/pages/Demand/Insertion_Order_Group/IOG.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;AACJ;;AAEA;EACI,yDAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,4BAAA;EACA,2BAAA;EACA,wBAAA;EACA,qCAAA;EACA,gBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,yBAAA;AACJ;;AAEE;EACE,qBAAA;EACA,yBAAA;AACJ;;AAEE;EACE,qBAAA;EACA,yBAAA;AACJ","sourcesContent":[".offcanvas-header {\n    border-bottom: 1px solid #9e9e9e;\n}\n\n.radioStyle{\n    background-image:url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e\");\n    border-radius: 50%;\n    float: left;\n    width: 1em;\n    height: 1em;\n    margin-top: 0.25em;\n    vertical-align: top;\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: contain;\n    border: 1px solid rgba(0, 0, 0, 0.25);\n    appearance: none;\n}\n\n.high{\n    border-color: #f61414;\n    background-color: #f61414;\n  }\n\n  .medium{\n    border-color: #e2ce20;\n    background-color: #e2ce20;\n  }\n\n  .low{\n    border-color: #10973b;\n    background-color: #10973b;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
