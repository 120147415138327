/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";
import Select from "react-select";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import _ from "lodash";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewIOs as onADDIOs,
  updateIOs as onUpdateIOs,
  addIOsSuccess,
  addIOsFail,
  updateIOsSuccess,
  updateIOsFail,
} from "store/Demand/IOs/actions";

import {
  getAdvertisers as onGetAdvertisers,
} from "store/Demand/Advertiser/actions";

import {
  getIOGroups as onGetIOGroups,
} from "store/Demand/IOGroup/actions";
import Toaster from "pages/Toaster/Toaster";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ToasterWarning from "pages/Toaster/ToasterWarning";
import Hierarchy_Selection from "Product_Helpers/Hierarchy_Selection";

var cityRetrieved = []
var countriesAll = ["DZ", "AO", "BJ", "BW", "BF", "BI", "CM", "CV", "CF", "TD", "KM", "CG", "CD", "CI", "DJ", "EG", "GQ", "ER", "ET", "GA", "GM", "GH", "GN", "GW", "KE", "LS", "LR", "LY", "MG", "MW", "ML", "MR", "MU", "YT", "MA", "MZ", "NA", "NE", "NG", "RW", "RE", "SH", "SN", "SC", "SL", "SO", "ZA", "SD", "SZ", "ST", "TZ", "TG", "TN", "UG", "EH", "ZM", "ZW", "AI", "AG", "AR", "AW", "BS", "BB", "BZ", "BM", "BO", "BR", "VG", "CA", "KY", "CL", "CO", "CR", "CU", "DM", "DO", "EC", "SV", "FK", "GF", "GL", "GD", "GP", "GT", "GY", "HT", "HN", "JM", "MQ", "MX", "MS", "AN", "NI", "PA", "PY", "PE", "PR", "BL", "KN", "LC", "MF", "PM", "VC", "SR", "TT", "TC", "VI", "US", "UY", "VE", "AF", "AM", "AZ", "BH", "BD", "BT", "BN", "KH", "CN", "GE", "HK", "IN", "ID", "IR", "IQ", "IL", "JP", "JO", "KZ", "KW", "KG", "LA", "LB", "MO", "MY", "MV", "MN", "MM", "NP", "NT", "KP", "OM", "PK", "PS", "YD", "PH", "QA", "SA", "SG", "KR", "LK", "SY", "TW", "TJ", "TH", "TL", "TR", "™", "AE", "UZ", "VN", "YE", "AL", "AD", "BY", "BA", "DD", "FO", "GI", "GG", "IS", "IM", "JE", "LI", "MK", "FX", "MD", "MC", "ME", "NO", "RU", "SM", "RS", "CS", "SJ", "CH", "UA", "SU", "VA", "AX", "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "GB", "AS", "AQ", "AU", "BV", "IO", "CX", "CC", "CK", "FJ", "PF", "TF", "GU", "HM", "KI", "MH", "FM", "NR", "NC", "NZ", "NU", "NF", "MP", "PW", "PG", "PN", "WS", "SB", "GS", "TK", "TO", "TV", "UM", "VU", "WF"]

const Create_Insertion_Order = props => {

  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const [iOs, setIOs] = useState({
    iOName: '',
    iOGroupID: '',
    advertiserID: '',
    iOType: props.selectedIOType || props.selectedIOs.iOType || props.selectedIOGroup.iOType,
    iOImpressionCapping: {
      per: 'day',
      capping: "0",
    },
    iOFrequencyCapping: {
      per: 'hour',
      capping: "0",
    },
    iOCountriesIn: [],
    iOCountriesEx: [],
    iOCitiesIn: [],
    iOCitiesEx: [],
  });
  const [advertiserData, setAdvertiserData] = useState([])
  const [submitted, setSubmitted] = useState(false);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const [iOGroupData, setIOGroupData] = useState([])
  const [selectedIOGroup, setSelectedIOGroup] = useState(null);
  const [iOGroupPresent, setIOGroupPresent] = useState(false)
  const nextRef = useRef();
  const [nextTab, setNextTab] = useState(false);
  const [lineItemType, setIOsType] = useState('');
  const [impressionState, setImpressionState] = useState(false)
  const [frequencyState, setFrequencyState] = useState(false)
  const [regionData, setRegionData] = useState([])
  const [countriesData, setCountriesData] = useState([])
  const [cityData, setCityData] = useState([])
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [loader, setLoader] = useState(false);
  const [impressionData, setImpressionData] = useState({
    per: 'day',
    capping: "0",
  })
  const [frequencyData, setFrequencyData] = useState({
    capping: "0",
    per: 'hour'
  })
  const [keyword,setKeyword] = useState('')
  const [countriesState, setCountriesState] = useState(true);
  const [citiesState, setCitiesState] = useState(true);
  const [selectAllCity, setSelectAllCity] = useState(false);
  const [selectAllCountry, setSelectAllCountry] = useState(false);

  const [treeSelects, setTreeSelects] = useState({
    checked: [],
    expanded: [],
    keyword:''
  })
  const [targetStates, setTargetStates] = useState({
    countries: false,
    cities: false,
  })

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const myRef = useRef();
  const cityRef = useRef();

  const handleClickOutside = e => {
    if (!myRef.current.contains(e.target)) {
      setIsOptionsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const [treeSelectCity, setTreeSelectCity] = useState({
    checked: [],
    expanded: [],
    keyword: ''
  })
  const [isOptionsOpenCity, setIsOptionsOpenCity] = useState(false);

  const handleClickCityOutside = e => {
    if (!cityRef.current.contains(e.target)) {
      setIsOptionsOpenCity(false);
    }
  };

  const toggleOptionsCity = () => {
    if(treeSelects.checked && treeSelects.checked.length > 0){
      setIsOptionsOpenCity(!isOptionsOpenCity);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickCityOutside);
    return () => document.removeEventListener("mousedown", handleClickCityOutside);
  });

  console.log(props)

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }
  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name:userInfo.name,
    }
  }

  var impressionValue = {}

  useEffect(() => {
    dispatch(onGetIOGroups(null));
  }, [])

  const blurChangeImpression = (e) => {
    const { name, value } = e.target;
    if (e.target.name === 'capping') {
      if(e.target.value){
      if (e.target.value < "0") {
        setImpressionData(impressionData => ({ ...impressionData, [name]: "0" }));
        impressionValue = {
          ...impressionData, [name]: "0"
        }
      } else {
        setImpressionData(impressionData => ({ ...impressionData, [name]: value }));
        impressionValue = {
          ...impressionData, [name]: value
        }
      }}else{
        setImpressionData(impressionData => ({ ...impressionData, [name]: "0" }));
        impressionValue = {
          ...impressionData, [name]: "0"
        }
      }
      setIOs(iOs => ({ ...iOs, iOImpressionCapping: impressionValue }));
    }
  }

  const blurChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === 'capping') {
      if(e.target.value){
      if (e.target.value < "0") {
        setFrequencyData(frequencyData => ({ ...frequencyData, [name]: "0" }));
        frequencyValue = {
          ...frequencyData, [name]: "0"
        }
      } else {
        setFrequencyData(frequencyData => ({ ...frequencyData, [name]: value }));
        frequencyValue = {
          ...frequencyData, [name]: value
        }
      }}else{
        setFrequencyData(frequencyData => ({ ...frequencyData, [name]: "0" }));
        frequencyValue = {
          ...frequencyData, [name]: "0"
        }
      }
      setIOs(iOs => ({ ...iOs, iOFrequencyCapping: frequencyValue }));
    }
  }

  const changeImpressionCapping = (e) => {
    const { name, value } = e.target;
    console.log(value)
    if(value){
      setImpressionData(impressionData => ({ ...impressionData, [name]: value }));
      impressionValue = {
        ...impressionData, [name]: value
      }
      setIOs(iOs => ({ ...iOs, iOImpressionCapping: impressionValue }));
    }else{
      setImpressionData(impressionData => ({ ...impressionData, [name]: '' }));
    }
  }

  var frequencyValue = {}

  const changeFrequencyCapping = (e) => {
    const { name, value } = e.target;
    if (value) {
      setFrequencyData(frequencyData => ({ ...frequencyData, [name]: value }));
      frequencyValue = {
        ...frequencyData, [name]: value
      }
      setIOs(iOs => ({ ...iOs, iOFrequencyCapping: frequencyValue }));
    } else {
      setFrequencyData(frequencyData => ({ ...frequencyData, [name]: '' }));
    }
  }

  const changeImpressionPer = (e) => {
    setImpressionData(impressionData => ({ ...impressionData, per: e.target.value }));
    impressionValue = {
      ...impressionData, per: e.target.value
    }
    setIOs(iOs => ({ ...iOs, iOImpressionCapping: impressionValue }));
  }

  const changeFrequencyPer = (e) => {
    setFrequencyData(frequencyData => ({ ...frequencyData, per: e.target.value }));
    frequencyValue = {
      ...frequencyData, per: e.target.value
    }
    setIOs(iOs => ({ ...iOs, iOFrequencyCapping: frequencyValue }));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIOs(iOs => ({ ...iOs, [name]: value }));
  }

  useEffect(() => {
    if (props.selectedIOs !== '') {
      setIsEdit(true)
      setIOs(props.selectedIOs)
      if(props.selectedIOs.iOImpressionCapping.capping > 0){
        setImpressionState(true)
      }else{
        setImpressionState(false)
      }
      if(props.selectedIOs.iOFrequencyCapping.capping > 0){
        setFrequencyState(true)
      }else{
        setFrequencyState(false)
      }
      setSelectedIOGroup({ value: props.selectedIOs.iOGroupID, label: props.selectedIOs.iOGroupName });
      setImpressionData(impressionData => ({ ...impressionData, capping: props.selectedIOs.iOImpressionCapping.capping, per: props.selectedIOs.iOImpressionCapping.per }));
      setFrequencyData(frequencyData => ({ ...frequencyData, capping: props.selectedIOs.iOFrequencyCapping.capping, per: props.selectedIOs.iOFrequencyCapping.per }));
      setSelectedRegion(props.selectedIOs.iOCountriesIn.length > 0 ? objectModals(props.selectedIOs.iOCountriesIn) : props.selectedIOs.iOCountriesEx.length > 0 ? objectModals(props.selectedIOs.iOCountriesEx) : [])
      setTreeSelects(treeSelects => ({ ...treeSelects, checked: props.selectedIOs.iOCountriesIn.length > 0 ? props.selectedIOs.iOCountriesIn : props.selectedIOs.iOCountriesEx.length > 0 ? props.selectedIOs.iOCountriesEx : [] }));
      setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: props.selectedIOs.iOCitiesIn.length > 0 ? props.selectedIOs.iOCitiesIn : props.selectedIOs.iOCitiesEx.length > 0 ? props.selectedIOs.iOCitiesEx : [] }));
      setSelectedCity(props.selectedIOs.iOCitiesIn.length > 0 ? objectModals(props.selectedIOs.iOCitiesIn) : props.selectedIOs.iOCitiesEx.length > 0 ? objectModals(props.selectedIOs.iOCitiesEx) : [])

      setCountriesState(props.selectedIOs.iOCountriesIn.length > 0 ? true : props.selectedIOs.iOCountriesEx.length > 0 ? false : true)
      setCitiesState(props.selectedIOs.iOCitiesIn.length > 0 ? true : props.selectedIOs.iOCitiesEx.length > 0 ? false : true)
      getParentIO('')
    }
    if (props.selectedIOGroup !== '' && props.selectedIOs === '') {
      getParentIO('')
    }
  }, []);

  const objectModals = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  useEffect(() => {
    body.url = "http://127.0.0.1:2002/v1.0/regions/list"
    axios.post("/v1.0/connectReportAPI", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is ==>", res)
        if (res.data.responseData) {
          let trees = [
            createTree("Africa", res.data.responseData.Africa),
            createTree("America", res.data.responseData.Americas),
            createTree("Asia", res.data.responseData.Asia),
            createTree("Europe", res.data.responseData.Europe),
            createTree("GDPR", res.data.responseData.GDPR),
            createTree("Oceania", res.data.responseData.Oceania),
          ];
          setRegionData(trees)
          setCountriesData([...res.data.responseData.Africa,...res.data.responseData.Americas,...res.data.responseData.Asia,...res.data.responseData.Europe,...res.data.responseData.GDPR,...res.data.responseData.Oceania])
          // if (props.selectedIOs !== '') {
          //   if(props.selectedIOs.iOCountriesIn.length > 0 || props.selectedIOs.iOCountriesEx.length > 0){
          //     getCities(props.selectedIOs.iOCountriesIn.length > 0 ? props.selectedIOs.iOCountriesIn : props.selectedIOs.iOCountriesEx.length > 0 ? props.selectedIOs.iOCountriesEx : [],[...res.data.responseData.Africa, ...res.data.responseData.Americas, ...res.data.responseData.Asia, ...res.data.responseData.Europe, ...res.data.responseData.GDPR, ...res.data.responseData.Oceania])
          //   }
          // }


          if (props.selectedIOs !== '') {
              setTargetStates(targetStates => ({
                ...targetStates,
                cities: props.selectedIOs.iOCitiesIn.length > 0 || props.selectedIOs.iOCitiesEx.length > 0 ? true : false,}))
                cityRetrieved = props.selectedIOs.iOCitiesIn.length > 0 ? props.selectedIOs.iOCitiesIn : props.selectedIOs.iOCitiesEx.length > 0 ? props.selectedIOs.iOCitiesEx : [] 
            setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: props.selectedIOs.iOCitiesIn.length > 0 ? props.selectedIOs.iOCitiesIn : props.selectedIOs.iOCitiesEx.length > 0 ? props.selectedIOs.iOCitiesEx : [] }));
            if (props.selectedIOs.iOCountriesIn.length > 0 || props.selectedIOs.iOCountriesEx.length > 0) {
              getDefaultSelectedCities(props.selectedIOs.iOCountriesIn.length > 0 ? props.selectedIOs.iOCountriesIn : props.selectedIOs.iOCountriesEx.length > 0 ? props.selectedIOs.iOCountriesEx : [], [...res.data.responseData.Africa, ...res.data.responseData.Americas, ...res.data.responseData.Asia, ...res.data.responseData.Europe, ...res.data.responseData.GDPR, ...res.data.responseData.Oceania])
            }
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }, []);

  const getCities = (countries,countryData) => {
    setCityData([])
    let countryList = []
    var array3 = countryData.filter(function (obj) {
      return countries.indexOf(obj.value) !== -1
    });
    array3.map((item, index) => {
      countryList.push(item.label)
    })
    const bodyCity = {
      "url": "http://127.0.0.1:2002/v1.0/cities/list/countrylist",
      "requestBody": {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name:userInfo.name,
        "countrylist":countryList
      }
    }
    axios.post("/v1.0/connectReportAPI", bodyCity,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is cities ==>", res)
        if (res.data.responseData) {
          let trees = []
          let selectedCities = []
          if (res.data.responseData.response.length > 0) {
            res.data.responseData.response.map((item, i) => {
              trees.push(createCityTree(item.label, item.value))
              selectedCities = [...selectedCities, ...item.value]
              cityRetrieved = selectedCities
            })
          }
          setSelectAllCity(false)
          setCityData(trees)
          if (treeSelectCity.checked.length > 0 && cityRetrieved.length > 0) {
            let arr1 = [treeSelectCity.checked, cityRetrieved]
            let newArr = arr1.reduce((x, y) => x.filter((z) => y.includes(z)));
            setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: newArr }));
            citiesState ? setIOs(iOs => ({ ...iOs, iOCitiesIn: newArr })) : setIOs(iOs => ({ ...iOs, iOCitiesEx: newArr }));
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const getDefaultSelectedCities = (countries, countryData) => {
    setCityData([])
    let countryList = []
    var array3 = countryData.filter(function (obj) {
      return countries.indexOf(obj.value) !== -1
    });
    array3.map((item, index) => {
      countryList.push(item.label)
    })
    const bodyCity = {
      "url": "http://127.0.0.1:2002/v1.0/cities/list/countrylist",
      "requestBody": {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
        "countrylist": countryList
      }
    }
    axios.post("/v1.0/connectReportAPI", bodyCity,
      {
        headers: headers
      })
      .then((res) => {
        if (res.data.responseData) {
          let trees = []
          let selectedCities = []
          if (res.data.responseData.response.length > 0) {
            res.data.responseData.response.map((item, i) => {
              trees.push(createCityTree(item.label, item.value))
              selectedCities = [...selectedCities, ...item.value]
              cityRetrieved = selectedCities
            })
          }
          setCityData(trees)
          setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: props.selectedIOs.iOCitiesIn.length > 0 ? props.selectedIOs.iOCitiesIn : props.selectedIOs.iOCitiesEx.length > 0 ? props.selectedIOs.iOCitiesEx : [] }));
          setSelectedCity(props.selectedIOs.iOCitiesIn.length > 0 ? objectModals(props.selectedIOs.iOCitiesIn) : props.selectedIOs.iOCitiesEx.length > 0 ? objectModals(props.selectedIOs.iOCitiesEx) : [])
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const createTree = (groupName, options) => {
    return {
      label: groupName,
      value: groupName,
      children: options,
    };
  };

  const createCityTree = (groupName, options) => {
    return {
      label: groupName,
      value: groupName,
      children: objectModalCity(options)
    };
  };

  const objectModalCity = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  const getParentIO = (oldGroupID) => {
    var filterBody = {
      url:(userInfo.userRole === 'Partner'|| userInfo.userRole === 'Agency') ? 'http://127.0.0.1:3006/v1.0/demand/getParent' : "http://127.0.0.1:2002/v1.0/demand/getParent",
      requestBody: {
        "advertiserID": 0,
        "iOGroupID": 0,
        "iOID": 0,
        "lineItemID": 0,
        "creativeID": 0,
        "videoCreativeID": 0,
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
      }
    }
    if(userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency'){
      filterBody.requestBody = {...filterBody.requestBody,partnerName:userInfo.name,partnerID:userInfo.userID}
  }
    if (props.selectedIOs !== '') {
      filterBody.requestBody.iOGroupID = props.selectedIOs.iOGroupID
      setIOGroupPresent(true)
    }
    if (props.selectedIOGroup !== '') {
      filterBody.requestBody.iOGroupID = props.selectedIOGroup.iOGroupID
      setIOGroupPresent(true)
    }
    if(oldGroupID){
      filterBody.requestBody.iOGroupID = oldGroupID
    }
    axios.post(userInfo.userRole === 'Partner' ? "/v1.0/connectPartner" :userInfo.userRole === 'Agency' ? "/v1.0/connectPartner" : "/v1.0/connectOP", filterBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("cdsresult is ==>", res)
        if (res.data.responseData) {
          setSelectedAdvertiser({ label: res.data.responseData.advertiserName, value: res.data.responseData.advertiserID });
          setSelectedIOGroup({ value: res.data.responseData.iOGroupID, label: res.data.responseData.iOGroupName });
          setIOs(iOs => ({ ...iOs, advertiserID: res.data.responseData.advertiserID, iOGroupID: res.data.responseData.iOGroupID }));
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const { advertisers } = useSelector(state => ({
    advertisers: state.advertisers.advs,
  }));

  const { iOGroups } = useSelector(state => ({
    iOGroups: state.iOGroups.iOGroups,
  }));

  console.log(advertisers, iOGroups)

  useEffect(() => {
    if (advertisers !== null) {
      // dispatch(onGetAdvertisers({ filter: 'Active',type:iOs.iOType }));
      dispatch(onGetAdvertisers({ filter: 'Active',type:'',userRole:userInfo.userRole,userID:userInfo.userID }));
    }
  }, []);

  useEffect(() => {
    if (iOGroups !== null) {
      // dispatch(onGetIOGroups({ advertiserID: '', filter: 'Active',type:iOs.iOType }));
      dispatch(onGetIOGroups({ advertiserID: '', filter: 'Active',type:'',userRole:userInfo.userRole,userID:userInfo.userID }));
    }
  }, []);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.advertiserID
      obj.label = item.advertiserName
      dataList.push(obj)
    })
    return dataList
  }

  const objectModalGroup = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.iOGroupID
      obj.label = item.iOGroupName
      dataList.push(obj)
    })
    return dataList
  }

  useEffect(() => {
    if (advertisers && advertisers.length > 0) {
      setAdvertiserData(objectModal(advertisers))
      console.log(advertisers)
    }
  }, [advertisers]);

  useEffect(() => {
    if (iOGroups && iOGroups.length > 0) {
      setIOGroupData(objectModalGroup(iOGroups))
      console.log(iOGroups)
    }
  }, [iOGroups]);

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.iOSs.errorMsg,
    successMsg: state.iOSs.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addIOsSuccess(""));
        dispatch(addIOsFail(""));
        dispatch(updateIOsSuccess(""));
        dispatch(updateIOsFail(""));
        setLoader(false)
        if (nextTab) {
          props.closeCanvas('')
          nextRef.current.click()
        } else {
          if (props.selectedIOs !== '') {
            props.closeCanvas('Update')
          } else {
            props.closeCanvas('Create')
          }
        }
      }
    }
  }, [successMsg]);


  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if(errorMsg.status && errorMsg.status === 500){
      }else{
        dispatch(addIOsFail(""));
        dispatch(updateIOsFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addIOsFail(""));
    dispatch(updateIOsFail(""));
        props.closeCanvas('')
  };

  function handleSelectAdvertiser(selectAdvertiser) {
    console.log(selectAdvertiser)
    setIOs(iOs => ({ ...iOs, advertiserID: selectAdvertiser.value }));
    setSelectedAdvertiser(selectAdvertiser);
     setSelectedIOGroup(null);
     setIOGroupData([])
    // dispatch(onGetIOGroups({ advertiserID: selectAdvertiser.value, filter: 'Active',type:iOs.iOType }));
    dispatch(onGetIOGroups({ advertiserID: selectAdvertiser.value, filter: 'Active',type:'',userRole:userInfo.userRole,userID:userInfo.userID }));
   
  }

  function handleSelectIOGroup(selectIOGroup) {
    getParentIO(selectIOGroup.value)
    setIOs(iOs => ({ ...iOs, iOGroupID: selectIOGroup.value }));
    console.log(selectIOGroup)
    setSelectedIOGroup(selectIOGroup);
    
  }

  const impressionStateChange = e => {
    setImpressionState(e.target.checked)
    if(e.target.checked === false){
        setImpressionData(impressionData => ({ ...impressionData,capping: "0" }));
        impressionValue = {
          ...impressionData, capping: "0"
        }
        setIOs(iOs => ({ ...iOs, iOImpressionCapping: impressionValue }));
      }else{
        setImpressionData(impressionData => ({ ...impressionData,capping: "" }));
        impressionValue = {
          ...impressionData, capping: ""
        }
      }
  }

  const frequencyStateChange = e => {
    setFrequencyState(e.target.checked)
    if(e.target.checked === false){
      setFrequencyData(frequencyData => ({ ...frequencyData, capping: "0" }));
      frequencyValue = {
        ...frequencyData, capping: "0"
      }
      setIOs(iOs => ({ ...iOs, iOFrequencyCapping: frequencyValue }));
    }else{
      setFrequencyData(frequencyData => ({ ...frequencyData, capping: "" }));
      frequencyValue = {
        ...frequencyData, capping: ""
      }
    }
  }

  const handleSelectRegion = country => {
    console.log(country)
    setSelectedRegion(country);
    var countryList = []
    country.map((item, index) => {
      countryList.push(item.value)
    })
    countriesState ? setIOs(iOs => ({ ...iOs, iOCountriesIn: countryList })) : setIOs(iOs => ({ ...iOs, iOCountriesEx: countryList }))
  }

  const handleSelectCity = city => {
    setSelectedCity(city);
    var citiesLists = []
    city.map((item, index) => {
      citiesLists.push(item.value)
    })
    citiesState ? setIOs(iOs => ({ ...iOs, iOCitiesIn: citiesLists })) : setIOs(iOs => ({ ...iOs, iOCitiesEx: citiesLists }))
  }

  const countryStateChange = (e) => {
    setCountriesState(e.target.checked)
    var countriess = []
    if (iOs.iOCountriesIn.length > 0) {
      countriess = iOs.iOCountriesIn
    } else {
      countriess = iOs.iOCountriesEx
    }
    if (e.target.checked) {
      setIOs(iOs => ({ ...iOs, iOCountriesIn: countriess, iOCountriesEx: [] }));
    } else {
      setIOs(iOs => ({ ...iOs, iOCountriesEx: countriess, iOCountriesIn: [] }));
    }
  }

  const cityStateChange = (e) => {
    setCitiesState(e.target.checked)
    var citiess = []
    if (iOs.iOCitiesIn.length > 0) {
      citiess = iOs.iOCitiesIn
    } else {
      citiess = iOs.iOCitiesEx
    }
    if (e.target.checked) {
      setIOs(iOs => ({ ...iOs, iOCitiesIn: citiess, iOCitiesEx: [] }));
    } else {
      setIOs(iOs => ({ ...iOs, iOCitiesEx: citiess, iOCitiesIn: [] }));
    }
  }

  const handleSubmit = async (e) => {
    console.log(iOs)
    e.preventDefault();
    setSubmitted(true);
    if ((impressionState && impressionData.capping < 1)) {
      toastr.error('Please fill Impression Capping Data')
    }else{
      if (iOs.iOName && iOs.iOGroupID) {
        setLoader(true)
        setIOsType(iOs.iOType)
        if (props.selectedIOs !== '') {
          dispatch(onUpdateIOs({ ...iOs, iOID: props.selectedIOs.iOID,iOName:iOs.iOName.trim(),userRole:userInfo.userRole,userID:userInfo.userID }));
        } else {
          dispatch(onADDIOs({ ...iOs,iOName:iOs.iOName.trim(),userRole:userInfo.userRole,userID:userInfo.userID }));
        }
      }
    }
  }

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if ( !(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault();}
  };

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if ( !(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault();}
  };

  const toggleSelectAllCountries = (e) => {
    setSelectAllCountry(e.target.checked)
    if (e.target.checked) {
      setTreeSelects(treeSelects => ({ ...treeSelects, checked: countriesAll }));
      countriesState ? setIOs(iOs => ({ ...iOs, iOCountriesIn: cityRetrieved })) : setIOs(iOs => ({ ...iOs, iOCountriesEx: countriesAll }));
      getCities(countriesAll, countriesData)
      setTargetStates(targetStates => ({
        ...targetStates,
        countries: true,
      }));
    } else {
      cityRetrieved = []
      setTreeSelects(treeSelects => ({ ...treeSelects, checked: [] }));
      countriesState ? setIOs(iOs => ({ ...iOs, iOCountriesIn: [] })) : setIOs(iOs => ({ ...iOs, iOCountriesEx: [] }));
    }
  }

  const toggleSelectAllCities = (e) => {
    setSelectAllCity(e.target.checked)
    if (e.target.checked) {
      setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: cityRetrieved }));
      citiesState ? setIOs(iOs => ({ ...iOs, iOCitiesIn: cityRetrieved })) : setIOs(iOs => ({ ...iOs, iOCitiesEx: cityRetrieved }));
      setTargetStates(targetStates => ({
        ...targetStates,
        cities: true,
      }));
      console.log(cityRetrieved)
    } else {
      setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: [] }));
      citiesState ? setIOs(iOs => ({ ...iOs, iOCitiesIn: [] })) : setIOs(iOs => ({ ...iOs, iOCitiesEx: [] }));
    }
  }

  useEffect(() => {
    if (treeSelects.checked.length === 0) {
      setSelectAllCity(false)
      setCityData([])
      setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: [] }));
      citiesState ? setIOs(iOs => ({ ...iOs, iOCitiesIn: [] })) : setIOs(iOs => ({ ...iOs, iOCitiesEx: [] }));``
      cityRetrieved = []
      setTargetStates(targetStates => ({
        ...targetStates,
        cities: false, 
      }));
    } else {
      setTargetStates(targetStates => ({
        ...targetStates, countries: true
      }));
    }
  }, [treeSelects.checked.length])

  const onSearchInputChange = (event, data, searchedNodes) => {
    console.log(event, data, searchedNodes)
    setTreeSelects(treeSelects => {
      if (treeSelects.keyword.trim() && !data.value.trim()) {
        return {
          ...treeSelects,
          expanded: [],
          keyword: data.value
        };
      }
      return {
        ...treeSelects,
        expanded: getAllValuesFromNodes(searchedNodes, true),
        keyword: data.value
      };
    });
  };

  const onSearchInputCityChange = (event, data, searchedNodes) => {
    console.log(event, data, searchedNodes)
    setTreeSelectCity(treeSelectCity => {
      if (treeSelectCity.keyword.trim() && !data.value.trim()) {
        return {
          ...treeSelectCity,
          expanded: [],
          keyword: data.value
        };
      }
      return {
        ...treeSelectCity,
        expanded: getAllValuesFromNodes(searchedNodes, true),
        keyword: data.value
      };
    });
  };

  const getAllValuesFromNodes = (nodes, firstLevel) => {
    if (firstLevel) {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, false));
        }
      }
      return values;
    } else {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, false));
        }
      }
      return values;
    }
  };

  const keywordFilter = (nodes, keyword) => {
    let newNodes = [];
    for (let n of nodes) {
      if (n.children) {
        const nextNodes = keywordFilter(n.children, keyword);
        if (nextNodes.length > 0) {
          n.children = nextNodes;
        } else if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.children = nextNodes.length > 0 ? nextNodes : [];
        }
        if (
          nextNodes.length > 0 ||
          n.label.toLowerCase().includes(keyword.toLowerCase())
        ) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      } else {
        if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      }
    }
    if(newNodes.length === 1){
      let regions = regionData
      regions.map((item,index)=> {
        if(item.value.toLowerCase() === newNodes[0].value.toLowerCase()){
          newNodes[0].children = item.children
        }
      })
    }
    return newNodes;
  };

  const keywordFilterCity = (nodes, keyword) => {
    let newNodes = [];
    for (let n of nodes) {
      if (n.children) {
        const nextNodes = keywordFilterCity(n.children, keyword);
        if (nextNodes.length > 0) {
          n.children = nextNodes;
        } else if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.children = nextNodes.length > 0 ? nextNodes : [];
        }
        if (
          nextNodes.length > 0 ||
          n.label.toLowerCase().includes(keyword.toLowerCase())
        ) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      } else {
        if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      }
    }
    if(newNodes.length === 1){
      let countries = cityData
      countries.map((item,index)=> {
        if(item.value.toLowerCase() === newNodes[0].value.toLowerCase()){
          newNodes[0].children = item.children
        }
      })
    }
    return newNodes;
  };

  const getHighlightText = (text, keyword) => {
    console.log(text, keyword)
    const startIndex = text.indexOf(keyword);
    return startIndex !== -1 ? (
      <span>
        {text.substring(0, startIndex)}
        <span style={{ color: "red" }}>
          {text.substring(startIndex, startIndex + keyword.length)}
        </span>
        {text.substring(startIndex + keyword.length)}
      </span>
    ) : (
      <span>{text}</span>
    );
  };

  const checkValue = (row,checked) => {
      if(row.isParent){
        if (row.checked){
          let finalChecked = [...treeSelects.checked,...checked]
          finalChecked = finalChecked.filter((item,index) => finalChecked.indexOf(item) === index)
          setTreeSelects(treeSelects => ({ ...treeSelects, checked:finalChecked}));
          countriesState ? setIOs(iOs => ({ ...iOs, iOCountriesIn: finalChecked })) : setIOs(iOs => ({ ...iOs, iOCountriesEx: finalChecked }))
          if(finalChecked.length > 0){
            getCities(finalChecked,countriesData)
          }
        }else{
          let finalChecked = [...treeSelects.checked]
          let filteredChildren = row.children.map(a => a.value);
          let result  = finalChecked.filter(val => !filteredChildren.includes(val));
          setTreeSelects(treeSelects => ({ ...treeSelects, checked:result}));
          countriesState ? setIOs(iOs => ({ ...iOs, iOCountriesIn: result })) : setIOs(iOs => ({ ...iOs, iOCountriesEx: result }))
          if(result.length > 0){
            getCities(result,countriesData)
          }
        }
      }else{
        if (row.checked){
          let finalChecked = [...treeSelects.checked,...checked]
          finalChecked = finalChecked.filter((item,index) => finalChecked.indexOf(item) === index)
          setTreeSelects(treeSelects => ({ ...treeSelects, checked:finalChecked}));
          countriesState ? setIOs(iOs => ({ ...iOs, iOCountriesIn: finalChecked })) : setIOs(iOs => ({ ...iOs, iOCountriesEx: finalChecked }))
          if(finalChecked.length > 0){
            getCities(finalChecked,countriesData)
          }
        }else{
          let finalChecked = [...treeSelects.checked]
          let index = finalChecked.indexOf(row.value)
          finalChecked.splice(index,1)
          setTreeSelects(treeSelects => ({ ...treeSelects, checked:finalChecked}));
          countriesState ? setIOs(iOs => ({ ...iOs, iOCountriesIn: finalChecked })) : setIOs(iOs => ({ ...iOs, iOCountriesEx: finalChecked }))
          if(finalChecked.length > 0){
            getCities(finalChecked,countriesData)
          }
        }
      }
  }

  const checkCityValue = (row,checked) => {
    if(row.isParent){
      if (row.checked){
        let finalChecked = [...treeSelectCity.checked,...checked]
        finalChecked = finalChecked.filter((item,index) => finalChecked.indexOf(item) === index)
        setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked:finalChecked}));
        citiesState ? setIOs(iOs => ({ ...iOs, iOCitiesIn: finalChecked })) : setIOs(iOs => ({ ...iOs, iOCitiesEx: finalChecked }))
      }else{
        let finalChecked = [...treeSelectCity.checked]
        let filteredChildren = row.children.map(a => a.value);
        let result  = finalChecked.filter(val => !filteredChildren.includes(val));
        setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked:result}));
        citiesState ? setIOs(iOs => ({ ...iOs, iOCitiesIn: result })) : setIOs(iOs => ({ ...iOs, iOCitiesEx: result }))
      }
    }else{
      if (row.checked){
        let finalChecked = [...treeSelectCity.checked,...checked]
        finalChecked = finalChecked.filter((item,index) => finalChecked.indexOf(item) === index)
        setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked:finalChecked}));
        citiesState ? setIOs(iOs => ({ ...iOs, iOCitiesIn: finalChecked })) : setIOs(iOs => ({ ...iOs, iOCitiesEx: finalChecked }))
      }else{
        let finalChecked = [...treeSelectCity.checked]
        let index = finalChecked.indexOf(row.value)
        finalChecked.splice(index,1)
        setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked:finalChecked}));
        citiesState ? setIOs(iOs => ({ ...iOs, iOCitiesIn: finalChecked })) : setIOs(iOs => ({ ...iOs, iOCitiesEx: finalChecked }))
      }
    }
}

const handleCheck = e => {
  const { name, value } = e.target;
  setTargetStates(targetStates => ({ ...targetStates, [name]: e.target.checked }));
  if (name === 'countries') {
    setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: [] }));
    setIOs(iOs => ({ ...iOs, iOCitiesEx: [], iOCitiesIn: [] }));
    cityRetrieved = []
    setTreeSelects(treeSelects => ({ ...treeSelects, checked: [] }));
    setIOs(iOs => ({ ...iOs, iOCountriesEx: [], iOCountriesIn: [] }));
  } if (name === 'cities') {
    setSelectAllCity(false)
    setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: [] }));
    setIOs(iOs => ({ ...iOs, iOCitiesEx: [], iOCitiesIn: [] }));
  }

}

const getSelectionData = async (selectedCreativeData) => {
  setIOs(iOs => ({ ...iOs, ...selectedCreativeData }));
}

  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
        <Form onSubmit={handleSubmit}>
          <div style={{ padding: '1% 3%', height: '79vh', overflow: 'auto' }}>
            <Col xs={12}>
            <div className="mb-3">
                <Label>Advertiser</Label>
                <Select
                  value={selectedAdvertiser}
                  onChange={s => {
                    handleSelectAdvertiser(s)
                  }}
                  options={advertiserData}
                  classNamePrefix="select2-selection  "
                  className={ (submitted && !iOs.advertiserID ? ' is-invalid' : '') }
                  isDisabled={props.selectedArchived || isEdit}
                />
                {submitted && !iOs.advertiserID ? (
                  <FormFeedback type="invalid">{'Please Select Advertiser'}</FormFeedback>
                ) : null}

              </div>
              <div className="mb-3">
                <Label>IO Group</Label>
                <Select
                  value={selectedIOGroup}
                  onChange={s => {
                    handleSelectIOGroup(s)
                  }}
                  options={iOGroupData}
                  classNamePrefix="select2-selection"
                  className={ (submitted && !iOs.iOGroupID ? ' is-invalid' : '') }
                  isDisabled={props.selectedArchived || isEdit}
                />
                {submitted && !iOs.iOGroupID ? (
                  <FormFeedback type="invalid">{'Please Select IOGroup'}</FormFeedback>
                ) : null}

              </div>
              {/* <Hierarchy_Selection moduleType="IOS" selectedArchived={props.selectedArchived} inventoryType={''} selectedItems={getSelectionData} submitted={submitted} selectedData={props.selectedIOs} createStatus={isEdit} /> */}
              <div className="mb-3">
                <Label className="form-label">Insertion Order Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  name="iOName"
                  type="text"
                  maxLength={65}
                  onChange={handleChange}
                  value={iOs.iOName}
                  onKeyPress={allowAlphaNumericSpace}
                  className={ (submitted && !iOs.iOName ? ' is-invalid' : '')}
                  readOnly={props.selectedArchived}
                />
                {submitted && !iOs.iOName ? (
                  <FormFeedback type="invalid">{'Insertion Order Name is required'}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label >Impression Capping : </Label>
                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitchsizemd"
                    checked={impressionState}
                    onChange={impressionStateChange}
                    disabled={props.selectedArchived}
                  />
                  <label htmlFor="customSwitchsizemd" className="form-check-label">
                    {impressionState ? <span color='text-success' style={{ color: '#34c38f' }}>On</span> : <span style={{ color: '#a3a3a3' }}>Off</span>}
                  </label>
                </div>
                <div className='row'>
                  <div className='col-8'>
                    <Label >Capping </Label>
                    <Input
                      type="text" name="capping"
                      value={impressionData.capping} onBlur={blurChangeImpression} onKeyPress={allowOnlyNumbers}  onChange={changeImpressionCapping}
                      placeholder="Enter Impression Capping"
                      className="form-control"
                      disabled={!impressionState}
                      readOnly={props.selectedArchived}
                    />
                  </div>
                  <div className='col-4'>
                    <Label >Per</Label>
                    <Input type="select" className="form-select" readOnly={props.selectedArchived} value={impressionData.per} onChange={changeImpressionPer} disabled={!impressionState}>
                      <option value='day'>  Day</option>
                      <option value='hour'> Hour</option>
                      <option value='total'> Total</option>
                    </Input>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                  <Label >Frequency Capping : </Label>
                  <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd1"
                      checked={frequencyState}
                      onChange={frequencyStateChange}
                      disabled={props.selectedArchived}
                    />
                    <label htmlFor="customSwitchsizemd1" className="form-check-label">
                      {frequencyState ? <span color='text-success' style={{ color: '#34c38f' }}>On</span> : <span style={{ color: '#a3a3a3' }}>Off</span>}
                    </label>
                  </div>
                  <div className='row'>
                    <div className='col-8'>
                      <Label >Capping </Label>
                      <Input
                        type="text" name="capping"
                        value={frequencyData.capping} onBlur={blurChange} onKeyPress={allowOnlyNumbers} onChange={changeFrequencyCapping}
                        placeholder="Enter Frequency Capping"
                        className="form-control"
                        disabled={!frequencyState}
                        readOnly={props.selectedArchived}
                      />
                    </div>
                    <div className='col-4'>
                      <Label >Per</Label>
                      <Input type="select" className="form-select" value={frequencyData.per} onChange={changeFrequencyPer} readOnly={props.selectedArchived} disabled={!frequencyState}>
                        <option value='hour'> Hour</option>
                        <option value='minute'> Minute</option>
                        <option value='day'> Day</option>
                      </Input>
                    </div>
                  </div>
                </div>
              <div className="mb-3">
              <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="countries" id='countries' checked={targetStates.countries} onChange={handleCheck}  />
              <Label className='m-3 mt-0 ' htmlFor='countries'>Country Targeting</Label>
            </div>
            {regionData.length > 0 && targetStates.countries &&
              <div className="form-group d-flex mt-1">
                <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="countriesSelectAll" id='countriesSelectAll' checked={(selectAllCountry && treeSelects.checked.length === 252)} onChange={toggleSelectAllCountries} disabled={props.selectedArchived} />
                <Label className='m-3 mt-0 ' htmlFor='countriesSelectAll'>Select All </Label>
              </div>
            }
                <div className='row checkbox-tree rmsc'>
                <div className='col-8' ref={myRef}>
                    <button
                      type="button"
                      aria-haspopup="listbox"
                      aria-expanded={isOptionsOpen}
                      className={'selectionButton ' + (isOptionsOpen ? "expanded" : "")}
                      onClick={toggleOptions}
                      disabled={props.selectedArchived || !targetStates.countries}
                    >
                      {treeSelects.checked && treeSelects.checked.length > 0 ? <span style={{ color: 'black' }}>{treeSelects.checked.length + " Countries are Selected"}</span> : "Please Select Countries"}
                    </button>
                    <Input
                        style={{display:isOptionsOpen ? 'block' : 'none'}}
                        placeholder="Search Countries..."
                        className='mt-1'
                        value={treeSelects.keyword}
                        onChange={(event, data) => {
                          onSearchInputChange(event, event.target, treeSelects.keyword.trim()
                          ? keywordFilter(_.cloneDeep(regionData), treeSelects.keyword.trim())
                          : regionData);
                        }}
                      />
                    <ul
                      className={`options ${isOptionsOpen ? "show" : ""}`}
                      role="listbox"
                      tabIndex={-1}
                    >
                      <CheckboxTree
                        nodes={treeSelects.keyword.trim()
                          ? keywordFilter(_.cloneDeep(regionData), treeSelects.keyword.trim())
                          : regionData}
                        checked={treeSelects.checked}
                        expanded={treeSelects.expanded}
                        onCheck={(checked,row) => {
                          checkValue(row,checked)
                        }}
                        onExpand={expanded => setTreeSelects(treeSelects => ({ ...treeSelects, expanded: expanded }))}
                        icons={{
                          expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                          expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                        }}
                        style={{marginTop:'10%'}}
                      />
                    </ul>
                  </div>
                  <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd2"
                      checked={countriesState}
                      onChange={countryStateChange}
                      disabled={props.selectedArchived || !targetStates.countries}
                    />
                    <label htmlFor="customSwitchsizemd2" className="form-check-label">
                      {countriesState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-3">
              <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="cities" id='cities' checked={targetStates.cities} onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='cities'>City Targeting</Label>
            </div>
              {cityData.length > 0 && targetStates.cities &&
                <div className="form-group d-flex mt-1">
                  <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="citiesSelectAll" id='citiesSelectAll' checked={(selectAllCity && (treeSelectCity.checked.length === cityRetrieved.length ? true : false))} onChange={toggleSelectAllCities} disabled={props.selectedArchived} />
                  <Label className='m-3 mt-0 ' htmlFor='citiesSelectAll'>Select All </Label>
                </div>
              }
                <div className='row checkbox-tree rmsc'>

                <div className='col-8' ref={cityRef}>
                    <button
                      type="button"
                      aria-haspopup="listbox"
                      aria-expanded={isOptionsOpenCity}
                      className={'selectionButton ' + (isOptionsOpenCity ? "expanded" : "")}
                      onClick={toggleOptionsCity}
                    >
                      {treeSelectCity.checked && treeSelectCity.checked.length > 0 ? <span style={{ color: 'black' }}>{treeSelectCity.checked.length + " Cities are Selected"}</span> : "Please Select Cities"}
                    </button>
                    <Input
                       style={{display:isOptionsOpenCity ? 'block' : 'none'}}
                       placeholder="Search Cities..."
                       className='mt-1'
                        value={treeSelectCity.keyword}
                        onChange={(event, data) => {
                          onSearchInputCityChange(event, event.target, treeSelectCity.keyword.trim()
                          ? keywordFilterCity(_.cloneDeep(cityData), treeSelectCity.keyword.trim())
                          : cityData);
                        }}
                      />
                    <ul
                      className={`options ${isOptionsOpenCity ? "show" : ""}`}
                      role="listbox"
                      tabIndex={-1}
                    >
                      <CheckboxTree
                        nodes={treeSelectCity.keyword.trim()
                          ? keywordFilterCity(_.cloneDeep(cityData), treeSelectCity.keyword.trim())
                          : cityData}
                        checked={treeSelectCity.checked}
                        expanded={treeSelectCity.expanded}
                        onCheck={(checked,row) => {
                          checkCityValue(row,checked)
                        }}
                        onExpand={expanded => setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, expanded: expanded }))}
                        icons={{
                          expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                          expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                        }}
                      />
                    </ul>
                  </div>


                  <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd3"
                      checked={citiesState}
                      onChange={cityStateChange}
                      disabled={props.selectedArchived || !targetStates.cities}
                    />
                    <label htmlFor="customSwitchsizemd3" className="form-check-label">
                      {citiesState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                    </label>
                  </div>
                </div>
              </div>
            </Col>
          </div>
          <Row className="drawer-footer"  style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
            <Col>
              <div className="text-start">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;

                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                    onClick={() => setNextTab(true)}
                  >
                    Save & Add Line Item
                  </button>
                }
                &nbsp;&nbsp;
                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {nextTab && successMsg &&
                  <Link ref={nextRef} to={`/Line_Items?iOID=${successMsg.data.iOID}&iOName=${successMsg.data.iOName}&lineItemType=${lineItemType}&status=LineItemCreateByIO`}  > </Link>
                }
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
  
    </React.Fragment>
  );
};

export default withRouter(Create_Insertion_Order);

Create_Insertion_Order.propTypes = {
  history: PropTypes.object,
};