/* eslint-disable react/prop-types */
import React, { useState, useRef,useEffect } from 'react';
import {
  Row, Col, Label, Form, Input
} from "reactstrap";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import _ from "lodash";

var cityRetrieved = []
var countriesAll = ["DZ", "AO", "BJ", "BW", "BF", "BI", "CM", "CV", "CF", "TD", "KM", "CG", "CD", "CI", "DJ", "EG", "GQ", "ER", "ET", "GA", "GM", "GH", "GN", "GW", "KE", "LS", "LR", "LY", "MG", "MW", "ML", "MR", "MU", "YT", "MA", "MZ", "NA", "NE", "NG", "RW", "RE", "SH", "SN", "SC", "SL", "SO", "ZA", "SD", "SZ", "ST", "TZ", "TG", "TN", "UG", "EH", "ZM", "ZW", "AI", "AG", "AR", "AW", "BS", "BB", "BZ", "BM", "BO", "BR", "VG", "CA", "KY", "CL", "CO", "CR", "CU", "DM", "DO", "EC", "SV", "FK", "GF", "GL", "GD", "GP", "GT", "GY", "HT", "HN", "JM", "MQ", "MX", "MS", "AN", "NI", "PA", "PY", "PE", "PR", "BL", "KN", "LC", "MF", "PM", "VC", "SR", "TT", "TC", "VI", "US", "UY", "VE", "AF", "AM", "AZ", "BH", "BD", "BT", "BN", "KH", "CN", "GE", "HK", "IN", "ID", "IR", "IQ", "IL", "JP", "JO", "KZ", "KW", "KG", "LA", "LB", "MO", "MY", "MV", "MN", "MM", "NP", "NT", "KP", "OM", "PK", "PS", "YD", "PH", "QA", "SA", "SG", "KR", "LK", "SY", "TW", "TJ", "TH", "TL", "TR", "™", "AE", "UZ", "VN", "YE", "AL", "AD", "BY", "BA", "DD", "FO", "GI", "GG", "IS", "IM", "JE", "LI", "MK", "FX", "MD", "MC", "ME", "NO", "RU", "SM", "RS", "CS", "SJ", "CH", "UA", "SU", "VA", "AX", "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "GB", "AS", "AQ", "AU", "BV", "IO", "CX", "CC", "CK", "FJ", "PF", "TF", "GU", "HM", "KI", "MH", "FM", "NR", "NC", "NZ", "NU", "NF", "MP", "PW", "PG", "PN", "WS", "SB", "GS", "TK", "TO", "TV", "UM", "VU", "WF"]


const Geography = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));

    const [geoData, setGeoData] = useState({
      countriesIn: [],
      countriesEx: [],
      citiesIn: [],
      citiesEx: [],
    })
  
    const [value, setValue] = useState([]);
    const [cityData, setCityData] = useState([])
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
  
    const [countriesState, setCountriesState] = useState(true);
    const [citiesState, setCitiesState] = useState(true);
    const [countryData, setCountryData] = useState([]);
    const [selectAllCity, setSelectAllCity] = useState(false);
    const [selectAllCountry, setSelectAllCountry] = useState(false);
  
    const [treeSelect, setTreeSelect] = useState({
      checked: [],
      expanded: [],
      keyword: ''
    })

    const [targetStates, setTargetStates] = useState({
      countries: false,
      cities: false,
    })
  
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  
    const toggleOptions = () => {
      setTreeSelect(treeSelect => ({ ...treeSelect, keyword: '' }));
      setIsOptionsOpen(!isOptionsOpen);
    };
  
    const myRef = useRef();
    const cityRef = useRef();
  
    const handleClickOutside = e => {
      if (!myRef.current.contains(e.target)) {
        setIsOptionsOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    });
  
    const [countriesData, setCountriesData] = useState([])
    const [treeSelectCity, setTreeSelectCity] = useState({
      checked: [],
      expanded: [],
      keyword: ''
    })
    const [isOptionsOpenCity, setIsOptionsOpenCity] = useState(false);
  
    const handleClickCityOutside = e => {
      if (!cityRef.current.contains(e.target)) {
        // setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, keyword: '' }));
        setIsOptionsOpenCity(false);
      }
    };
  
    const toggleOptionsCity = () => {
      if (treeSelect.checked && treeSelect.checked.length > 0) {
        setIsOptionsOpenCity(!isOptionsOpenCity);
      }
    };
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickCityOutside);
      return () => document.removeEventListener("mousedown", handleClickCityOutside);
    });
  
  
    const headers = {
      "Content-Type": "application/json",
      "Accept": 'application/json',
      "Authorization": `Bearer ${userInfo.token}`
    }
  
    const body = {
      "url": '',
      "requestBody": {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
      }
    }
  
    // console.log(props)
  
  
  
    useEffect(() => {
      if (props.tabId !== 6) {
        setIsOptionsOpen(false);
      }
    }, [props.tabId])
  
    useEffect(() => {
      body.url = "http://127.0.0.1:2002/v1.0/regions/list"
      axios.post("/v1.0/connectReportAPI", body,
        {
          headers: headers
        })
        .then((res) => {
          console.log("result is ==>", res)
          if (res.data.responseData) {
            let trees = [
              createTree("Africa", res.data.responseData.Africa),
              createTree("America", res.data.responseData.Americas),
              createTree("Asia", res.data.responseData.Asia),
              createTree("Europe", res.data.responseData.Europe),
              createTree("GDPR", res.data.responseData.GDPR),
              createTree("Oceania", res.data.responseData.Oceania),
            ];
            setCountryData(trees)
            console.log(trees)
            setCountriesData([...res.data.responseData.Africa, ...res.data.responseData.Americas, ...res.data.responseData.Asia, ...res.data.responseData.Europe, ...res.data.responseData.GDPR, ...res.data.responseData.Oceania])
            if (props.networkData !== '') {
              props.targetedSelectedItems({ cities: props.networkData.citiesIn.length > 0 || props.networkData.citiesEx.length > 0 ? true : false})
              setTargetStates(targetStates => ({
                ...targetStates,
                cities: props.networkData.citiesIn.length > 0 || props.networkData.citiesEx.length > 0 ? true : false,}))
                cityRetrieved = props.networkData.citiesIn.length > 0 ? props.networkData.citiesIn : props.networkData.citiesEx.length > 0 ? props.networkData.citiesEx : [] 
            setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: props.networkData.citiesIn.length > 0 ? props.networkData.citiesIn : props.networkData.citiesEx.length > 0 ? props.networkData.citiesEx : [] }));
            if (props.networkData.countriesIn.length > 0 || props.networkData.countriesEx.length > 0) {
              getDefaultSelectedCities(props.networkData.countriesIn.length > 0 ? props.networkData.countriesIn : props.networkData.countriesEx.length > 0 ? props.networkData.countriesEx : [], [...res.data.responseData.Africa, ...res.data.responseData.Americas, ...res.data.responseData.Asia, ...res.data.responseData.Europe, ...res.data.responseData.GDPR, ...res.data.responseData.Oceania])
            }
            }
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }, []);
  
    const getCities = (countries,countryData) => {
      setCityData([])
      let countryList = []
      var array3 = countryData.filter(function (obj) {
        return countries.indexOf(obj.value) !== -1
      });
      array3.map((item, index) => {
        countryList.push(item.label)
      })
      const bodyCity = {
        "url": "http://127.0.0.1:2002/v1.0/cities/list/countrylist",
        "requestBody": {
          userID: userInfo.userID,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,
          name: userInfo.name,
          "countrylist": countryList
        }
      }
      axios.post("/v1.0/connectReportAPI", bodyCity,
        {
          headers: headers
        })
        .then((res) => {
          console.log("result is cities ==>", res)
          if (res.data.responseData) {
            let trees = []
          let selectedCities = []
          if (res.data.responseData.response.length > 0) {
            res.data.responseData.response.map((item, i) => {
              trees.push(createCityTree(item.label, item.value))
              selectedCities = [...selectedCities, ...item.value]
              cityRetrieved = selectedCities
            })
          }
          setSelectAllCity(false)
          setCityData(trees)
          countriesState ? props.selectedItems({ countriesIn: countries }) : props.selectedItems({ countriesEx: countries })
          if (treeSelectCity.checked.length > 0 && cityRetrieved.length > 0) {
            let arr1 = [treeSelectCity.checked, cityRetrieved]
            let newArr = arr1.reduce((x, y) => x.filter((z) => y.includes(z)));
            console.log(newArr)
            setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: newArr }));
            citiesState ? setGeoData(geoData => ({ ...geoData, citiesIn: newArr })) : setGeoData(geoData => ({ ...geoData, citiesEx: newArr }));
            citiesState ? props.selectedItems({ citiesIn: newArr }) : props.selectedItems({ citiesEx: newArr })
          }
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }

    const toggleSelectAllCountries = (e) => {
      setSelectAllCountry(e.target.checked)
      if (e.target.checked) {
        setTreeSelect(treeSelect => ({ ...treeSelect, checked: countriesAll }));
        countriesState ? setGeoData(geoData => ({ ...geoData, countriesIn: cityRetrieved })) : setGeoData(geoData => ({ ...geoData, countriesEx: countriesAll }));
        getCities(countriesAll, countriesData)
        setTargetStates(targetStates => ({
          ...targetStates,
          countries: true,
        }));
        props.targetedSelectedItems({ countries: true, })
      } else {
        cityRetrieved = []
        setTreeSelect(treeSelect => ({ ...treeSelect, checked: [] }));
        countriesState ? setGeoData(geoData => ({ ...geoData, countriesIn: [] })) : setGeoData(geoData => ({ ...geoData, countriesEx: [] }));
        countriesState ? props.selectedItems({ countriesIn: [] }) : props.selectedItems({ countriesEx: [] })
      }
    }
  
    const toggleSelectAllCities = (e) => {
      setSelectAllCity(e.target.checked)
      if (e.target.checked) {
        setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: cityRetrieved }));
        citiesState ? setGeoData(geoData => ({ ...geoData, citiesIn: cityRetrieved })) : setGeoData(geoData => ({ ...geoData, citiesEx: cityRetrieved }));
        citiesState ? props.selectedItems({ citiesIn: cityRetrieved }) : props.selectedItems({ citiesEx: cityRetrieved })
        setTargetStates(targetStates => ({
          ...targetStates,
          cities: true,
        }));
        props.targetedSelectedItems({ cities: true, })
        console.log(cityRetrieved)
      } else {
        setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: [] }));
        citiesState ? setGeoData(geoData => ({ ...geoData, citiesIn: [] })) : setGeoData(geoData => ({ ...geoData, citiesEx: [] }));
        citiesState ? props.selectedItems({ citiesIn: [] }) : props.selectedItems({ citiesEx: [] })
      }
    }

    const getDefaultSelectedCities = (countries, countryData) => {
      setCityData([])
      let countryList = []
      var array3 = countryData.filter(function (obj) {
        return countries.indexOf(obj.value) !== -1
      });
      array3.map((item, index) => {
        countryList.push(item.label)
      })
      const bodyCity = {
        "url": "http://127.0.0.1:2002/v1.0/cities/list/countrylist",
        "requestBody": {
          userID: userInfo.userID,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,
          name: userInfo.name,
          "countrylist": countryList
        }
      }
      axios.post("/v1.0/connectReportAPI", bodyCity,
        {
          headers: headers
        })
        .then((res) => {
          if (res.data.responseData) {
            let trees = []
            let selectedCities = []
            if (res.data.responseData.response.length > 0) {
              res.data.responseData.response.map((item, i) => {
                trees.push(createCityTree(item.label, item.value))
                selectedCities = [...selectedCities, ...item.value]
                cityRetrieved = selectedCities
              })
            }
            setCityData(trees)
            setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: props.networkData.citiesIn.length > 0 ? props.networkData.citiesIn : props.networkData.citiesEx.length > 0 ? props.networkData.citiesEx : [] }));
            setSelectedCity(props.networkData.citiesIn.length > 0 ? objectModals(props.networkData.citiesIn) : props.networkData.citiesEx.length > 0 ? objectModals(props.networkData.citiesEx) : [])
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }
  
    const createTree = (groupName, options) => {
      return {
        label: groupName,
        value: groupName,
        children: options,
      };
    };
  
    const createCityTree = (groupName, options) => {
      return {
        label: groupName,
        value: groupName,
        children: objectModalCity(options)
      };
    };
  
    const objectModalCity = (response) => {
      var dataList = []
      response.map((item, index) => {
        let obj = {}
        obj.value = item
        obj.label = item
        dataList.push(obj)
      })
      return dataList
    }

    useEffect(() => {
      if (props.networkData !== '') {
        setGeoData(props.networkData)
        setSelectedRegion(props.networkData.countriesIn.length > 0 ? objectModals(props.networkData.countriesIn) : props.networkData.countriesEx.length > 0 ? objectModals(props.networkData.countriesEx) : [])
        setTreeSelect(treeSelect => ({ ...treeSelect, checked: props.networkData.countriesIn.length > 0 ? props.networkData.countriesIn : props.networkData.countriesEx.length > 0 ? props.networkData.countriesEx : [] }));
        setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: props.networkData.citiesIn.length > 0 ? props.networkData.citiesIn : props.networkData.citiesEx.length > 0 ? props.networkData.citiesEx : [] }));
        setSelectedCity(props.networkData.citiesIn.length > 0 ? objectModals(props.networkData.citiesIn) : props.networkData.citiesEx.length > 0 ? objectModals(props.networkData.citiesEx) : [])
        setCountriesState(props.networkData.countriesIn.length > 0 ? true : props.networkData.countriesEx.length > 0 ? false : true)
        setCitiesState(props.networkData.citiesIn.length > 0 ? true : props.networkData.citiesEx.length > 0 ? false : true)
   
        setTargetStates(targetStates => ({
          ...targetStates,
          countries: props.networkData.countriesIn.length > 0 || props.networkData.countriesEx.length > 0 ? true : false,
          cities: props.networkData.citiesIn.length > 0 || props.networkData.citiesEx.length > 0 ? true : false,
        }));
  
        props.targetedSelectedItems({
          countries: props.networkData.countriesIn.length > 0 || props.networkData.countriesEx.length > 0 ? true : false,
          cities: props.networkData.citiesIn.length > 0 || props.networkData.citiesEx.length > 0 ? true : false,
        })
      }
    }, []);

    useEffect(() => {
      if (treeSelect.checked.length === 0) {
        setSelectAllCity(false)
        setCityData([])
        setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: [] }));
        citiesState ? setGeoData(geoData => ({ ...geoData, citiesIn: [] })) : setGeoData(geoData => ({ ...geoData, citiesEx: [] }));
        citiesState ? props.selectedItems({ citiesIn: [] }) : props.selectedItems({ citiesEx: [] })
        cityRetrieved = []
        setTargetStates(targetStates => ({
          ...targetStates,
          cities: false, 
        }));
        props.targetedSelectedItems({ cities: false,})
      } else {
        setTargetStates(targetStates => ({
          ...targetStates, countries: true
        }));
        props.targetedSelectedItems({  countries: true })
      }
    }, [treeSelect.checked.length])
  
    const objectModals = (response) => {
      var dataList = []
      response.map((item, index) => {
        let obj = {}
        obj.value = item
        obj.label = item
        dataList.push(obj)
      })
      return dataList
    }

  
    const countryStateChange = (e) => {
      setCountriesState(e.target.checked)
      var countriess = []
      if (geoData.countriesIn.length > 0) {
        countriess = geoData.countriesIn
      } else {
        countriess = geoData.countriesEx
      }
      if (e.target.checked) {
        setGeoData(geoData => ({ ...geoData, countriesIn: countriess, countriesEx: [] }));
        props.selectedItems({ countriesIn: countriess, countriesEx: [] })
      } else {
        setGeoData(geoData => ({ ...geoData, countriesEx: countriess, countriesIn: [] }));
        props.selectedItems({ countriesEx: countriess, countriesIn: [] })
      }
    }
  
    const cityStateChange = (e) => {
      setCitiesState(e.target.checked)
      var citiess = []
      if (geoData.citiesIn.length > 0) {
        citiess = geoData.citiesIn
      } else {
        citiess = geoData.citiesEx
      }
      if (e.target.checked) {
        setGeoData(geoData => ({ ...geoData, citiesIn: citiess, citiesEx: [] }));
        props.selectedItems({ citiesIn: citiess, citiesEx: [] })
      } else {
        setGeoData(geoData => ({ ...geoData, citiesEx: citiess, citiesIn: [] }));
        props.selectedItems({ citiesEx: citiess, citiesIn: [] })
      }
    }
  
  
    const groupSelect = (options) => (e) => {
      if (e.target.checked) {
        var countryList = []
        options.map((item, index) => {
          countryList.push(item.value)
        })
        countriesState ? setGeoData(geoData => ({ ...geoData, countriesIn: countryList })) : setGeoData(geoData => ({ ...geoData, countriesEx: countryList }))
        countriesState ? props.selectedItems({ countriesIn: countryList }) : props.selectedItems({ countriesEx: countryList })
        let arr = value
        let arr2 = []
        options.map((item, index) => {
          arr.push(item)
        })
        const ids = arr.map(o => o.value)
        const filtered = arr.filter(({ value }, index) => !ids.includes(value, index + 1))
        setValue(filtered);
      } else {
        let myArray = value.filter(ar => !options.find(rm => (rm.label === ar.label && ar.value === rm.value)))
        setValue(myArray)
      }
    }
  
    const onSearchInputChange = (event, data, searchedNodes) => {
      console.log(event, data, searchedNodes)
      setTreeSelect(treeSelect => {
        if (treeSelect.keyword.trim() && !data.value.trim()) {
          return {
            ...treeSelect,
            expanded: [],
            keyword: data.value
          };
        }
        return {
          ...treeSelect,
          expanded: getAllValuesFromNodes(searchedNodes, true),
          keyword: data.value
        };
      });
    };
  
    const onSearchInputCityChange = (event, data, searchedNodes) => {
      console.log(event, data, searchedNodes)
      setTreeSelectCity(treeSelectCity => {
        if (treeSelectCity.keyword.trim() && !data.value.trim()) {
          return {
            ...treeSelectCity,
            expanded: [],
            keyword: data.value
          };
        }
        return {
          ...treeSelectCity,
          expanded: getAllValuesFromNodes(searchedNodes, true),
          keyword: data.value
        };
      });
    };
  
    const getAllValuesFromNodes = (nodes, firstLevel) => {
      if (firstLevel) {
        const values = [];
        for (let n of nodes) {
          values.push(n.value);
          if (n.children) {
            values.push(...getAllValuesFromNodes(n.children, false));
          }
        }
        return values;
      } else {
        const values = [];
        for (let n of nodes) {
          values.push(n.value);
          if (n.children) {
            values.push(...getAllValuesFromNodes(n.children, false));
          }
        }
        return values;
      }
    };
  
    const keywordFilter = (nodes, keyword) => {
      let newNodes = [];
      for (let n of nodes) {
        if (n.children) {
          const nextNodes = keywordFilter(n.children, keyword);
          if (nextNodes.length > 0) {
            n.children = nextNodes;
          } else if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
            n.children = nextNodes.length > 0 ? nextNodes : [];
          }
          if (
            nextNodes.length > 0 ||
            n.label.toLowerCase().includes(keyword.toLowerCase())
          ) {
            console.log("entered",n.label, keyword)
            n.label = getHighlightText(n.label, keyword);
            newNodes.push(n);
          }
        } else {
          if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
            n.label = getHighlightText(n.label, keyword);
            newNodes.push(n);
          }
        }
      }
      if (newNodes.length === 1) {
        let regions = countryData
        regions.map((item, index) => {
          if (item.value.toLowerCase() === newNodes[0].value.toLowerCase()) {
            newNodes[0].children = item.children
          }
        })
      }
      return newNodes;
    };

    const getHighlightText = (text, keyword) => {
      console.log(text, keyword)
      const startIndex = (text.toLowerCase()).indexOf(keyword.toLowerCase());
      {console.log("redtext"+text.substring(startIndex, startIndex + keyword.length),startIndex)}
      return startIndex !== -1 ? (
        <span>
          {text.substring(0, startIndex)}
          <span style={{ color: "red" }}>
            {console.log("redtext"+text.substring(startIndex, startIndex + keyword.length))}
            {text.substring(startIndex, startIndex + keyword.length)}
          </span>
          {text.substring(startIndex + keyword.length)}
        </span>
      ) : (
        <span> {text}</span>
      );
    };
  
    const keywordFilterCity = (nodes, keyword) => {
      let newNodes = [];
      for (let n of nodes) {
        if (n.children) {
          const nextNodes = keywordFilterCity(n.children, keyword);
          if (nextNodes.length > 0) {
            n.children = nextNodes;
          } else if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
            n.children = nextNodes.length > 0 ? nextNodes : [];
          }
          if (
            nextNodes.length > 0 ||
            n.label.toLowerCase().includes(keyword.toLowerCase())
          ) {
            n.label = getHighlightText(n.label, keyword);
            newNodes.push(n);
          }
        } else {
          if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
            n.label = getHighlightText(n.label, keyword);
            newNodes.push(n);
          }
        }
      }
      if (newNodes.length === 1) {
        let countries = cityData
        countries.map((item, index) => {
          if (item.value.toLowerCase() === newNodes[0].value.toLowerCase()) {
            newNodes[0].children = item.children
          }
        })
      }
      return newNodes;
    };
  

  
  
    const checkValue = (row, checked) => {
      if (row.isParent) {
        if (row.checked) {
          let finalChecked = [...treeSelect.checked, ...checked]
          finalChecked = finalChecked.filter((item, index) => finalChecked.indexOf(item) === index)
          setTreeSelect(treeSelect => ({ ...treeSelect, checked: finalChecked }));
          countriesState ? setGeoData(geoData => ({ ...geoData, countriesIn: finalChecked })) : setGeoData(geoData => ({ ...geoData, countriesEx: finalChecked }));
          // countriesState ? props.selectedItems({ countriesIn: finalChecked }) : props.selectedItems({ countriesEx: finalChecked })
          if (finalChecked.length > 0) {
            getCities(finalChecked, countriesData)
          }
        } else {
          let finalChecked = [...treeSelect.checked]
          let filteredChildren = row.children.map(a => a.value);
          let result = finalChecked.filter(val => !filteredChildren.includes(val));
          setTreeSelect(treeSelect => ({ ...treeSelect, checked: result }));
          countriesState ? setGeoData(geoData => ({ ...geoData, countriesIn: result })) : setGeoData(geoData => ({ ...geoData, countriesEx: result }));
          // countriesState ? props.selectedItems({ countriesIn: result }) : props.selectedItems({ countriesEx: result })
          if (result.length > 0) {
            getCities(result, countriesData)
          }
        }
      } else {
        if (row.checked) {
          let finalChecked = [...treeSelect.checked, ...checked]
          finalChecked = finalChecked.filter((item, index) => finalChecked.indexOf(item) === index)
          setTreeSelect(treeSelect => ({ ...treeSelect, checked: finalChecked }));
          countriesState ? setGeoData(geoData => ({ ...geoData, countriesIn: finalChecked })) : setGeoData(geoData => ({ ...geoData, countriesEx: finalChecked }));
          // countriesState ? props.selectedItems({ countriesIn: finalChecked }) : props.selectedItems({ countriesEx: finalChecked })
          if (finalChecked.length > 0) {
            getCities(finalChecked, countriesData)
          }
        } else {
          let finalChecked = [...treeSelect.checked]
          let index = finalChecked.indexOf(row.value)
          finalChecked.splice(index, 1)
          setTreeSelect(treeSelect => ({ ...treeSelect, checked: finalChecked }));
          countriesState ? setGeoData(geoData => ({ ...geoData, countriesIn: finalChecked })) : setGeoData(geoData => ({ ...geoData, countriesEx: finalChecked }));
          // countriesState ? props.selectedItems({ countriesIn: finalChecked }) : props.selectedItems({ countriesEx: finalChecked })
          if (finalChecked.length > 0) {
            getCities(finalChecked, countriesData)
          }
        }
      }
    }
  
    const checkCityValue = (row, checked) => {
      if (row.isParent) {
        if (row.checked) {
          let finalChecked = [...treeSelectCity.checked, ...checked]
          finalChecked = finalChecked.filter((item, index) => finalChecked.indexOf(item) === index)
          setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: finalChecked }));
          citiesState ? setGeoData(geoData => ({ ...geoData, citiesIn: finalChecked })) : setGeoData(geoData => ({ ...geoData, citiesEx: finalChecked }));
          citiesState ? props.selectedItems({ citiesIn: finalChecked }) : props.selectedItems({ citiesEx: finalChecked })
        } else {
          let finalChecked = [...treeSelectCity.checked]
          let filteredChildren = row.children.map(a => a.value);
          let result = finalChecked.filter(val => !filteredChildren.includes(val));
          setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: result }));
          citiesState ? setGeoData(geoData => ({ ...geoData, citiesIn: result })) : setGeoData(geoData => ({ ...geoData, citiesEx: result }));
          citiesState ? props.selectedItems({ citiesIn: result }) : props.selectedItems({ citiesEx: result })
        }
      } else {
        if (row.checked) {
          let finalChecked = [...treeSelectCity.checked, ...checked]
          finalChecked = finalChecked.filter((item, index) => finalChecked.indexOf(item) === index)
          setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: finalChecked }));
          citiesState ? setGeoData(geoData => ({ ...geoData, citiesIn: finalChecked })) : setGeoData(geoData => ({ ...geoData, citiesEx: finalChecked }));
          citiesState ? props.selectedItems({ citiesIn: finalChecked }) : props.selectedItems({ citiesEx: finalChecked })
        } else {
          let finalChecked = [...treeSelectCity.checked]
          let index = finalChecked.indexOf(row.value)
          finalChecked.splice(index, 1)
          setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: finalChecked }));
          citiesState ? setGeoData(geoData => ({ ...geoData, citiesIn: finalChecked })) : setGeoData(geoData => ({ ...geoData, citiesEx: finalChecked }));
          citiesState ? props.selectedItems({ citiesIn: finalChecked }) : props.selectedItems({ citiesEx: finalChecked })
        }
      }
    }
  
    const handleCheck = e => {
      const { name, value } = e.target;
      setTargetStates(targetStates => ({ ...targetStates, [name]: e.target.checked }));
      props.targetedSelectedItems({ [name]: e.target.checked })
      if (name === 'countries') {
        setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: [] }));
        setGeoData(geoData => ({ ...geoData, citiesEx: [], citiesIn: [] }));
        props.selectedItems({ citiesEx: [], citiesIn: [] })
        cityRetrieved = []
        setTreeSelect(treeSelect => ({ ...treeSelect, checked: [] }));
        setGeoData(geoData => ({ ...geoData, countriesEx: [], countriesIn: [] }));
        props.selectedItems({ countriesEx: [], countriesIn: [] })
      } if (name === 'cities') {
        setSelectAllCity(false)
        setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, checked: [] }));
        setGeoData(geoData => ({ ...geoData, citiesEx: [], citiesIn: [] }));
        props.selectedItems({ citiesEx: [], citiesIn: [] })
      }
    }
  
  
    return (
      <Row>
        <Col lg="11">
        <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="countries" id='countries' checked={targetStates.countries} onChange={handleCheck}  />
              <Label className='m-3 mt-0 ' htmlFor='countries'>Country Targeting</Label>
            </div>
            {countryData.length > 0 && targetStates.countries &&
              <div className="form-group d-flex mt-1">
                <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="countriesSelectAll" id='countriesSelectAll' checked={(selectAllCountry && treeSelect.checked.length === 252)} onChange={toggleSelectAllCountries} disabled={props.selectedArchived} />
                <Label className='m-3 mt-0 ' htmlFor='countriesSelectAll'>Select All </Label>
              </div>
            }
            <div className='row checkbox-tree rmsc'>
              <div className='col-7' ref={myRef}>

                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isOptionsOpen}
                  className={'selectionButton ' + (isOptionsOpen ? "expanded" : "")}
                  onClick={toggleOptions}
                  disabled={props.selectedArchived || !targetStates.countries}
                >
                  {treeSelect.checked && treeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{treeSelect.checked.length + " Countries are Selected"}</span> : "Please Select Countries"}
                </button>
                <Input
                  style={{ display: isOptionsOpen ? 'block' : 'none' }}
                  placeholder="Search Countries..."
                  className='mt-1'
                  value={treeSelect.keyword}
                  onChange={(event, data) => {
                    onSearchInputChange(event, event.target, treeSelect.keyword.trim()
                      ? keywordFilter(_.cloneDeep(countryData), treeSelect.keyword.trim())
                      : countryData);
                  }}
                />
                <ul
                  className={`options ${isOptionsOpen ? "show" : ""}`}
                  role="listbox"
                  tabIndex={-1}
                >
                  <CheckboxTree
                    nodes={treeSelect.keyword.trim()
                      ? keywordFilter(_.cloneDeep(countryData), treeSelect.keyword.trim())
                      : countryData}
                    checked={treeSelect.checked}
                    expanded={treeSelect.expanded}
                    onCheck={(checked, row) => {
                      checkValue(row, checked)
                    }}
                    onExpand={expanded => setTreeSelect(treeSelect => ({ ...treeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={countriesState}
                  onChange={countryStateChange}
                  disabled={props.selectedArchived || !targetStates.countries}
                />
                <label className="form-check-label">
                  {countriesState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>


          </div>
          <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="cities" id='cities' checked={targetStates.cities} onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='cities'>City Targeting</Label>
            </div>
            {cityData.length > 0 && targetStates.cities &&
              <div className="form-group d-flex mt-1">
                <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="citiesSelectAll" id='citiesSelectAll' checked={(selectAllCity && props.networkData !== '' ? (cityRetrieved.length === (props.networkData.citiesIn.length  || props.networkData.citiesEx.length) ? true : false) : treeSelectCity.checked.length === cityRetrieved.length ? true : false)} onChange={toggleSelectAllCities} disabled={props.selectedArchived} />
                <Label className='m-3 mt-0 ' htmlFor='citiesSelectAll'>Select All </Label>
              </div>
            }
            {/* {cityData.length > 0 && selectAllCity > 0 &&
              <div className="form-group d-flex mt-1">
                <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="citiesUnSelectAll" id='citiesUnSelectAll' checked={selectAllCity} onChange={toggleSelectAllCities} />
                <Label className='m-3 mt-0 ' htmlFor='citiesUnSelectAll'>UnSelect All Cities</Label>
              </div>
            } */}
            <div className='row checkbox-tree rmsc'>

              <div className='col-7' ref={cityRef}>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isOptionsOpenCity}
                  className={'selectionButton ' + (isOptionsOpenCity ? "expanded" : "")}
                  onClick={toggleOptionsCity}
                  disabled={props.selectedArchived || !targetStates.cities}
                >
                  {treeSelectCity.checked && treeSelectCity.checked.length > 0 ? <span style={{ color: 'black' }}>{treeSelectCity.checked.length + " Cities are Selected"}</span> : "Please Select Cities"}
                </button>
                <Input
                  style={{ display: isOptionsOpenCity ? 'block' : 'none' }}
                  placeholder="Search Cities..."
                  className='mt-1'
                  value={treeSelectCity.keyword}
                  onChange={(event, data) => {
                    onSearchInputCityChange(event, event.target, treeSelectCity.keyword.trim()
                      ? keywordFilterCity(_.cloneDeep(cityData), treeSelectCity.keyword.trim())
                      : cityData);
                  }}
                />
                <ul
                  className={`options ${isOptionsOpenCity ? "show" : ""}`}
                  role="listbox"
                  tabIndex={-1}
                >
                  <CheckboxTree
                    nodes={treeSelectCity.keyword.trim()
                      ? keywordFilterCity(_.cloneDeep(cityData), treeSelectCity.keyword.trim())
                      : cityData}
                    checked={treeSelectCity.checked}
                    expanded={treeSelectCity.expanded}
                    onCheck={(checked, row) => {
                      checkCityValue(row, checked)
                    }}
                    onExpand={expanded => setTreeSelectCity(treeSelectCity => ({ ...treeSelectCity, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>


              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={citiesState}
                  onChange={cityStateChange}
                  disabled={props.selectedArchived || !targetStates.cities}
                />
                <label className="form-check-label">
                  {citiesState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
  
        </Col>
  
      </Row>
    )
  }

Geography.propTypes = {}

export default Geography