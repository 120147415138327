/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from "axios";
import {
  Row, Col, Input, Label, Form, FormFeedback,
} from "reactstrap";
import Select from "react-select";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  getAdvertisers as onGetAdvertisers,
} from "store/Demand/Advertiser/actions";

import {
  getIOGroups as onGetIOGroups,
} from "store/Demand/IOGroup/actions";

import {
  getIOs as onGetIOss,
} from "store/Demand/IOs/actions";

import {
  getLineItem as onGetLineItems,
} from "store/Demand/LineItem/actions";

// actions
import {
  readCreative as onReadCreative,
} from "store/Demand/Creative/actions";

const Hierarchy_Selection = (props) => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [selectiveData, setSelectiveData] = useState({
    advertiserID: '',
    iOGroupID: '',
    iOID: '',
  });
  const [advertiserData, setAdvertiserData] = useState([])
  const [iOGroupData, setIOGroupData] = useState([])
  const [iOSData, setIOSData] = useState([])
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const [selectedIOGroup, setSelectedIOGroup] = useState(null);
  const [selectedIOs, setSelectedIOs] = useState(null);

  console.log(props)
  // console.log(props.inventoryTypeName)

  useEffect(() => {
    dispatch(onReadCreative(null));
    dispatch(onGetIOGroups(null));
    dispatch(onGetIOss(null));
  }, [])

  useEffect(() => {
    if (props.selectedLineItem !== '' && props.selectedCreativeData === '') {
      getParentCreative()
    }
  }, []);

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const { advertisers, iOGroups, iOSs } = useSelector(state => ({
    advertisers: state.advertisers.advs,
    iOGroups: state.iOGroups.iOGroups,
    iOSs: state.iOSs.iOSs
  }));

  const getParentCreative = (IOGroupId, IOId, lineItemId) => {
    var filterBody = {
      url:(userInfo.userRole === 'Partner'|| userInfo.userRole === 'Agency') ? 'http://127.0.0.1:3006/v1.0/demand/getParent' : "http://127.0.0.1:2002/v1.0/demand/getParent",
      requestBody: {
        "advertiserID": 0,
        "iOGroupID": 0,
        "iOID": 0,
        "lineItemID": 0,
        "creativeID": 0,
        "videoCreativeID": 0,
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
      }
    }
    if(userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency'){
      filterBody.requestBody = {...filterBody.requestBody,partnerName:userInfo.name,partnerID:userInfo.userID}
  }
    if (IOGroupId) {
      filterBody.requestBody.iOGroupID = IOGroupId
    }
    if (IOId) {
      filterBody.requestBody.iOID = IOId
    }
    axios.post(userInfo.userRole === 'Partner' ? "/v1.0/connectPartner" :userInfo.userRole === 'Agency' ? "/v1.0/connectPartner" : "/v1.0/connectOP", filterBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("cdsresult is ==>", res)
        if (res.data.responseData) {
          setSelectedAdvertiser({ label: res.data.responseData.advertiserName, value: res.data.responseData.advertiserID });
          setSelectedIOGroup({ label: res.data.responseData.iOGroupName, value: res.data.responseData.iOGroupID });
          if (!IOGroupId) {
            setSelectedIOs({ label: res.data.responseData.iOName, value: res.data.responseData.iOID });
          }
        }
      })
      .catch((err) => {
        // console.log("e", err)
      });
  }

  useEffect(() => {
    if (advertisers !== null) {
      if (props.moduleType === "IOGroup" || props.moduleType === "IOS" || props.moduleType === "LI") {
        dispatch(onGetAdvertisers({ filter: 'Active', userRole:userInfo.userRole,userID:userInfo.userID, type: props.inventoryType }));
      }
    }
  }, []);

  useEffect(() => {
    if (iOGroups !== null && iOSs !== null) {
      if (props.moduleType === "IOS" || props.moduleType === "LI") {
        dispatch(onGetIOGroups({ advertiserID: '', filter: 'Active', type: props.inventoryType, userRole:userInfo.userRole,userID:userInfo.userID }));
      }
      if (props.moduleType === "LI") {
        dispatch(onGetIOss({ iOGroupID: '', filter: 'Active', iOType: props.inventoryType, userRole:userInfo.userRole,userID:userInfo.userID }));
      }
    }
  }, []);

  useEffect(() => {
    if (advertisers && advertisers.length > 0) {
      setAdvertiserData(objectModal(advertisers))
    }
  }, [advertisers]);

  useEffect(() => {
    if (iOGroups && iOGroups.length > 0) {
      setIOGroupData(objectIOGroupModal(iOGroups))
    }
  }, [iOGroups]);

  useEffect(() => {
    if (iOSs && iOSs.length > 0) {
      setIOSData(objectIOsModal(iOSs))
    }
  }, [iOSs]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.advertiserID
      obj.label = item.advertiserName
      dataList.push(obj)
    })
    return dataList
  }

  const objectIOGroupModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.iOGroupID
      obj.label = item.iOGroupName
      dataList.push(obj)
    })
    return dataList
  }

  const objectIOsModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.iOID
      obj.label = item.iOName
      dataList.push(obj)
    })
    return dataList
  }

  function handleSelectAdvertiser(selectAdvertiser) {
    setSelectiveData(selectiveData => ({ ...selectiveData, advertiserID: selectAdvertiser.value }));
    setSelectedAdvertiser(selectAdvertiser);
    props.selectedItems({ advertiserID: selectAdvertiser.value })
    if (props.moduleType === "IOS" || props.moduleType === "LI") {
      dispatch(onGetIOGroups({ advertiserID: selectAdvertiser.value, filter: 'Active', type: props.inventoryType, userRole:userInfo.userRole,userID:userInfo.userID }));
      setSelectedIOGroup(null);
      setSelectedIOs(null);
    }
  }

  function handleSelectIOGroup(selectIOGroup) {
    getParentCreative(selectIOGroup.value, '', '')
    setSelectiveData(selectiveData => ({ ...selectiveData, iOGroupID: selectIOGroup.value }));
    setSelectedIOGroup(selectIOGroup);
    props.selectedItems({ iOGroupID: selectIOGroup.value })
    if (props.moduleType === "LI") {
      dispatch(onGetIOss({ iOGroupID: selectIOGroup.value, filter: 'Active', iOType: props.inventoryType, userRole:userInfo.userRole,userID:userInfo.userID }));
      setSelectedIOs(null);
    }
  }

  function handleSelectIOs(selectIOs) {
    getParentCreative('', selectIOs.value, '')
    setSelectiveData(selectiveData => ({ ...selectiveData, iOID: selectIOs.value }));
    setSelectedIOs(selectIOs);
    props.selectedItems({ iOID: selectIOs.value })
    dispatch(onGetLineItems({ iOID: selectIOs.value, filter: 'Active', lineItemType: props.inventoryType, priority: '', userRole:userInfo.userRole,userID:userInfo.userID }));
  }

  useEffect(() => {
    if (props.selectedData!== '') {
      if(props.moduleType === "IOS"){
        getParentCreative(props.selectedData.iOGroupID, '', '')
      }
      if(props.moduleType === "IOGroup"){
        setSelectedAdvertiser({value: props.selectedData.advertiserId,label:props.selectedData.advertiserName});
      }
    }
  }, []);

  return (

    <React.Fragment>
      <div className="col-12">
        <Row form>
          <Col xs={12}>
            {(props.moduleType === "IOGroup" || props.moduleType === "IOS" || props.moduleType === "LI") &&
              <div className="mb-3">
                <Label>Advertiser <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Select
                  value={selectedAdvertiser}
                  onChange={(s) => {
                    handleSelectAdvertiser(s);
                  }}
                  options={advertiserData}
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived || props.creativeStatus}
                  className={(props.submitted && !selectiveData.advertiserID ? ' is-invalid' : '')}
                />
                {props.submitted && !selectiveData.advertiserID &&
                  <div className="invalid-feedback">{'Please Select Advertiser'}</div>}
              </div>
            }
            {(props.moduleType === "IOS" || props.moduleType === "LI") &&

              <div className="mb-3">
                <Label>Insertion order Group <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Select
                  value={selectedIOGroup}
                  onChange={(s) => {
                    handleSelectIOGroup(s);
                  }}
                  options={iOGroupData}
                  className={(props.submitted && !selectiveData.iOGroupID ? ' is-invalid' : '')}
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived || props.creativeStatus}
                />

                {props.submitted && !selectiveData.iOGroupID &&
                  <div className="invalid-feedback">{'Please Select IOGroup'}</div>}
              </div>
            }
            {props.moduleType === "LI" &&
              <div className="mb-3">
                <Label>Insertion order <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Select
                  value={selectedIOs}
                  onChange={(s) => {
                    handleSelectIOs(s);
                  }}
                  options={iOSData}
                  classNamePrefix="select2-selection"
                  className={(props.submitted && !selectiveData.iOID ? ' is-invalid' : '')}
                  isDisabled={props.selectedArchived || props.creativeStatus}
                />

                {props.submitted && !selectiveData.iOID &&
                  <div className="invalid-feedback">{'Please Select Insertion Order'}</div>}   </div>
            }
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Hierarchy_Selection
