import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label
} from "reactstrap"
import queryString from 'query-string';

import axios from "axios";

import DateRangePickers from 'helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getPublisher as onGetPublishers,
  statusPublisher as onStatusPublisher,
  archivePublisher as onArchivePublisher,
  statusPublisherSuccess,
  archivePublisherSuccess,
  addPublisherFail,
  updatePublisherFail,
} from "store/Supply/Publisher/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";
import Publisher_Supply_BreadCrumb from "components/Common/Publisher_Supply_BreadCrumb";
import LoadingTable from "pages/LoaderPages/LoadingTable";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var filterCount = 0

const PublisherConsole_Publisher = () => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [statusSearch, setStatusSearch] = useState('Active')
  const [selected, setSelected] = useState([])
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [publishers, setPublishers] = useState([])
  const [switchLoader, setSwitchLoader] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const applyFilter = (e) => {
    getPublishers(statusSearch)
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setStatusSearch('Active')
    filterCount = 0
    setFilterState(false)
     getPublishers('Active')
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000
  };

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.publisherID)
        selectedItem.push(row.publisherID)
        console.log(selectedIndex)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.publisherID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.publisherID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.publisherID)
          selectedItem.push(item.publisherID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }
    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
  }

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const getPublishers = (status) => {
    setLoading(true)
    const body = {
      "url": "http://127.0.0.1:3006/v1.0/Publishers/read/users/" + userInfo.userID,
      "requestBody": {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        partnerName: userInfo.name,
        filter: status,
        "type": ""
      }
    }
    axios.post("/v1.0/connectPartner", body,
      {
        headers: headers
      })
      .then((res) => {
        setLoading(false)
        console.log("result is Publishers ==>", res)
        if (res.data.responseData.success) {
          res.data.responseData.response.map((ele, index) => {
            ele.id = index + 1
          })
          setPublishers(res.data.responseData.response)

        } else {
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log("e", err)
      });
  }


  useEffect(() => {
    if (publishers !== null) {
      getPublishers(statusSearch)
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const publisherListColumns = [
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "publisherID",
      sort: true,
    },
    {
      dataField: "publisherCreateTime",
      text: "Creation Date",
      sort: true,
    },
    {
      text: "Publisher Name",
      dataField: "publisherName",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, publisher) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to={`/Publisher_AdUnitGroup?publisherId=${publisher.publisherID}&publisherName=${publisher.publisherName}&status=AdUnitGroupCreateByParent`} className="color-dark-blue ">
              {publisher.publisherName}
            </Link>
          </h5>
        </>
      ),
    },

    {
      dataField: "publisherStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, publisher) => (
        <>
          <span className={publisher.newStatus === 'Active' ? "active-state" : publisher.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{publisher.newStatus}</span>
        </>
      ),
    }
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  console.log(publishers)

  if (publishers.length > 0) {
    publishers.map((item, index) => {
      if (item.isPublisherArchived) {
        selectRow.nonSelectable.push(index + 1)
      }
    })
  }

  useEffect(() => {
    console.log(loading)
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);
  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
        <div className="page-content">
          <MetaTags>
            <title>Publisher | Bidsxchange</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}

            <div className=" d-sm-flex  justify-content-between">
              <SupplyBreadCrumb title="Supply" breadcrumbItem="Publisher" breadList="All Publishers" />
              <div className=" text-sm-end">
                <div className="btn-group">
                  <Dropdown
                    direction="left"
                    isOpen={isMenu}
                    toggle={toggleMenu}
                  >
                    <DropdownToggle
                      tag="button"
                      className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                    >
                      <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                      {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                        Filter Applied
                      </span> : <span className="d-none font-16 d-sm-inline-block">
                        Filter
                      </span>
                      }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                      <div className="dropdown-item-text">
                        <div className="row">
                          <h5 className="mb-0">Filters</h5>
                        </div>
                      </div>

                      <DropdownItem divider />
                      <Card>
                        <CardBody aria-hidden="true">
                          <Row >
                            <Col sm="12" className="mt-2">
                              <Label className="form-label">Status</Label>
                              <div className=" text-sm-end">
                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                  <option value=''> All</option>
                                  <option value='Active' > Active</option>
                                  <option value='Inactive'> InActive</option>
                                  <option value='Archived'> Archived</option>
                                </Input>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <DropdownItem divider />
                      <Row>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={clearAllFilter}
                          >
                            Clear ALL
                          </DropdownItem>
                        </Col>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={applyFilter}
                          >
                            Apply Filter
                          </DropdownItem>
                        </Col>
                      </Row>

                    </DropdownMenu>
                  </Dropdown>
                </div>
                </div>
            </div>
            {switchLoader &&
              <LoadingTable loading={loading} targetLoading={handleSwitch} />
            }
            {!switchLoader &&
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                            <ToolkitProvider
                              keyField={keyField}
                              data={publishers}
                              columns={publisherListColumns}
                              // bootstrap4
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col sm="8">
                                      <div className="search-box ms-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar {...toolkitProps.searchProps} />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12" className="table-responsive">
                                      <div >
                                        <BootstrapTable
                                          keyField={keyField}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          pagination={paginationFactory(pageOptions)}
                                          noDataIndication={<h5 className="text-center">No Data</h5>}
                                        // ref={node}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            }
          </Container>
        </div>
      {/* </div> */}


    </React.Fragment>
  )
}

export default PublisherConsole_Publisher