import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col,} from "reactstrap"
import $ from "jquery";

const PagesMaintenance = () => {
  useEffect(() => {
    localStorage.removeItem('authUser');
    localStorage.removeItem('UserRole');
    localStorage.removeItem('ParentRole');
    localStorage.removeItem('Terms');
    localStorage.removeItem('NotificationStatus');
    localStorage.removeItem('UserRolePrivileges');
    localStorage.removeItem('AdminRolePrivileges');
    localStorage.removeItem('previousNavigation');
    localStorage.removeItem('UserSwitching');
    localStorage.removeItem('AdminDetails');
    localStorage.removeItem('activeTab');
    localStorage.removeItem('InventoryType');
    localStorage.removeItem('userSessionState');
    localStorage.removeItem('darkMode');
    localStorage.setItem("loginStatus", 'LoggedOut');
    localStorage.setItem("alertStatus", 'closed');
  }, []);
  //meta title
  document.title = "Maintenance | Bidsxchange ";

  function sendApiRequest() {
    $.ajax({
      url: 'https://isalive.bidsxchange.com',
      method: 'POST',
      success: function (response) {
        console.log(response)
        if (response.isAlive) {
          window.location = "https://nino.bidsxchange.com";
        }
      },
      error: function (xhr, status, error) {
      }
    });
  }

  const interval = 10000;
  setInterval(sendApiRequest, interval);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Maintenance | Bidsxchange </title>
      </MetaTags>
      <section className=" pt-2" style={{ textAlign: 'center', padding: 0, background: '#d6433b', color: '#fff', height: '100vh', fontFamily: 'Open Sans',display:'flex',alignItems:'center' }}>
        <Container>
          <Row>
            <Col className="col-12 ">
              <div className="home-wrapper" style={{ color: '#fff', marginTop: '15pc', display: 'block', padding: '50px', margin: '0 auto' }}>

                <Row className="justify-content-center">
                    <div className="maintenance-img" style={{width: '100px', marginTop: '0.5em',marginBottom:'5em'}}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.24 202.24"><defs>
                        </defs>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Capa_1" data-name="Capa 1">
                            <path fill="#fff" className="cls-1" d="M101.12,0A101.12,101.12,0,1,0,202.24,101.12,101.12,101.12,0,0,0,101.12,0ZM159,148.76H43.28a11.57,11.57,0,0,1-10-17.34L91.09,31.16a11.57,11.57,0,0,1,20.06,0L169,131.43a11.57,11.57,0,0,1-10,17.34Z" /><path fill="#fff" className="cls-1" d="M101.12,36.93h0L43.27,137.21H159L101.13,36.94Zm0,88.7a7.71,7.71,0,1,1,7.71-7.71A7.71,7.71,0,0,1,101.12,125.63Zm7.71-50.13a7.56,7.56,0,0,1-.11,1.3l-3.8,22.49a3.86,3.86,0,0,1-7.61,0l-3.8-22.49a8,8,0,0,1-.11-1.3,7.71,7.71,0,1,1,15.43,0Z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                </Row>
                <h1 className="  " style={{ fontSize: '50px',fontWeight:100,marginBottom:'1em',color: '#fff'}}>We&rsquo;ll be back soon! </h1>
                <p className="  mt-3" style={{ fontSize: '20px',fontWeight:100,marginBottom:'1em',color: '#fff' }}>We&apos;re sorry for the inconvenience but We&apos;re undergoing maintenance. </p>
                <p className="mt-2" style={{ fontSize: '20px',fontWeight:100,color: '#fff' }}>&mdash; Team Streamlyn</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default PagesMaintenance
