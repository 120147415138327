import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_HIGH_IMPACT_SETTINGS,ADD_HIGH_IMPACT_SETTING , UPDATE_HIGH_IMPACT_SETTING,READ_HIGH_IMPACT_SETTING,STATUS_HIGH_IMPACT_SETTING,ARCHIVE_HIGH_IMPACT_SETTING} from "./actionTypes"

import {
  loadActivitiesHighImpactSetting,
  noDataHighImpactSetting,
  getHighImpactSettingsSuccess,
  getHighImpactSettingsFail,
  addHighImpactSettingSuccess,
  addHighImpactSettingFail,
  updateHighImpactSettingSuccess,
  updateHighImpactSettingFail,
  readHighImpactSettingSuccess,
  statusHighImpactSettingSuccess,
  archiveHighImpactSettingSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getHighImpactSetting,getHighImpactSettingsByAdUnit,createHighImpactSetting,updateHighImpactSetting,readHighImpactSetting,statusChangeHighImpactSetting,archiveHighImpactSetting } from "../../../helpers/Backend_helper"

function* fetchHighImpactSettings({ payload: highImpactSetting }) {
  try {
    yield put(loadActivitiesHighImpactSetting(true))
    var getHighImpactSettingList
    if(highImpactSetting && highImpactSetting.adUnitID !== ''){
      getHighImpactSettingList = yield call(getHighImpactSettingsByAdUnit,highImpactSetting)
      getHighImpactSettingList.responseData.response.map((item, index) => {
        item.id = index + 1
    })
    if(getHighImpactSettingList.responseData.response.length === 0){
      yield put(noDataHighImpactSetting(true))
    }
      yield put(getHighImpactSettingsSuccess(getHighImpactSettingList.responseData))
    }else if(highImpactSetting && highImpactSetting.adUnitID === ''){
      delete highImpactSetting.adUnitID
      getHighImpactSettingList = yield call(getHighImpactSetting,highImpactSetting)
      getHighImpactSettingList.responseData.response.map((item, index) => {
        item.id = index + 1
    })
    if(getHighImpactSettingList.responseData.response.length === 0){
      yield put(noDataHighImpactSetting(true))
    }
      yield put(getHighImpactSettingsSuccess(getHighImpactSettingList.responseData))
    }else {
      getHighImpactSettingList = []
      yield put(getHighImpactSettingsSuccess(getHighImpactSettingList))
    }
  } catch (error) {
    yield put(getHighImpactSettingsFail(error))
  }
}

function* onReadHighImpactSetting({ payload: highImpactSetting }) {
  try {
    const response = yield call(readHighImpactSetting, highImpactSetting)
    yield put(readHighImpactSettingSuccess(response))
  } catch (error) {
    yield put(readHighImpactSettingSuccess(error))
  }
}

function* onAddHighImpactSetting({ payload: highImpactSetting }) {
  try {
    const response = yield call(createHighImpactSetting, highImpactSetting)
    if(response.responseData.success){
      yield put(addHighImpactSettingSuccess(response.responseData))
    }else{
      yield put(addHighImpactSettingFail(response.responseData))
    }
  } catch (error) {
    yield put(addHighImpactSettingFail(error))
  }
}

function* onUpdateHighImpactSetting({ payload: highImpactSetting }) {
  try {
    const response = yield call(updateHighImpactSetting, highImpactSetting)
    if(response.responseData.success){
      yield put(updateHighImpactSettingSuccess(response))
    }else{
      yield put(updateHighImpactSettingFail(response.responseData))
    }
  } catch (error) {
    yield put(updateHighImpactSettingFail(error))
  }
}

function* onStatusHighImpactSetting({ payload: highImpactSetting }) {
  try {
    const response = yield call(statusChangeHighImpactSetting, highImpactSetting)
    yield put(statusHighImpactSettingSuccess(response))
  } catch (error) {
    yield put(statusHighImpactSettingSuccess(error))
  }
}

function* onArchiveHighImpactSetting({ payload: highImpactSetting }) {
  try {
    const response = yield call(archiveHighImpactSetting, highImpactSetting)
    yield put(archiveHighImpactSettingSuccess(response))
  } catch (error) {
    yield put(archiveHighImpactSettingSuccess(error))
  }
}

function* highImpactSettingSaga() {
  yield takeEvery(GET_HIGH_IMPACT_SETTINGS, fetchHighImpactSettings)
  yield takeEvery(ADD_HIGH_IMPACT_SETTING, onAddHighImpactSetting)
  yield takeEvery(UPDATE_HIGH_IMPACT_SETTING, onUpdateHighImpactSetting)
  yield takeEvery(READ_HIGH_IMPACT_SETTING, onReadHighImpactSetting)
  yield takeEvery(STATUS_HIGH_IMPACT_SETTING, onStatusHighImpactSetting)
  yield takeEvery(ARCHIVE_HIGH_IMPACT_SETTING, onArchiveHighImpactSetting)
}

export default highImpactSettingSaga;
